import { Button, useModalUtilities } from '@faxi/web-component-library';
import { FC, Fragment, PropsWithChildren, ReactNode } from 'react';

import Icon from '../../../../../components/Icon';
import { Contributor } from '../../../../../models';
import AssignContributorsModal from '../AssignContributorsModal';
import {
  AssignContributorsForm,
  AssignTarget,
} from '../AssignContributorsModal/AssignContributorsModal.component';

export type AssignContributorsProps = PropsWithChildren<{
  btnText?: string;
  className?: string;
  initialData: any;
  contributors: Contributor[];
  render: (contributor: Contributor[]) => ReactNode;
  assignTarget?: AssignTarget;
  onSubmit?: (data: AssignContributorsForm) => Promise<void>;
}>;

// TODO: this needs clean-up, rush for demo
const AssignContributors: FC<AssignContributorsProps> = ({
  btnText = 'Assign',
  assignTarget = AssignTarget.Form,
  className,
  contributors,
  initialData,
  onSubmit,
  render,
}) => {
  const { open, openModal, closeModal } = useModalUtilities();

  return (
    <Fragment>
      <Button
        icon={<Icon name="plus" />}
        variant="outline"
        onClick={openModal}
        className={className}
      >
        {btnText}
      </Button>
      {render(contributors)}
      {open && (
        <AssignContributorsModal
          renderAsPortal
          initialData={initialData}
          onClose={() => {
            closeModal();
          }}
          onSubmit={async (data) => {
            await onSubmit?.(data);
            closeModal();
          }}
          assignTarget={assignTarget}
        />
      )}
    </Fragment>
  );
};

export default AssignContributors;
