import { SortableContext } from '@dnd-kit/sortable';
import { SectionDataModule } from 'models';
import { FC, memo, useMemo } from 'react';

import CanvasModule from '../../../../../pages/Campaigns/components/Dnd/components/CanvasModule';
import InlineEditable from '../../../../_molecules/InlineEditable';
import Icon from '../../../../Icon';
import useTemporaryModules from '../../hooks/useTemporaryModules';
import { useModuleActionsProvider } from '../../providers/ModuleActions.provider';
import { StyledSectionCanvasModule } from './SectionCanvasModule.styled';

export type SectionCanvasModuleProps = SectionDataModule & { onSave: any };

const SectionCanvasModule: FC<SectionCanvasModuleProps> = (props) => {
  const {
    id,
    elements,
    moduleElement: ModuleElement,
    disabled,
    ...rest
  } = props;

  const itemIds = useMemo(
    () => elements?.map(({ id }) => `canvas-module_${id}`) || [],
    [elements]
  );

  const { onInitialiseConfig } = useTemporaryModules();

  const { handleDeleteModule, handleDuplicateModule, handleUpdateModule } =
    useModuleActionsProvider();

  return (
    <StyledSectionCanvasModule
      className="esg-section-canvas-module"
      id={`section-canvas_${id}`}
    >
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit section title'}
        {...rest}
      />

      <SortableContext items={itemIds}>
        {elements?.map((module) => (
          <CanvasModule
            key={module.id}
            module={module}
            id={module.id}
            onClickDelete={() => handleDeleteModule(module)}
            onDuplicate={handleDuplicateModule}
            onConfig={onInitialiseConfig}
            hasUpdatePermission={!disabled}
          >
            {ModuleElement && (
              <ModuleElement
                type={module.type}
                module={module}
                onTitleSave={handleUpdateModule}
                isInlineEditableDisabled={disabled}
              />
            )}
          </CanvasModule>
        ))}
      </SortableContext>
      <div className="esg-section-canvas-module__empty">
        <Icon name="arrow-down-to-arc" />
        Drop modules
      </div>
    </StyledSectionCanvasModule>
  );
};

export default memo(SectionCanvasModule);
