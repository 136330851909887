import { Breadcrumb } from '@faxi/web-component-library';

import { APP_URI } from '../config';

export const USER_PROFILE_NAV = [
  {
    label: 'Personal Details',
    to: APP_URI.PERSONAL_DETAILS,
  },
  {
    label: 'Notifications',
    to: APP_URI.NOTIFICATIONS,
  },
  {
    label: 'Assignments',
    to: APP_URI.ASSIGNMENTS,
  },
  {
    label: 'Reminders',
    to: APP_URI.REMINDERS,
  },
];

export const USER_MANAGEMENT_NAV = [
  {
    label: 'Personal Details',
    to: 'personal-details',
  },
  {
    label: 'Permissions',
    to: 'permissions',
  },
  {
    label: 'Assignments',
    to: 'assignments',
  },
  {
    label: 'Activity Logs',
    to: 'activity-logs',
  },
];

export const USER_BY_ID_CRUMBS: Breadcrumb[] = [
  {
    text: 'Users',
    href: APP_URI.USERS,
  },
  {
    text: 'User profile',
    href: APP_URI.USER_BY_ID_PROFILE,
  },
];

export const DATE_FORMAT = 'DD.MM.YYYY.';

export const LIMIT = 10;

export const DEFAULT_ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'video/quicktime',
  'text/plain',
];

export const XBRL_ACCEPTED_FILE_TYPES = [
  'zip',
  'application/octet-stream',
  'application/zip',
  'application/x-zip',
  'application/x-zip-compressed',
];
