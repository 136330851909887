import {
  Image,
  PromptModalProps,
  SelectOption,
  useUtilities,
} from '@faxi/web-component-library';
import { FormField, validators } from '@faxi/web-form';
import { SelectField } from 'components';
import { IntegrationDataModule, IntegrationTypes } from 'models';
import { FC, useCallback } from 'react';

import Icon from '../../../../../components/Icon';
import { StyledBaseIntegrationFieldConfiguration } from './BaseIntegrationFieldConfiguration.styled';

const INTEGRATION_TITLES = {
  'kinto': 'Kinto Join',
  'jira': 'Jira',
  'slack': 'Slack',
} as Record<IntegrationTypes, string>;

const INTEGRATIONS = [
  {
    icon: <Image src="/assets/images/kinto-join.png" alt="kinto-join" />,
    label: INTEGRATION_TITLES['kinto'],
    value: 'kinto',
  },
  {
    icon: <Image src="/assets/images/slack.png" alt="slack" />,
    label: INTEGRATION_TITLES['slack'],
    value: 'slack',
  },
  {
    icon: <Image src="/assets/images/jira.png" alt="jira" />,
    label: INTEGRATION_TITLES['jira'],
    value: 'jira',
  },
] as Array<SelectOption<IntegrationTypes>>;

const promptProps = {
  type: 'info-success',
  content: 'You will be redirected to external platform',
  confirmButtonText: 'Connect',
  cancelButtonText: 'Cancel',
  buttonIcon: 'circle-check',
  titleIcon: <Icon name="circle-info" />,
} as PromptModalProps;

export type BaseIntegrationFieldConfigurationProps = IntegrationDataModule;

const BaseIntegrationFieldConfiguration: FC<
  BaseIntegrationFieldConfigurationProps
> = (props) => {
  const { prompt } = useUtilities();

  const integrationRedirect = useCallback(
    async (integration: IntegrationTypes) => {
      const proceed = await prompt({
        ...promptProps,
        title: `Connect ${INTEGRATION_TITLES[integration]} integration`,
      });

      if (!proceed) return;
      //redirect logic
    },
    [prompt]
  );

  return (
    <StyledBaseIntegrationFieldConfiguration className="esg-integration-field-configuration">
      <FormField
        name="integration"
        renderAsPortal
        skipFocus
        portalClassName="esg-integration-field"
        placeholder="Select integration"
        component={SelectField}
        options={INTEGRATIONS}
        onChange={(v) => integrationRedirect(v as IntegrationTypes)}
        validate={validators.general.required('Integration field is required')}
      />
    </StyledBaseIntegrationFieldConfiguration>
  );
};

export default BaseIntegrationFieldConfiguration;
