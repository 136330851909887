import { Heading } from '@faxi/web-component-library';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { SessionCard } from '../../../../components';
import Icon from '../../../../components/Icon';
import { BlockUI } from '../../../../helpers';
import CampaignBrowserSidePanel from '../../../../routes/subrouters/Sessions/components/CampaignBrowserSidePanel';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import SessionCampaignForm from '../SessionCampaignForm';
import { StyledSessionCampaignBrowser } from './SessionCampaignBrowser.styled';

const SessionCampaignBrowser: FC = () => {
  const { sessionCampaignItemChildren, selectedCampaignItemInformation } =
    useSessionBrowser(false);

  const navigate = useNavigate();
  const { sessionId = '', companyId = '' } = useParams();

  const {
    name,
    description,
    isForm,
    organisationSessionId = '',
  } = selectedCampaignItemInformation;

  return (
    <StyledSessionCampaignBrowser className="esg-session-campaign-browser">
      <BlockUI loading={false}>
        <div className="esg-session-campaign-browser__content">
          <div className="esg-session-campaign-browser__content__header">
            <Icon
              name={
                organisationSessionId
                  ? isForm
                    ? 'clipboard-list'
                    : 'leaf'
                  : 'building'
              }
            />
            <Heading level="1">{name}</Heading>
          </div>

          <div className="esg-session-campaign-browser__content__header__sub">
            {description || 'No description'}
          </div>

          {!isForm ? (
            sessionCampaignItemChildren && (
              <div className="esg-session-campaign-browser__content__cards">
                {sessionCampaignItemChildren.map((card) => (
                  <SessionCard
                    key={card.cardData.id}
                    onSelect={() => {
                      navigate(
                        `/sessions/${sessionId}/campaign-browser/${companyId}/item/${card.cardData.id}`
                      );
                    }}
                    {...card}
                  />
                ))}
              </div>
            )
          ) : (
            <SessionCampaignForm
              organisationSessionId={organisationSessionId}
            />
          )}
        </div>
        <CampaignBrowserSidePanel
          organisationSessionId={organisationSessionId}
        />
      </BlockUI>
    </StyledSessionCampaignBrowser>
  );
};

export default SessionCampaignBrowser;
