import { FC } from 'react';

import { StyledAccountSettings } from './AccountSettings.styled';

// TODO
const AccountSettings: FC = () => {
  return (
    <StyledAccountSettings
      title="Account Settings"
      className="esg-account-settings"
    >
      Account Settings
    </StyledAccountSettings>
  );
};

export default AccountSettings;
