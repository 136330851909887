export const formatOrganisationPath = (organisationPath: string) =>
  organisationPath.replaceAll('#', ' / ');

export const formatCampaignItemPath = (campaignItemPath: string) => {
  campaignItemPath = campaignItemPath.replaceAll('#', ' / ');

  if (campaignItemPath.startsWith('root')) {
    campaignItemPath = campaignItemPath.replace('root / ', '');
  }

  return campaignItemPath;
};
