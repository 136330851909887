import {
  applyRef,
  ModalProps,
  ModalRef,
  SelectOption,
  useCallbackRef,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import {
  AssignedContributor,
  BasicTag,
  FormFooter,
  SelectField,
} from 'components';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useMemo,
  useRef,
} from 'react';

import Icon from '../../../../../components/Icon';
import { useContributors, useValidations } from '../../../../../hooks';
import { StyledCustomOption } from '../../../../../pages/Users/components/ManageUserModal/ManageUserModal.styled';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../../../pages/Users/constants';
import { StyledAssignContributorsModal } from './AssignContributorsModal.styled';

export type AssignContributorsModalProps = PropsWithChildren<
  ModalProps & {
    onSubmit: (data: AssignContributorsForm) => Promise<void>;
    initialData: any;
    assignTarget: string;
  }
>;
export type AssignContributorsForm = {
  contributors: SelectOption<string>[];
};
export enum AssignTarget {
  Form = 'form',
  Topic = 'topic',
  Subtopic = 'subtopic',
  Section = 'section',
}

// TODO: this needs clean-up, rush for demo
const AssignContributorsModal: ForwardRefRenderFunction<
  ModalRef,
  AssignContributorsModalProps
> = (props, ref) => {
  const { onSubmit: pOnSubmit, assignTarget, initialData, ...rest } = props;

  const modalRef = useRef<ModalRef>();

  const [formRef, setFormRef] = useCallbackRef<FormRef>();

  const { validations } = useValidations();

  const { contributors } = useContributors();

  const onSubmit = useCallback(
    async (
      { contributors }: AssignContributorsForm,
      e: React.FormEvent<Element>
    ) => {
      e.stopPropagation();

      await pOnSubmit({ contributors });
    },
    [pOnSubmit]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        initialData={initialData}
        ref={setFormRef}
        children={children}
        className={className}
        onSubmit={onSubmit}
      />
    ),
    [initialData, onSubmit, setFormRef]
  );

  const removeContributor = useCallback(
    (id: string) => {
      const currentContributors: any[] =
        formRef.fields?.contributors?.value || [];

      const updatedContributors = currentContributors.filter(
        ({ value }: SelectOption<string>) => value !== id
      );

      formRef.updateValueField('contributors', updatedContributors);
    },
    [formRef]
  );

  const contributorOptionsMultipleSelect = useMemo(
    () =>
      contributors.map(({ name, role, id }) => ({
        label: (
          <StyledCustomOption>
            <div className="user-image-container">
              <Icon name="user-solid" />
            </div>
            <span>{name}</span>
            <BasicTag
              text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[role.name]}
              color={role.color}
            />
          </StyledCustomOption>
        ),
        value: id,
      })),
    [contributors]
  );

  return (
    <StyledAssignContributorsModal
      className="esg-assign-contributors-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title={`Assign contributors to ${assignTarget}`}
      icon={<Icon name="user" />}
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel="Assign"
        />
      }
      {...rest}
    >
      <FormField
        name="contributors"
        component={SelectField}
        placeholder="Select contributors"
        options={contributorOptionsMultipleSelect}
        searchable
        multiple
        required
        requiredLabel="Required"
        autoComplete="off"
        validate={validations.contributors}
      />
      <div className="esg-assign-contributors-modal__contributors">
        {contributors
          .filter(
            (contributor) =>
              Array.isArray(formRef?.fields?.contributors?.value) &&
              formRef.fields.contributors.value.some(
                (selected: { value: string }) =>
                  selected.value === contributor.id
              )
          )
          .map(({ name, src, role, isAdmin, id }) => (
            <AssignedContributor
              key={name}
              src={src}
              name={name}
              isAdmin={isAdmin}
              role={role}
              id={id}
              onRemove={() => removeContributor(id)}
            />
          ))}
      </div>
    </StyledAssignContributorsModal>
  );
};

export default forwardRef(AssignContributorsModal);
