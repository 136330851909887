import { PermissionSections, RouterPermissionSections } from 'models';

import { APP_URI } from '../config';

export const PERMISSIONS_SECTION_ROUTER_MAPPER: Record<
  RouterPermissionSections,
  string
> = {
  [PermissionSections.ORGANISATION]: APP_URI.ORGANISATIONS,
  [PermissionSections.USER]: APP_URI.USERS,
  [PermissionSections.CAMPAIGN]: APP_URI.CAMPAIGNS,
  [PermissionSections.SESSION]: APP_URI.SESSIONS,
  [PermissionSections.XBRL]: APP_URI.XBRL,
};

export const INITIAL_PERMISSIONS: Record<PermissionSections, Array<string>> = {
  [PermissionSections.ORGANISATION]: [],
  [PermissionSections.USER]: [],
  [PermissionSections.CAMPAIGN]: [],
  [PermissionSections.SESSION]: [],
  [PermissionSections.ROLE]: [],
  [PermissionSections.COMMENTS]: [],
  [PermissionSections.LOGS]: [],
  [PermissionSections.XBRL]: [],
};

export const ROUTER_PERMISSION_SECTIONS: Record<PermissionSections, boolean> = {
  [PermissionSections.ORGANISATION]: true,
  [PermissionSections.USER]: true,
  [PermissionSections.CAMPAIGN]: true,
  [PermissionSections.SESSION]: true,
  [PermissionSections.ROLE]: false,
  [PermissionSections.COMMENTS]: false,
  [PermissionSections.LOGS]: false,
  // TODO
  [PermissionSections.XBRL]: true,
};
