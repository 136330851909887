import { ModalProps, ModalRef } from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import { FormFooter, XBRLSelectionFields } from 'components';
import Icon from 'components/Icon';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import {
  CheckListModuleConfig,
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigMapperType,
  ModuleConfigType,
} from 'models';
import {
  FC,
  PropsWithChildren,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';

import storageService from '../../../../services/storageService';
import { useModulesStore } from '../../../../store/modulesStore';
import CampaignItemContext from '../../context/CampaignItem/CampaignItem.context';
import { generateModulesFromCampaignItem } from '../../context/FormBuilder/FormBuilder.provider';
import { mapTypeToIcon, mapTypeToTitle } from '../../utils';
import { findModule } from '../Dnd/utils';
import { StyledModuleConfiguration } from './ModuleConfiguration.styled';

const CONFIG_MODULE_TEXT: Partial<Record<DataModuleEnum, string>> = {
  [DataModuleEnum.INPUT]: 'Set up input field configuration',
  [DataModuleEnum.CHOICE]: 'Set up choices with options',
  [DataModuleEnum.CHECKLIST]: 'Set up checklist with options',
  [DataModuleEnum.DROPDOWN]: 'Set up options for dropdown',
  [DataModuleEnum.SWITCH]: 'Set up options for switch',
  [DataModuleEnum.INTEGRATION]: 'Set up external integration',
  [DataModuleEnum.TEXT_BOX]: 'Set up format of text',
  [DataModuleEnum.FORMULA]: 'Set up the formula',
};

export type ModuleConfigurationProps<
  T,
  S extends DataModuleEnum,
> = PropsWithChildren<ModalProps> & {
  moduleConfig: ModuleConfig<T, S>;
  onSubmit?: (config: ModuleConfig<ModuleConfigType, S>) => void;
  configurableModules: ModuleConfigMapperType;
};

export type ModuleFormFooterProps<T, S extends DataModuleEnum> = {
  moduleConfig: ModuleConfig<T, S>;
  modalRef: RefObject<ModalRef>;
};

function ModuleFormFooter<
  T extends Record<string, any>,
  S extends DataModuleEnum,
>({ moduleConfig, modalRef }: ModuleFormFooterProps<T, S>) {
  const { campaignItem } = useContext(CampaignItemContext);

  const { localFormModules } = useModulesStore();

  const initialModuleAPI = useMemo(
    () =>
      campaignItem &&
      findModule(
        moduleConfig.id,
        generateModulesFromCampaignItem(campaignItem.form?.elements)
      ).module,
    [campaignItem, moduleConfig]
  );

  const formModule = useMemo(
    () => findModule(moduleConfig.id, localFormModules).module,
    [localFormModules, moduleConfig.id]
  );

  const isModuleChanged = useMemo(
    () => !isEqual(formModule, initialModuleAPI),
    [formModule, initialModuleAPI]
  );

  return (
    <FormFooter
      modal={modalRef}
      submitIcon="check"
      submitLabel="Done"
      cancelLabel="Cancel"
      isModuleChanged={isModuleChanged}
    />
  );
}

function ModuleConfiguration<
  T extends Record<string, any>,
  S extends DataModuleEnum,
>(props: ModuleConfigurationProps<T, S>) {
  const { moduleConfig, onSubmit, configurableModules, ...rest } = props;

  const { type } = moduleConfig;
  const modalRef = useRef<ModalRef>(null);

  const initialData = useMemo(
    () => ({
      ...moduleConfig.config,
      ...(moduleConfig?.config?.minDate && {
        minDate: dayjs(moduleConfig.config.minDate),
      }),
      ...(moduleConfig?.config?.maxDate && {
        maxDate: dayjs(moduleConfig.config.maxDate),
      }),
    }),
    [moduleConfig]
  );

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        initialData={initialData}
        onSubmit={async (config: ModuleConfigType, e) => {
          e.stopPropagation();
          const storageModules =
            storageService.getItem<
              ModuleConfig<ModuleConfigType, DataModuleEnum>[]
            >('MODULES');

          const moduleConditionalElements = findModule(
            moduleConfig.id,
            storageModules
          ).module?.conditionalElements;

          let finalConfig = config;

          if (moduleConfig.type === DataModuleEnum.CHECKLIST) {
            finalConfig = {
              ...config,
              defaultValue: (config as CheckListModuleConfig).defaultValue.map(
                ({ value }) => value
              ),
            } as ModuleConfigType;
          }

          onSubmit?.({
            ...moduleConfig,
            config: finalConfig,
            conditionalElements: moduleConditionalElements || {},
          });
          modalRef.current?.close();
        }}
      />
    ),
    [initialData, onSubmit, moduleConfig]
  );

  const ModuleConfig = useMemo(
    () =>
      configurableModules[type] as FC<
        ModuleConfig<T, typeof type> & { initialData: Record<string, any> }
      >,
    [type, configurableModules]
  );

  return (
    <StyledModuleConfiguration
      ref={modalRef}
      hasCloseButton={false}
      title={`${mapTypeToTitle[type]}: ${moduleConfig.title}`}
      icon={<Icon name={mapTypeToIcon[type]} />}
      className="esg-module-configuration"
      childrenWrapper={formWrapper}
      renderAsPortal
      defaultOpen={false}
      footer={
        <ModuleFormFooter moduleConfig={moduleConfig} modalRef={modalRef} />
      }
      {...rest}
    >
      <p className="esg-module-configuration__title">
        {CONFIG_MODULE_TEXT[type]}
      </p>

      <ModuleConfig {...moduleConfig} initialData={initialData} />

      {moduleConfig.type !== 'input' && (
        <XBRLSelectionFields
          type="fields"
          className="esg-module-configuration__xbrl"
          selectedPackage={moduleConfig.config?.xbrl_package}
        />
      )}
    </StyledModuleConfiguration>
  );
}

export default ModuleConfiguration;
