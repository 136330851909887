import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledHeader = styled.header`
  ${flex('column')};
  border-bottom: 1px solid var(--GRAY-EF);
  padding: ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_32};
  gap: ${theme.sizes.SIZE_20};
  .esg-header {
    &__title {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_10};

      &__badge {
        ${flex('row', 'center', 'center')};
        border-radius: ${theme.sizes.SIZE_8};
        color: var(--WHITE-FF);
        width: ${theme.sizes.SIZE_56};
        height: ${theme.sizes.SIZE_48};
        background-color: var(--RED-D5);

        &--sessions {
          background-color: var(--PURPLE-B6);
        }

        &--organisations {
          background-color: var(--BLUE-29);
        }

        &--campaigns {
          background-color: var(--GREEN-97);
        }

        &--users {
          background-color: var(--ORANGE-E6);
        }

        &--tasks {
          background-color: var(--GREEN-97);
        }
        &--xbrl-taxonomies {
          background-color: var(--PURPLE-3D);
        }

        svg {
          font-size: 30px;
        }
      }
    }
  }
`;

export { StyledHeader };
