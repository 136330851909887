import api from 'api';
import { CampaignItem, IDataModule } from 'models';
import { FC, ReactNode, useCallback, useContext, useState } from 'react';
import { deleteProhibitedIds } from 'utils';

import { API_ROUTES } from '../../../../api/routes';
import { useURLDataCollectionElementId } from '../../../../hooks';
import CampaignItemContext, {
  CampaignItemContextProps,
} from './CampaignItem.context';

type CampaignItemProviderProps = {
  children?: ReactNode;
};

export type CampaignItemNameDesc = {
  name: string;
  description?: string;
  type?: string;
};

const CampaignItemProvider: FC<CampaignItemProviderProps> = (props) => {
  const { dataCollectionElementId } = useURLDataCollectionElementId();

  const { children } = props;

  const [campaignItem, setCampaignItem] = useState<CampaignItem>();

  const { trigger: editCampaignItemTrigger, isMutating: mutatingEdit } =
    api.useMutation(
      API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_ITEM(dataCollectionElementId)
    );

  const { trigger: duplicateCampaignItemTrigger } = api.useMutation(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_ITEM(dataCollectionElementId)
  );

  const editCampaignItem = useCallback(
    async (data: Partial<CampaignItem>) => {
      if (data.name) {
        delete data.name;
        delete data.type;
      }

      deleteProhibitedIds(data);
      await editCampaignItemTrigger({
        method: 'PATCH',
        data,
      });
    },
    [editCampaignItemTrigger]
  );

  const duplicateCampaignItem = useCallback(
    async (data: IDataModule) => {
      await duplicateCampaignItemTrigger({
        method: 'POST',
        data,
        url: API_ROUTES.CAMPAIGN_ITEMS_ROUTES.DUPLICATE(
          dataCollectionElementId,
          data.id.split('+')[0]
        ),
      });
    },
    [dataCollectionElementId, duplicateCampaignItemTrigger]
  );

  const removeActiveCampaignItem = useCallback(() => {
    setCampaignItem(undefined);
  }, []);

  return (
    <CampaignItemContext.Provider
      value={{
        mutating: mutatingEdit,
        campaignItem,
        setCampaignItem,
        removeActiveCampaignItem,
        editCampaignItem,
        duplicateCampaignItem,
      }}
    >
      {children}
    </CampaignItemContext.Provider>
  );
};

const useCampaignItem = (): CampaignItemContextProps =>
  useContext(CampaignItemContext);

export { CampaignItemProvider, useCampaignItem };
