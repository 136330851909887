import { useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../api/routes';
import { ApiData, Contributor, User } from '../models';

const useContributors = () => {
  const { data: { data: users } = { data: [] } } = useSWR<
    ApiData<User[]>,
    Error
  >(API_ROUTES.USERS.USER);

  const contributors = useMemo<Contributor[]>(
    () =>
      users?.map(({ id, firstName, lastName, roles }) => ({
        id,
        name: [firstName, lastName].join(' ').trim(),
        role: roles[0],
        src: '',
      })),
    [users]
  );

  return { contributors };
};

export default useContributors;
