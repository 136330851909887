import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSubSubTopic = styled.div`
  ${flex('column')};
  padding: ${theme.sizes.SIZE_32};
`;

export { StyledSubSubTopic };
