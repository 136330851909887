import {
  Breadcrumbs,
  Button,
  Divider,
  Heading,
  Input,
} from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import dayjs from 'dayjs';
import { FC, Fragment, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApprovalProgressBar, BasicTreeView } from '../../../../components';
import { DATE_FORMAT } from '../../../../constants';
import { useSelectedSession } from '../../context/SelectedSession';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import { mockedApprovals } from '../../utils';
import SessionSidebarLink, {
  SessionSidebarLinkProps,
} from './components/SessionSidebarLink/SessionSidebarLink.component';
import { StyledSessionSidebar } from './SessionSidebar.styled';

const SESSION_SIDEBAR_LINKS: SessionSidebarLinkProps[] = [
  {
    url: APP_URI.SESSIONS_SESSION_DASHBOARD,
    text: 'Dashboard',
    leftIcon: 'grid',
  },
  {
    url: APP_URI.SESSIONS_SESSION_ACTIVITY,
    text: 'Activity',
    leftIcon: 'wave-pulse',
  },
  {
    url: APP_URI.SESSIONS_SESSION_MEMBERS,
    text: 'Contributors',
    leftIcon: 'users',
  },
  {
    url: APP_URI.SESSIONS_SESSION_CAMPAIGN_BROWSER,
    text: 'Browser',
    leftIcon: 'folder-tree',
    rightIcon: 'chevron-right',
  },
  {
    url: APP_URI.SESSIONS_SESSION_DETAILS_DOCUMENTS,
    text: 'Documents',
    leftIcon: 'cabinet-filling',
  },
];

type SessionSidebarProps = {
  isBrowserView?: boolean;
};

const SessionSidebar: FC<SessionSidebarProps> = ({ isBrowserView = false }) => {
  const selectedSession = useSelectedSession();

  const location = useLocation();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>();

  const { companyId = '' } = useParams();

  const { sessionCompanyTreeView, sessionCampaignTreeView } =
    useSessionBrowser(false);

  const basicTreeViewData = companyId
    ? sessionCampaignTreeView
    : sessionCompanyTreeView;

  return (
    <StyledSessionSidebar
      className="esg-session-sidebar"
      expandDirection="right"
    >
      <div className="esg-session-sidebar__nav-container">
        <Breadcrumbs
          splitter="/"
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: selectedSession!.name, href: location.pathname },
          ]}
        />

        <Heading level="1">{selectedSession!.name}</Heading>

        <Divider />

        {!isBrowserView && (
          <Fragment>
            <div className="esg-session-sidebar__nav-container__details">
              <div className="esg-session-sidebar__nav-container__details__date">
                <div>Session started</div>
                <div>
                  {dayjs(selectedSession?.startDate).format(DATE_FORMAT)}
                </div>
              </div>
              <div className="esg-session-sidebar__nav-container__details__date">
                <div>Session ends</div>
                <div>{dayjs(selectedSession?.endDate).format(DATE_FORMAT)}</div>
              </div>
              <ApprovalProgressBar
                title="Progress"
                status="In Progress"
                progress={selectedSession?.progress}
              />
              <ApprovalProgressBar
                title="Approval"
                status="In Progress"
                progress={mockedApprovals[0]}
              />
            </div>
            <Divider />
          </Fragment>
        )}

        {!isBrowserView ? (
          <Fragment>
            <div className="esg-session-sidebar__nav-container__subtitle">
              Browser
            </div>
            <div className="esg-session-sidebar__nav-container__links">
              {SESSION_SIDEBAR_LINKS.map((link) => (
                <SessionSidebarLink key={link.url} {...link} />
              ))}
            </div>
          </Fragment>
        ) : (
          <div className="esg-session-sidebar__nav-container__nav">
            <Button
              variant="ghost"
              onClick={() => navigate(-1)}
              icon={<Icon name="chevron-left" />}
            >
              Back
            </Button>
            <Input
              className="esg-session-sidebar__nav-container__nav__search"
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder="Search"
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label="Clear all"
                    icon={<Icon name="xmark" />}
                    onClick={() => setSearch('')}
                  />
                ),
              })}
              value={search}
              onChange={setSearch}
            />
            <div className="esg-session-sidebar__nav-container__subtitle">
              {!companyId ? 'Company browser' : 'Campaign browser'}
            </div>

            {basicTreeViewData && <BasicTreeView data={basicTreeViewData} />}
          </div>
        )}
        <div className="esg-session-sidebar__nav-container__button-wrapper">
          <Button
            className="esg-session-sidebar__nav-container__button-wrapper__download"
            variant="outline"
            icon={<Icon name="download" />}
            //TODO: implement disabled logic and onCLick when download is ready for report
            disabled
          >
            Download Report
          </Button>
        </div>
      </div>
    </StyledSessionSidebar>
  );
};

export default SessionSidebar;
