import uniqueId from 'lodash.uniqueid';

import { INameExtended } from '../../../../components/Icon';
import { CampaignSessionStatus } from '../../../../models';

export const DASHBOARD_DATA_MOCK = [
  {
    'icon': 'bars-progress' as INameExtended,
    'name': 'Report Progress',
    'description': 'by Company',
    'items': [
      {
        'name': 'Kinto U.K. Limited',
        'description': '...',
        'progress': {
          'progress': 33,
          'total': 100,
          'completed': 0,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
      {
        'updatedBy': '13a44802-5051-70cf-3b97-2810ea384590',
        'createdBy': '13a44802-5051-70cf-3b97-2810ea384590',
        'name': 'Toyota Motors Corporation',
        'description': '...',
        'progress': {
          'progress': 76,
          'total': 100,
          'completed': 0,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
    ],
    'id': uniqueId(),
    'progress': {
      'progress': 0,
      'total': 6,
      'completed': 0,
    },
    'status': CampaignSessionStatus.Active,
    'organisationSessionId': '571dee34-d7f6-48db-988d-5d6103a9671e',
  },
  {
    'icon': 'bars-progress' as INameExtended,
    'name': 'Approval Status',
    'description': 'by Company',
    'items': [
      {
        'name': 'Kinto U.K. Limited',
        'description': '...',
        'progress': {
          'progress': 46,
          'total': 100,
          'completed': 0,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
      {
        'name': 'Toyota Motors Corporation',
        'description': '...',
        'progress': {
          'progress': 50,
          'total': 100,
          'completed': 0,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
    ],
    'id': uniqueId(),
    'progress': {
      'progress': 0,
      'total': 6,
      'completed': 0,
    },
    'status': CampaignSessionStatus.Active,
    'organisationSessionId': '571dee34-d7f6-48db-988d-5d6103a9671e',
  },
  {
    'icon': 'building' as INameExtended,
    'name': 'KINTO U.K. Limited',
    'description': '',
    'items': [
      {
        'name': 'Topic',
        'description': '...',
        'progress': {
          'progress': 33,
          'total': 100,
          'completed': 33,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
    ],
    'id': uniqueId(),
    'progress': {
      'progress': 0,
      'total': 6,
      'completed': 0,
    },
    'status': CampaignSessionStatus.Active,
    'organisationSessionId': 'bf7a86d1-1910-48ea-94bb-aa428c086b79',
  },
  {
    'icon': 'building' as INameExtended,
    'name': 'Toyota Motors Corporation',
    'description': '',
    'items': [
      {
        'name': 'Topic',
        'description': '...',
        'progress': {
          'progress': 76,
          'total': 100,
          'completed': 0,
        },
        'id': uniqueId(),
        'type': 'topic',
      },
    ],
    'id': uniqueId(),
    'progress': {
      'progress': 0,
      'total': 6,
      'completed': 0,
    },
    'status': CampaignSessionStatus.Active,
    'organisationSessionId': '571dee34-d7f6-48db-988d-5d6103a9671e',
  },
];
