import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

export const StyledFlag = styled.div<{ $isRead: boolean }>`
  ${size(theme.sizes.SIZE_16, theme.sizes.SIZE_16)};
  flex-shrink: 0;
  border: none;
  border-radius: 50%;
  background-color: ${({ $isRead }) =>
    $isRead ? 'var(--BLUE-00)' : 'var(--GRAY-EF)'};
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
`;
