import { Icon, Tooltip } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import { Contributor } from '../../../models';
import { StyledContributorsList } from './ContributorsList.styled';

export type ContributorsListProps = PropsWithChildren<{
  contributors: Contributor[];
  max?: number;
  className?: string;
}>;

const ContributorsList: FC<ContributorsListProps> = ({
  contributors,
  max = 6,
  className,
}) => {
  const displayedContributors = contributors.slice(0, max);
  const extraContributorsCount = contributors.length - max;

  return (
    <StyledContributorsList
      className={classNames('esg-contributors-list', className)}
    >
      <div className="esg-contributors-list__wrapper">
        <ul className="esg-contributors-list__wrapper__content">
          {displayedContributors.map(({ id, name }) => (
            <li key={id}>
              <Tooltip content={name}>
                <div className="user-image-container">
                  <Icon name="user-solid" />
                </div>
              </Tooltip>
            </li>
          ))}

          {extraContributorsCount > 0 && (
            <li className="esg-contributors-list__wrapper__extra">
              <span>+{extraContributorsCount}</span>
            </li>
          )}
        </ul>
      </div>
    </StyledContributorsList>
  );
};

export default ContributorsList;
