import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { INameExtended } from '../../../components/Icon';
import {
  CampaignSessionStatus,
  OrganisationSession,
  TreeNodeElement,
} from '../../../models';
import { insertNodeInTree } from '../../../utils';

const DEFAULT_SESSION_REPORT_APPROVAL_DATA: {
  icon: INameExtended;
  name: string;
  description: string;
} = {
  icon: 'building',
  name: 'Approval Status',
  description: 'by Company',
};

/**
 * A custom hook for managing and organizing session and campaign data in a structured format.
 *
 * @returns  An object containing:
 * - `sessionCompanyTreeView` (TreeNodeElement | undefined): A hierarchical tree view of the organization and its companies.
 * - `sessionCampaignTreeView` (TreeNodeElement | undefined): A hierarchical tree view of the selected company's campaign items.
 * - `sessionCampaignItemChildren` (Array<Object> | undefined): A list of campaign items nested under a specific campaign item or at the root level.
 * - `sessionCompanies` (Array<Object> | undefined): A processed list of companies with associated campaign items and progress data.
 * - `selectedCampaignItemInformation` (Object): Detailed information about the selected campaign item or company, including metadata like name, description, and type.
 * - `rest` (Object): Any additional data returned by the `useSWR` hook, such as loading or error states.
 */
const useSessionBrowser = (shouldRefreshPeriodically = true) => {
  const {
    sessionId = '',
    companyId = '',
    campaignItemId = '',
  } = useParams<{
    sessionId: string;
    companyId: string;
    campaignItemId: string;
  }>();

  const [isOrgReady, setIsOrgReady] = useState(true);

  const { data: { data } = {}, ...rest } = useSWR<{
    data: OrganisationSession[];
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSIONS(sessionId), {
    refreshInterval:
      shouldRefreshPeriodically && !isOrgReady && !companyId ? 5000 : undefined,
    dedupingInterval: 5000,
  });

  const sessionTreeData = useMemo(
    () =>
      data?.length
        ? {
            organisation: {
              id: data[0].organisation.path.ids[0],
              name: data[0].organisation.path.names[0],
            },
            companies: data.map(
              ({ organisation, campaignItems, id, status }) => ({
                ...organisation,
                organisationSessionId: id,
                campaignItems,
                status,
              })
            ),
          }
        : undefined,
    [data]
  );

  const sessionCompanyTreeView: TreeNodeElement | undefined = useMemo(() => {
    if (!sessionTreeData) return;

    const root: TreeNodeElement = {
      id: sessionTreeData.organisation.id,
      name: sessionTreeData.organisation.name,
      iconName: 'building',
      to: `/sessions/${sessionId}/campaign-browser`,
    };

    sessionTreeData.companies.forEach((company) => {
      insertNodeInTree({
        currentNode: root,
        targetNode: {
          ...company,
          iconName: 'building',
          to: `/sessions/${sessionId}/campaign-browser/${company.id}`,
          disabled: company.status === CampaignSessionStatus.Draft,
        },
        depth: 0,
      });
    });

    return root;
  }, [sessionId, sessionTreeData]);

  const sessionCampaignTreeView = useMemo(() => {
    if (!companyId || !sessionTreeData) return;

    const company = sessionTreeData.companies.find(
      (company) => companyId === company.id
    );

    if (company) {
      const root: TreeNodeElement = {
        id: company.id,
        name: company.name,
        iconName: 'building',
        to: `/sessions/${sessionId}/campaign-browser/${company.id}`,
      };

      company.campaignItems.forEach((item) => {
        insertNodeInTree({
          currentNode: root,
          targetNode: {
            ...item,
            path: {
              ids: [company.id, ...item.path.ids.slice(1)],
              names: [company.name, ...item.path.names.slice(1)],
            },
            to: `/sessions/${sessionId}/campaign-browser/${company.id}/item/${item.id}`,
            iconName:
              item.type === 'data_collection_element'
                ? 'clipboard-list'
                : 'leaf',
            emphasized: item.type === 'data_collection_element',
          },
          depth: 0,
        });
      });

      return root;
    }
  }, [companyId, sessionId, sessionTreeData]);

  const sessionCampaignItemChildren = useMemo(() => {
    if (!companyId) return;

    const selectedCompanyCampaignItems =
      sessionTreeData?.companies.find((company) => companyId === company.id)
        ?.campaignItems || [];

    let campaignItemChildren;

    if (!campaignItemId) {
      campaignItemChildren = selectedCompanyCampaignItems.filter(
        (item) => item.path.ids.length === 1
      );
    } else {
      campaignItemChildren = selectedCompanyCampaignItems.filter(
        (item) => item.path.ids.at(-1) === campaignItemId
      );
    }

    return campaignItemChildren.map((item) => ({
      cardData: {
        ...item,
        approval: item.progress,
      },
      iconName: (item.type === 'data_collection_element'
        ? 'clipboard-list'
        : 'leaf') as INameExtended,
    }));
  }, [campaignItemId, companyId, sessionTreeData?.companies]);

  const sessionCompanies = useMemo(
    () =>
      data?.map(
        ({
          organisation: { name, description, id },
          progress,
          campaignItems,
          id: organisationSessionId,
          campaignId,
          status,
        }) => ({
          ...DEFAULT_SESSION_REPORT_APPROVAL_DATA,
          items: campaignItems.filter(({ parent }) => parent.id === campaignId),
          name,
          description,
          id,
          progress,
          status,
          organisationSessionId,
        })
      ),
    [data]
  );

  const selectedCampaignItemInformation = useMemo(() => {
    if (!companyId) return {};

    const selectedCompany = sessionTreeData?.companies.find(
      (company) => company.id === companyId
    );

    if (!selectedCompany) return {};

    if (!campaignItemId) {
      return {
        name: selectedCompany.name,
        description: selectedCompany.description,
      };
    }

    const selectedItem = selectedCompany.campaignItems.find(
      (item) => item.id === campaignItemId
    );

    if (!selectedItem) return {};

    return {
      name: selectedItem.name,
      description: selectedItem.description,
      isForm: selectedItem.type === 'data_collection_element',
      organisationSessionId: selectedCompany.organisationSessionId,
    };
  }, [campaignItemId, companyId, sessionTreeData?.companies]);

  useEffect(() => {
    if (data) {
      setIsOrgReady(
        !data?.some(
          (orgSession) => orgSession.status === CampaignSessionStatus.Draft
        )
      );
    }
  }, [data]);

  return {
    ...rest,
    sessionCompanyTreeView,
    sessionCampaignTreeView,
    sessionCampaignItemChildren,
    sessionCompanies,
    selectedCampaignItemInformation,
  };
};

export default useSessionBrowser;
