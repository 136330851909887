import { Switch } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect, useState } from 'react';

import {
  getUnitCategory,
  UNIT_CATEGORY_OPTIONS,
  UNIT_OPTIONS,
  UnitCategory,
} from '../../../mock/units';
import { InputModuleConfig } from '../../../models';
import SelectField from '../SelectField';
import { StyledSelectUnit } from './SelectUnit.styled';

export type SelectUnitProps<T = InputModuleConfig> = PropsWithChildren<{
  configKey: keyof T;
  config: T;
  switchLabel?: string;
  className?: string;
}>;

const SelectUnit: FC<SelectUnitProps> = ({
  configKey,
  config,
  className,
  switchLabel = 'Numeric unit',
}) => {
  const value = config?.[configKey] as string | undefined;

  const [selectedUnitCategory, setSelectedUnitCategory] =
    useState<UnitCategory>();

  const [enabled, setEnabled] = useState<boolean>(!!value);

  useEffect(() => {
    if (value) {
      const unitCategory = getUnitCategory(value);

      unitCategory && setSelectedUnitCategory(unitCategory);
    }
  }, [value]);

  return (
    <StyledSelectUnit className={classNames('esg-select-unit', className)}>
      <Switch
        value={enabled}
        label={switchLabel}
        onChange={(e) => setEnabled(e.target.checked)}
      />

      <div className="esg-select-unit__fields">
        <SelectField
          placeholder="Unit Category"
          options={UNIT_CATEGORY_OPTIONS}
          onChange={(v) => setSelectedUnitCategory(v as UnitCategory)}
          aria-describedby="unit-category"
          value={selectedUnitCategory}
          disabled={!enabled}
        />

        <FormField
          name={configKey}
          component={SelectField}
          placeholder="Unit"
          disabled={!selectedUnitCategory || !enabled}
          options={UNIT_OPTIONS[selectedUnitCategory!]}
          hasClearAction
          clearTitle="Unselect"
          aria-describedby="unit"
          searchable
        />
      </div>
    </StyledSelectUnit>
  );
};

export default SelectUnit;
