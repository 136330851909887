import classNames from 'classnames';
import { FC, useState } from 'react';

import XBRLTag from '../../../../components/_molecules/XBRLTag';
import { useModuleActionsProvider } from '../../../../components/_organisms/BuilderCanvas/providers/ModuleActions.provider';
import { FEATURES } from '../../../../config';
import { StyledBuilderTools } from './BuilderTools.styled';
import DataModulesTool from './components/DataModulesTool';
import PreviewTool from './components/PreviewTool';

const BuilderTools: FC<{ className?: string; items: string[] }> = ({
  className,
  items,
}) => {
  const { campaignItem } = useModuleActionsProvider();
  const [xbrlTag, setXbrlTag] = useState<string>();

  return (
    <StyledBuilderTools className={classNames('esg-builder-tools', className)}>
      <PreviewTool />
      <div className="esg-builder-tools__divider" />

      {FEATURES.ADD_XBRL_TAG && (
        <>
          <XBRLTag
            className="esg-builder-tools__tag"
            parent={campaignItem?.name || 'campaign item'}
            value={xbrlTag}
            onChange={setXbrlTag}
          />
          <div className="esg-builder-tools__divider" />
        </>
      )}

      <DataModulesTool items={items} />
    </StyledBuilderTools>
  );
};

export default BuilderTools;
