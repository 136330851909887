import { AxiosRequestConfig } from 'axios';
import { SWRConfiguration } from 'swr';

import axiosInstance from './axiosInstance';

const MINUTES_TO_MILLISECONDS = 60 * 1000;

type FetcherArgs = [string, AxiosRequestConfig | undefined];

const fetcher = async (args: [string, AxiosRequestConfig] | string) => {
  const finalArgs: FetcherArgs = Array.isArray(args) ? args : [args, undefined];

  return axiosInstance.get(...finalArgs).then((res) => res.data);
};

export const swrConfig: SWRConfiguration = {
  fetcher,
  revalidateOnFocus: false, // Revalidate data when window is focused
  shouldRetryOnError: true, // Retry on error
  dedupingInterval: 2 * MINUTES_TO_MILLISECONDS, // Avoid re-fetching the same data within this time
  errorRetryInterval: 5000, // Interval for retrying a request after an error
  errorRetryCount: 2, // Number of retry attempts
  suspense: false, // If set to true, the component will be thrown into a "suspense" state while fetching data
};

export default swrConfig;
