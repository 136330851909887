import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionCampaignBrowser = styled.div`
  ${flex('row')};
  height: 100%;

  .esg-session-card {
    max-width: 100%;
  }

  .esg-collapsible-side-panel {
    height: 100%;
  }

  &.esg-session-campaign-browser--error {
    ${flex('row', 'center', 'center')};

    .wcl-icon {
      ${fontSize(theme.fontSizes.FONT_28)};
    }
  }

  .esg-session-campaign-browser {
    &__content {
      ${flex('column')};
      padding: ${theme.sizes.SIZE_48} ${theme.sizes.SIZE_32};
      flex-grow: 1;
      overflow-y: auto;
      gap: ${theme.sizes.SIZE_12};

      &__header {
        ${flex('row', 'flex-start', 'center')};

        gap: ${theme.sizes.SIZE_12};

        svg {
          font-size: ${theme.fontSizes.FONT_34};
        }
      }

      &__cards {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: ${theme.sizes.SIZE_20};
        margin-top: ${theme.sizes.SIZE_48};
      }
    }

    &__contributors {
      hr {
        margin: ${theme.sizes.SIZE_32} 0;
      }
    }
  }
`;

export { StyledSessionCampaignBrowser };
