import { Image } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { InputField } from 'components';
import { DataModuleEnum, IntegrationDataModule } from 'models';
import { FC } from 'react';

import { generateModuleFieldName } from '../../../../../../utils';
import { INTEGRATION_IMAGES } from '../../IntegrationCanvasModule/IntegrationCanvasModule.component';
import { StyledIntegrationPreview } from './IntegrationPreview.styled';

export type IntegrationPreviewProps = IntegrationDataModule;

const IntegrationPreview: FC<IntegrationPreviewProps> = ({
  title,
  config,
  id,
  disabled,
}) => (
  <StyledIntegrationPreview className="esg-integration-preview">
    <p className="esg-integration-preview__title">{title || 'Integration'}</p>
    <div className="esg-integration-preview__content">
      {config?.integration && (
        <Image
          src={INTEGRATION_IMAGES[config.integration]}
          alt={config.integration || ''}
        />
      )}

      <FormField
        name={generateModuleFieldName(id, DataModuleEnum.INTEGRATION)}
        placeholder="Value"
        component={InputField}
        disabled={disabled}
      />
    </div>
  </StyledIntegrationPreview>
);

export default IntegrationPreview;
