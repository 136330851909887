import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledXBRLTagChip = styled.div`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_16};
  min-height: ${theme.sizes.SIZE_40};

  border: 1px solid var(--GRAY-C1);
  border-radius: ${theme.sizes.SIZE_8};

  padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_16};
  background-color: var(--GRAY-EF);

  .esg-xbrl-tag-chip {
    &__button.button--ghost {
      min-height: ${theme.sizes.SIZE_24};
      width: ${theme.sizes.SIZE_24};

      &:hover {
        background-color: var(--GRAY-C1);
      }
    }

    &__value {
      ${fontSize(theme.fontSizes.FONT_14)};
      color: var(--GRAY-54);
      padding-bottom: 3px;
    }
  }
`;

export { StyledXBRLTagChip };
