import { FC } from 'react';

import { CampaignSessionStatus } from '../../../../models';
import { SessionDashboardCard } from './components';
import { DASHBOARD_DATA_MOCK } from './data';
import { StyledSessionDashboard } from './SessionDashboard.styled';

//TODO: Data is mocked. This need to be changed.

const SessionDashboard: FC = () => {
  return (
    <StyledSessionDashboard title="Dashboard" className="esg-session-dashboard">
      {DASHBOARD_DATA_MOCK?.map((data) => (
        <SessionDashboardCard
          key={data.id}
          {...data}
          loading={data.status === CampaignSessionStatus.Draft}
          loadingText="Preparing forms..."
          disableLinking
        />
      ))}
    </StyledSessionDashboard>
  );
};

export default SessionDashboard;
