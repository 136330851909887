import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledManageUserModal = styled(Modal)`
  .wcl-modal {
    &__main {
      ${flex('column', 'flex-start', 'stretch')};

      > p {
        ${fontSize(theme.sizes.SIZE_20)};

        font-weight: 700;
        text-align: center;
      }

      > .esg-user-profile-image {
        align-self: center;
      }

      gap: ${theme.sizes.SIZE_32};
      padding-top: ${theme.sizes.SIZE_32};

      .wcl-select__option {
        flex-direction: row-reverse;
        justify-content: space-between;
      }
    }
  }
`;

const StyledCustomOption = styled.div`
  ${flex('row', 'flex-start', 'center')};
  gap: ${theme.sizes.SIZE_12};
  width: 100%;

  .esg-basic-tag {
    margin-left: auto;
    font-size: ${theme.sizes.SIZE_12};
    padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_8};
  }

  .user-image-container {
    ${size(theme.sizes.SIZE_20)};
    min-width: ${theme.sizes.SIZE_20};
    font-size: ${theme.fontSizes.FONT_14};
    border-radius: 50%;
    background: linear-gradient(var(--GRAY-EF), var(--GRAY-D9));
    color: var(--GRAY-8F-B3);
    position: relative;
    overflow: hidden;
  }
`;

export { StyledCustomOption, StyledManageUserModal };
