import { Button } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { ApiData, CommentType, User, UserMention } from 'models';
import { FC, PropsWithChildren, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import ContentEditableWithMentions from '../ContentEditableWithMentions';
import { ContentEditableRef } from '../ContentEditableWithMentions/ContentEditableWithMentions.component';
import { StyledPostComment } from './PostComment.styled';

export type PostCommentProps = PropsWithChildren<{
  onSubmit: (message: CommentType[]) => Promise<void>;
}>;

const mapUsersToMentions = (users: User[]): UserMention[] =>
  users.map(({ id, firstName, lastName, roles }) => ({
    id,
    name: [firstName, lastName].join(' ').trim(),
    role: roles[0],
  }));

const PostComment: FC<PostCommentProps> = ({ onSubmit }) => {
  const [message, setMessage] = useState<CommentType[]>([]);

  // TODO: these should be contributors, change when API is ready
  const { data: { data: users } = { data: [] } } = useSWR<
    ApiData<User[]>,
    Error
  >(API_ROUTES.USERS.USER);

  const mentions = useMemo(() => mapUsersToMentions(users), [users]);

  const contentEditableRef = useRef<ContentEditableRef>(null);

  return (
    <StyledPostComment className="esg-post-comment">
      <ContentEditableWithMentions
        ref={contentEditableRef}
        mentions={mentions}
        onContentEditableChange={setMessage}
      />
      <Button
        className="esg-post-comment__send"
        icon={<Icon name="paper-plane" />}
        onClick={async () => {
          contentEditableRef?.current?.clearValue?.();
          await onSubmit(message);
        }}
        disabled={
          !message?.length ||
          (message?.length === 1 && message[0].value.trim() === '')
        }
      />
    </StyledPostComment>
  );
};

export default PostComment;
