import {
  AssignNotification,
  MarkNotification,
  Notification,
  RoleUpdateNotification,
} from '../../../models';

export const isAssignNotification = (
  notification: Notification
): notification is AssignNotification => {
  return notification.action === 'assign';
};

export const isRoleUpdateNotification = (
  notification: Notification
): notification is RoleUpdateNotification => {
  return notification.action === 'role-update';
};

export const isMarkNotification = (
  notification: Notification
): notification is MarkNotification => {
  return notification.action === 'mark';
};
