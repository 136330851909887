import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleConfiguration = styled(Modal)`
  &.wcl-overlay[class].esg-module-configuration {
    .wcl-modal {
      min-width: ${theme.sizes.SIZE_944};

      &__header {
        ${flex('row', 'center', 'center')};
        width: 100%;

        &__title {
          ${fontSize(theme.fontSizes.FONT_24, theme.sizes.SIZE_32)};
          font-weight: 600;
        }

        &__icon {
          ${size(theme.sizes.SIZE_46, theme.sizes.SIZE_32)};

          min-height: unset;
          background-color: var(--GRAY-EF);
          border-radius: ${theme.sizes.SIZE_8};
          padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_10};

          svg {
            ${size(theme.sizes.SIZE_24, theme.sizes.SIZE_16)};
          }
        }
      }

      &__footer {
        button[type='submit'] {
          &:after {
            border: unset;
          }

          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
    .esg-module-configuration {
      &__title {
        ${fontSize(theme.fontSizes.FONT_16, theme.sizes.SIZE_24)};

        font-weight: 400;
        margin: 0 0 ${theme.sizes.SIZE_32};
      }

      &__xbrl {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: ${theme.sizes.SIZE_20};

        margin-top: ${theme.sizes.SIZE_32};
      }
    }
  }
`;

export { StyledModuleConfiguration };
