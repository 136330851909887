import { EmptyFolder, Loading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';

import ErrorFallback from './components/ErrorFallback';
import WarningFallback from './components/WarningFallback';
import { WarningFallbackProps } from './components/WarningFallback/WarningFallback.component';

const BlockUI: FC<
  PropsWithChildren & {
    error?: boolean;
    warning?: boolean;
    fallback?: ReactNode;
    fallbackCondition?: boolean;
    loading?: boolean;
  } & Partial<WarningFallbackProps>
> = ({
  children,
  error = false,
  fallback = <EmptyFolder title="No data." />,
  fallbackCondition,
  loading,
  warning,
  warningText,
  warningTitle,
}) => {
  if (loading) return <Loading />;

  if (error) return <ErrorFallback />;

  if (warning && warningText && warningTitle)
    return (
      <WarningFallback warningTitle={warningTitle} warningText={warningText} />
    );

  return fallbackCondition ? fallback : children;
};

export default BlockUI;
