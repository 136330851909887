import { FC, PropsWithChildren } from 'react';

import { StyledBasicTag } from './BasicTag.styled';

export type BasicTagProps = PropsWithChildren<{
  text: string;
  color: string;
  isSup?: boolean;
}>;

const BasicTag: FC<BasicTagProps> = ({ text, color, isSup = false }) =>
  isSup ? (
    <sup>{text}</sup>
  ) : (
    <StyledBasicTag className="esg-basic-tag" $bgColor={color}>
      {text}
    </StyledBasicTag>
  );

export default BasicTag;
