import { ModuleElementActivityLog, UserRoleName } from '../models';

export type UserRole = {
  color: string;
  name: UserRoleName;
  id: string;
  description?: string;
};

// Mock array of ActivityLog
const mockActivityLog: ModuleElementActivityLog[] = [
  {
    id: '1',
    targetedAt: new Date('2024-06-01T10:30:00Z'),
    user: {
      id: 'u1',
      firstName: 'John',
      lastName: 'Doe',
      email: 'john.doe@example.com',
      roles: [
        {
          id: 'r1',
          name: 'org admin',
          color: '#FF5733',
          description: 'Administrator role with full permissions.',
        },
      ],
      jobTitle: 'Project Manager',
      organisation: { id: 'org1', name: 'Tech Corp' },
      img: 'https://example.com/profiles/john.png',
    },
    summary: 'User John Doe updated the project plan.',
  },
  {
    id: '2',
    targetedAt: '2024-06-01T15:45:00Z',
    user: {
      id: 'u2',
      firstName: 'Jane',
      lastName: 'Smith',
      email: 'jane.smith@example.com',
      roles: [
        {
          id: 'r2',
          name: 'super admin',
          color: '#33C1FF',
          description: 'Editor role with content modification permissions.',
        },
      ],
      jobTitle: 'Content Specialist',
      organisation: { id: 'org2', name: 'Media Inc' },
    },
    summary: 'User Jane Smith added new content to the module.',
  },
  {
    id: '3',
    targetedAt: new Date('2024-06-01T09:00:00Z'),
    user: {
      id: 'u3',
      firstName: 'Michael',
      lastName: 'Brown',
      email: 'michael.brown@example.com',
      roles: [
        {
          id: 'r3',
          name: 'auditor',
          color: '#75FF33',
          description: 'Viewer role with read-only permissions.',
        },
      ],
      organisation: { id: 'org1', name: 'Tech Corp' },
      img: 'https://example.com/profiles/michael.png',
    },
    summary: 'User Michael Brown viewed the module progress.',
  },
  {
    id: '4',
    targetedAt: '2024-06-04T14:20:00Z',
    user: {
      id: 'u4',
      firstName: 'Emily',
      lastName: 'Clark',
      email: 'emily.clark@example.com',
      roles: [
        {
          id: 'r1',
          name: 'org admin',
          color: '#FF5733',
          description: 'Administrator role with full permissions.',
        },
        {
          id: 'r2',
          name: 'basic user',
          color: '#33C1FF',
          description: 'Editor role with content modification permissions.',
        },
      ],
      jobTitle: 'Team Lead',
      organisation: { id: 'org3', name: 'Solutions Ltd' },
      img: 'https://example.com/profiles/emily.png',
    },
    summary: 'User Emily Clark updated team permissions.',
  },
  {
    id: '5',
    targetedAt: new Date('2024-06-04T11:10:00Z'),
    user: {
      id: 'u5',
      firstName: 'Chris',
      lastName: 'Wilson',
      email: 'chris.wilson@example.com',
      roles: [
        {
          id: 'r3',
          name: 'auditor',
          color: '#75FF33',
          description: 'Viewer role with read-only permissions.',
        },
      ],
      organisation: { id: 'org2', name: 'Media Inc' },
    },
    summary: 'User Chris Wilson commented on the task timeline.',
  },
  {
    id: '6',
    targetedAt: new Date('2024-06-04T08:00:00Z'),
    user: {
      id: 'u6',
      firstName: 'Sarah',
      lastName: 'Miller',
      email: 'sarah.miller@example.com',
      roles: [
        {
          id: 'r2',
          name: 'basic user',
          color: '#33C1FF',
          description: 'Editor role with content modification permissions.',
        },
      ],
      jobTitle: 'Content Developer',
      organisation: { id: 'org4', name: 'Creative Solutions' },
      img: 'https://example.com/profiles/sarah.png',
    },
    summary: 'User Sarah Miller created a new learning module.',
  },
  {
    id: '7',
    targetedAt: new Date('2024-07-02T10:15:00Z'),
    user: {
      id: 'u7',
      firstName: 'David',
      lastName: 'Lee',
      email: 'david.lee@example.com',
      roles: [
        {
          id: 'r1',
          name: 'super admin',
          color: '#FF5733',
          description: 'Administrator role with full permissions.',
        },
      ],
      jobTitle: 'Operations Manager',
      organisation: { id: 'org1', name: 'Tech Corp' },
      img: 'https://example.com/profiles/david.png',
    },
    summary: 'User David Lee archived old project files.',
  },
  {
    id: '8',
    targetedAt: new Date('2024-07-03T16:30:00Z'),
    user: {
      id: 'u8',
      firstName: 'Anna',
      lastName: 'Johnson',
      email: 'anna.johnson@example.com',
      roles: [
        {
          id: 'r3',
          name: 'auditor',
          color: '#75FF33',
          description: 'Viewer role with read-only permissions.',
        },
      ],
      jobTitle: 'QA Specialist',
      organisation: { id: 'org3', name: 'Solutions Ltd' },
    },
    summary: 'User Anna Johnson reviewed the module content.',
  },
  {
    id: '9',
    targetedAt: new Date('2024-07-04T12:00:00Z'),
    user: {
      id: 'u9',
      firstName: 'James',
      lastName: 'Taylor',
      email: 'james.taylor@example.com',
      roles: [
        {
          id: 'r2',
          name: 'basic user',
          color: '#33C1FF',
          description: 'Editor role with content modification permissions.',
        },
      ],
      jobTitle: 'Technical Writer',
      organisation: { id: 'org2', name: 'Media Inc' },
    },
    summary: 'User James Taylor updated the documentation files.',
  },
  {
    id: '10',
    targetedAt: new Date('2024-07-05T09:45:00Z'),
    user: {
      id: 'u10',
      firstName: 'Rachel',
      lastName: 'Green',
      email: 'rachel.green@example.com',
      roles: [
        {
          id: 'r1',
          name: 'super admin',
          color: '#FF5733',
          description: 'Administrator role with full permissions.',
        },
        {
          id: 'r2',
          name: 'basic user',
          color: '#33C1FF',
          description: 'Editor role with content modification permissions.',
        },
      ],
      jobTitle: 'Content Manager',
      organisation: { id: 'org4', name: 'Creative Solutions' },
      img: 'https://example.com/profiles/rachel.png',
    },
    summary: 'User Rachel Green approved the final module draft.',
  },
];

export default mockActivityLog;
