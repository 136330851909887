import { theme } from '@faxi/web-component-library';
import { flex, flexGap } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSimpleInput = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  margin-top: ${theme.sizes.SIZE_32};

  .esg-simple-input {
  }
`;

const StyledSimpleInputField = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: ${theme.sizes.SIZE_20};
  width: 100%;
`;

export { StyledSimpleInput, StyledSimpleInputField };
