import { useUtilities } from '@faxi/web-component-library';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';

import { presignedUploadFetcher } from '../api';
import useMutation from '../api/hooks/useMutation';
import { API_ROUTES } from '../api/routes';
import { PresignedUrl, UploadDocumentsResponse } from '../models';

export function useFileUpload(sessionId: string, companyId: string) {
  const { showSnackBar } = useUtilities();

  //uploading documents
  const { trigger: uploadDocuments } = useMutation<{
    data: UploadDocumentsResponse;
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSIONS_FILES_UPLOAD);

  //presigned upload
  const { trigger: presignedUpload } = useSWRMutation(
    'presignedUpload',
    presignedUploadFetcher
  );

  //confirm upload
  const { trigger: confirmUpload } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSIONS_CONFIRM_UPLOAD(
      sessionId,
      companyId
    )
  );

  //delete file
  const { trigger: deleteFile } = useMutation(
    'delete',
    {
      onSuccess: () => {
        showSnackBar({
          text: 'Successfully deleted file.',
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      },
    },
    true
  );

  const handlePresignedUrlsFileUpload = useCallback(
    (
      files: File[],
      presignedUrls: PresignedUrl[],
      campaignItemId: string,
      fileType: string
    ) => {
      files.forEach((file, index) => {
        const presignedUrl = presignedUrls[index];
        presignedUpload({
          file: file,
          presignedUrl: presignedUrl.presignedUrl,
          fileType: file.type,
        }).then(() => {
          confirmUpload({
            method: 'POST',
            data: {
              fileKey: presignedUrl.fileKey,
              campaignItemId: campaignItemId,
              fileType: fileType,
            },
          });
        });
      });
    },
    [confirmUpload, presignedUpload]
  );

  return {
    uploadDocuments,
    presignedUpload,
    confirmUpload,
    deleteFile,
    handlePresignedUrlsFileUpload,
  };
}
