import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { CampaignItemURLParam } from '../models';

const useURLDataCollectionElementId = (level: 0 | 1 | 2 | 3 = 0) => {
  const {
    subsubsubsubtopic = '',
    subsubsubtopic = '',
    subsubtopic = '',
    subtopic = '',
    topic = '',
    campaign = '',
  } = useParams<CampaignItemURLParam>();

  /**
   * Prioritizes the most specific topic level for data collection.
   * [important]: Be careful if changing the order.
   */
  const dataCollectionElementId = useMemo(
    () =>
      subsubsubsubtopic ||
      subsubsubtopic ||
      subsubtopic ||
      subtopic ||
      topic ||
      campaign,
    [subsubsubsubtopic, subsubsubtopic, subsubtopic, subtopic, topic, campaign]
  );

  const currentSubtopicLevelId = useMemo(() => {
    const subtopics = [
      subtopic,
      subsubtopic,
      subsubsubtopic,
      subsubsubsubtopic,
    ];

    return subtopics[level];
  }, [level, subsubsubsubtopic, subsubsubtopic, subsubtopic, subtopic]);

  return { dataCollectionElementId, currentSubtopicLevelId };
};

export default useURLDataCollectionElementId;
