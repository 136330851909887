import { Button, ButtonProps, MenuRef } from '@faxi/web-component-library';
import { Contributor } from 'models';
import { FC, LegacyRef, PropsWithChildren } from 'react';

import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import { BasicTag } from '../../_atoms';
import Icon from '../../Icon';
import ThreeDotMenu from '../ThreeDotMenu';
import { StyledAssignedContributor } from './AssignedContributor.styled';

export type AssignedContributorProps = PropsWithChildren<
  Contributor & {
    menuItems?: ButtonProps[];
    menuRef?: LegacyRef<MenuRef>;
    onRemove?: () => void;
  }
>;

const AssignedContributor: FC<AssignedContributorProps> = (props) => {
  const { menuRef, isAdmin, name, menuItems, onRemove, role } = props;

  return (
    <StyledAssignedContributor
      role="button"
      className="esg-assigned-contributor"
    >
      <div>
        <div className="user-image-container">
          <Icon name="user-solid" />
        </div>
        <p>{name}</p>
        {isAdmin && <Icon color="var(--BLUE-06)" name="crown" />}
      </div>
      <BasicTag
        text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[role.name]}
        color={role.color}
      />
      {menuItems ? (
        <ThreeDotMenu
          ref={menuRef}
          className="esg-assigned-contributor__menu"
          menuItems={menuItems}
        />
      ) : (
        <Button
          icon={<Icon name="xmark" />}
          onClick={onRemove}
          variant="ghost"
        />
      )}
    </StyledAssignedContributor>
  );
};

export default AssignedContributor;
