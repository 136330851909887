import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSelectUnit = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;

  .esg-select-unit {
    &__fields {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};

      .esg-select-field {
        width: 100%;
      }
    }
  }
`;

export { StyledSelectUnit };
