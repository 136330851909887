import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyleXBRLUploadModal = styled(Modal)`
  .wcl-modal {
    &__main {
      ${flex('column', 'flex-start', 'stretch')};
      gap: ${theme.sizes.SIZE_32};

      > div {
        margin-top: 0;
      }

      > p {
        ${fontSize(theme.sizes.SIZE_20)};

        font-weight: 700;
        text-align: center;
      }
    }
  }
`;

export { StyleXBRLUploadModal };
