import {
  ButtonProps,
  Image,
  StatusElementProps,
  Tooltip,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { ThreeDotMenu, ViewGuard } from 'components';
import { FC, memo, MouseEvent, ReactNode, useEffect, useMemo } from 'react';
import { copyToClipboard, splitStringWithPartsLimit } from 'utils';

import { useUserPermissions } from '../../../hooks';
import { PermissionSections } from '../../../models';
import BasicTag from '../../_atoms/BasicTag/BasicTag.component';
import Icon, { INameExtended } from '../../Icon';
import { StyledUserCard } from './UserCard.styled';

type EventCardType = (event: MouseEvent) => void;

export type UserCardProps = {
  className?: string;
  prefixElement?: ReactNode;
  status?: StatusElementProps<INameExtended>;
  id: string;
  image?: string;
  user: string;
  title: string;
  titleTag?: { text: string; color: string };
  type?: string;
  description?: string;
  withMenu?: boolean;
  view: 'grid' | 'list';
  menuItems?: ButtonProps[];
  includeDefaultMenuItems?: boolean;
  hasActions?: boolean;
  loading?: boolean;
  onSelect?: () => void;
  onClick?: () => void;
  onEdit?: EventCardType;
  onDelete?: EventCardType;
  onDuplicate?: EventCardType;
  onAddDescriptionCallback?: EventCardType;
};

const UserCard: FC<UserCardProps> = (props) => {
  const {
    id,
    image,
    user,
    title,
    titleTag,
    description = '',
    withMenu = false,
    view = 'grid',
    menuItems: pMenuItems,
    includeDefaultMenuItems = true,
    className,
    loading,
    onClick,
    onEdit,
    onDelete,
  } = props;

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const hasPermissions = useUserPermissions(PermissionSections.USER);

  const defaultMenuItems = useMemo(
    () =>
      [
        ...(hasPermissions(['update'])
          ? [
              {
                children: 'Edit',
                icon: <Icon name="pen" />,
                variant: 'ghost',
                iconPosition: 'left',
                onClick: (e: MouseEvent) => {
                  e.stopPropagation();
                  onEdit?.(e);
                },
              },
            ]
          : []),
        ...(hasPermissions(['delete'])
          ? [
              {
                children: 'Delete',
                icon: <Icon name="trash-can" className="color-secondary" />,
                iconPosition: 'left',
                variant: 'delete-ghost',
                onClick: (e: MouseEvent) => {
                  e.stopPropagation();
                  onDelete?.(e);
                },
              },
            ]
          : []),
      ] as ButtonProps[],
    [hasPermissions, onDelete, onEdit]
  );

  const menuItems = useMemo(
    () =>
      !pMenuItems
        ? defaultMenuItems
        : includeDefaultMenuItems
          ? [...pMenuItems, ...defaultMenuItems]
          : pMenuItems,
    [pMenuItems, defaultMenuItems, includeDefaultMenuItems]
  );
  const splitName = splitStringWithPartsLimit(title, ' ', 2);

  useEffect(() => {
    (loading ? showOverlay : hideOverlay)(`#user-${id}`);
  }, [hideOverlay, id, loading, showOverlay]);

  return (
    <StyledUserCard
      onClick={onClick}
      $view={view}
      id={`user-${id}`}
      className={classNames(
        'esg-user-card',
        `esg-user-card--${view}`,
        'hover-effect',
        className
      )}
    >
      {withMenu && (
        <ViewGuard
          section={PermissionSections.USER}
          permissions={['update', 'delete']}
        >
          <ThreeDotMenu className="esg-user-card__menu" menuItems={menuItems} />
        </ViewGuard>
      )}

      <div className="user-image">
        <div className="user-image-container">
          {image ? (
            <Image src={image} alt={user} />
          ) : (
            <Icon name="user-solid" />
          )}
        </div>

        {titleTag && <BasicTag text={titleTag.text} color={titleTag.color} />}
      </div>

      <Tooltip content={title} placement="bottom">
        <div>
          {splitName.map((name, index) => (
            <div key={index} className="esg-user-card__name">
              {name}
            </div>
          ))}
        </div>
      </Tooltip>

      <div
        className="esg-user-card__description"
        onClick={(e) => e.stopPropagation()}
      >
        <Icon name="envelope" className="envelope-icon" />

        <Tooltip content={`mailto:${description}`} placement="bottom">
          <span>{description}</span>
        </Tooltip>

        <Icon
          name="copy"
          onClick={(e) => {
            copyToClipboard(() => {
              showSnackBar({
                text: `Copied ${description} to clipboard.`,
                variant: 'success',
              });
            })(e, description);
          }}
        />
      </div>
    </StyledUserCard>
  );
};

export default memo(UserCard);
