import { PermissionSections, UserRoleForm } from 'models';

export const USER_ROLE_FORM_DATA: UserRoleForm = {
  [PermissionSections.ORGANISATION]: {
    icon: 'shield',
    label: PermissionSections.ORGANISATION,
    actions: {
      read: {
        label: 'Read Organisations and Companies',
        name: 'read',
        excludes: ['create', 'update', 'delete'],
        includes: [],
      },
      create: {
        label: 'Create Organisations and Companies',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      edit: {
        label: 'Edit Organisations and Companies',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete Organisations and Companies',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.CAMPAIGN]: {
    icon: 'leaf',
    label: PermissionSections.CAMPAIGN,
    actions: {
      read: {
        label: 'Read Campaigns, Topics and Subtopics',
        name: 'read',
        excludes: ['create', 'update', 'delete'],
        includes: [],
      },
      create: {
        label: 'Create Campaigns, Topics and Subtopics',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      edit: {
        label: 'Edit Campaigns, Topics and Subtopics',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete Campaigns, Topics and Subtopics',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.SESSION]: {
    icon: 'chart-bar',
    label: PermissionSections.SESSION,
    actions: {
      read: {
        label: 'Read sessions',
        name: 'read',
        excludes: [
          'start_sessions',
          'close_sessions',
          'input_value_sessions',
          'approve_sessions',
          'manage_contributors_sessions',
          'audited',
        ],
        includes: [],
      },
      start: {
        label: 'Start a session',
        name: 'start_sessions',
        excludes: [],
        includes: ['read'],
      },
      close: {
        label: 'Close a session',
        name: 'close_sessions',
        excludes: [],
        includes: ['read'],
      },
      input: {
        label: 'Input Value in a Session',
        name: 'input_value_sessions',
        excludes: [],
        includes: ['read'],
      },
      approve: {
        label: 'Mark form as approved/To review',
        name: 'approve_sessions',
        excludes: [],
        includes: ['read'],
      },
      manage_contributors: {
        label: 'Manage contributors',
        name: 'manage_contributors_sessions',
        excludes: [],
        includes: ['read'],
      },
      audited: {
        label: 'Mark form as audited',
        name: 'audited',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.USER]: {
    icon: 'users',
    label: PermissionSections.USER,
    actions: {
      read: {
        label: 'Read Users',
        name: 'read',
        excludes: ['update', 'delete', 'create'],
        includes: [],
      },
      create: {
        label: 'Create Users',
        name: 'create',
        excludes: [],
        includes: ['read'],
      },
      edit: {
        label: 'Edit Users',
        name: 'update',
        excludes: [],
        includes: ['read'],
      },
      delete: {
        label: 'Delete Users',
        name: 'delete',
        excludes: [],
        includes: ['read'],
      },
    },
  },
  [PermissionSections.LOGS]: {
    icon: 'wave-pulse',
    label: PermissionSections.LOGS,
    actions: {
      'read_logs': {
        label: 'Read Logs',
        name: 'read_logs',
        excludes: [],
        includes: [],
      },
      'read_system_logs': {
        label: 'Read system logs',
        name: 'read_system_logs',
        excludes: [],
        includes: [],
      },
    },
  },
  [PermissionSections.COMMENTS]: {
    icon: 'message',
    label: PermissionSections.COMMENTS,
    actions: {
      'read/write': {
        label: 'Write / Read comments',
        name: 'read/write',
        excludes: [],
        includes: [],
      },
    },
  },
  // TODO
  [PermissionSections.XBRL]: {
    icon: 'tag',
    label: PermissionSections.XBRL,
  },
};
