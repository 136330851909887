import { Table, Tooltip } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import UserImage from '../../../../components/_atoms/UserImage';
import Icon from '../../../../components/Icon';
import { BlockUI } from '../../../../helpers';
import { Document, SessionsPageParams } from '../../../../models';
import SessionHeader from '../SessionHeader';
import { StyledSessionDocuments } from './SessionDocuments.styled';
import { formatCampaignItemPath, formatOrganisationPath } from './utils';

dayjs.extend(isToday);

const SessionDocuments: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();

  const [fileKey, setFileKey] = useState<string | null>(null);

  const {
    data: { data: documents } = { data: [] },
    error: filesError,
    isLoading: isLoadingFiles,
  } = useSWR<{ data: Document[] }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_DOCUMENTS(sessionId!)
  );

  const {
    data: downloadUrl,
    error: downloadUrlError,
    isLoading: isLoadingDownloadUrl,
  } = useSWR<{ data: { downloadUrl: string } }>(
    fileKey &&
      `${API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_DOWNLOAD_URL(fileKey)}`
  );

  useEffect(() => {
    if (downloadUrl?.data?.downloadUrl) {
      const link = document.createElement('a');

      link.href = downloadUrl.data.downloadUrl;

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  }, [downloadUrl]);

  const tableData = documents.map(
    (
      {
        key,
        name,
        campaignItemPath,
        createdAt,
        userInfo,
        organisationPath,
        fileType,
      },
      index
    ) => ({
      id: index + 1,
      fileName: (
        <div
          onClick={() => setFileKey(key)}
          className="esg-session-document-name"
        >
          <Icon name="file-doc" />
          {name}
        </div>
      ),
      uploadedBy: (
        <div className="esg-session-document-user">
          <UserImage
            src={userInfo.src}
            name={[userInfo.firstName, userInfo.lastName].join(' ').trim()}
          />
          {userInfo.firstName} {userInfo.lastName}
        </div>
      ),
      organisationPath: (
        <Tooltip
          content={`Organisation path: ${formatOrganisationPath(organisationPath)}`}
          placement="bottom"
        >
          <div>{organisationPath.split('#').at(-1)}</div>
        </Tooltip>
      ),
      campaignItemPath: (
        <Tooltip
          content={`Campaign path: ${formatCampaignItemPath(campaignItemPath)}`}
          placement="bottom"
        >
          <div>{campaignItemPath.split('#').at(-1)}</div>
        </Tooltip>
      ),
      fileOrigin: fileType.replace('_', ' '),
      createdAt: dayjs(createdAt).isToday()
        ? `Today, ${dayjs(createdAt).format('HH:mm')}`
        : dayjs(createdAt).format('D.M.YYYY, HH:mm'),
    })
  );

  return (
    <StyledSessionDocuments>
      <BlockUI
        loading={isLoadingFiles || isLoadingDownloadUrl}
        error={!!filesError || !!downloadUrlError}
        fallbackCondition={!tableData?.length}
      >
        <SessionHeader heading="Documents" icon="cabinet-filling" />

        <Table
          tableId="session-documents-table"
          tableData={tableData}
          excludeColumns={['id']}
          disableSort
          translationKeys={{
            id: 'Document id',
            fileName: 'Document name',
            uploadedBy: 'Uploaded by',
            organisationPath: 'Organization path',
            campaignItemPath: 'Campaign item path',
            fileOrigin: 'File origin',
            createdAt: 'Uploaded at',
          }}
        />
      </BlockUI>
    </StyledSessionDocuments>
  );
};
export default SessionDocuments;
