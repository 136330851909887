import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaign-items/${apiVersion}`;

export const CAMPAIGN_ITEMS_ROUTES = {
  BASE: (onlyWithDataCollection?: boolean) =>
    `${baseUrl}${onlyWithDataCollection ? '?onlyWithDataCollection=' + onlyWithDataCollection : ''}`,
  CAMPAIGN_ITEM: (id: string) => `${baseUrl}/${id}`,
  CAMPAIGN_DATA_COLLECTION_ELEMENTS: (id: string) =>
    `${baseUrl}/${id}/data-collection-elements`,
  CAMPAIGN_TREE: (id: string) => `${baseUrl}/${id}/tree`,
  DUPLICATE: (campaignItemId: string, elementId: string) =>
    `${baseUrl}/${campaignItemId}/elements/${elementId}/duplicate`,
  CREATE_UPLOAD_URL: `${baseUrl}/taxonomies/create-upload-url`,
  XBRL_TAXONOMIES: `${baseUrl}/taxonomies`,
  XBRL_TAXONOMIES_FIELD_GROUPS: (taxonomyId: string) =>
    `${baseUrl}/taxonomies/${taxonomyId}/field-groups`,
  XBRL_TAXONOMIES_FIELDS: (taxonomyId: string) =>
    `${baseUrl}/taxonomies/${taxonomyId}/fields`,
};
