import { Icon, Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { actionLabels, Notification, Notifications } from 'models';
import { FC, Fragment, PropsWithChildren } from 'react';

import { BasicTag } from '../../../components';
import UserImage from '../../../components/_atoms/UserImage';
import { ApprovalChip } from '../../../components/_molecules/ApprovalProgressBar/components';
import { DATE_FORMAT } from '../../../constants';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../../../pages/Users/constants';
import StatusFlag from './components/StatusFlag';
import { StyledProfileNotifications } from './ProfileNotifications.styled';
import {
  isAssignNotification,
  isMarkNotification,
  isRoleUpdateNotification,
} from './utils';

dayjs.extend(isToday);

const mapNotificationsToTableData = (notifications: Notification[]) =>
  notifications.map((notification, index) => ({
    id: index,
    user: (
      <div className="esg-profile-notifications__user">
        <StatusFlag initialIsRead={notification.isRead} />

        <div className="esg-profile-notifications__user__image-name">
          <UserImage name={notification.name} src={notification.img} />

          <span>{notification.name}</span>

          <BasicTag
            text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[notification.userRole.name]}
            color={notification.userRole.color}
          />
        </div>
      </div>
    ),
    action: <div>{actionLabels[notification.action]}</div>,
    element: (
      <div className="esg-profile-notifications__element">
        {isAssignNotification(notification) ? (
          <Fragment>
            <Icon name="file" />
            {notification.assignedForm}
          </Fragment>
        ) : isMarkNotification(notification) ? (
          <Fragment>
            <Icon name="file" />
            {notification.marked}
          </Fragment>
        ) : isRoleUpdateNotification(notification) ? (
          <BasicTag
            text={
              ROLE_VALUE_TO_DESCRIPTION_MAPPER[notification.updatedRole.name]
            }
            color={notification.updatedRole.color}
          />
        ) : null}
      </div>
    ),
    task: isMarkNotification(notification) ? (
      <div className="esg-profile-notifications__task">
        <span>As</span>
        <ApprovalChip status={notification.markedAs} />
      </div>
    ) : (
      '-'
    ),
  }));

type ProfileNotificationsProps = PropsWithChildren<{
  notifications: Notifications;
}>;

const ProfileNotifications: FC<ProfileNotificationsProps> = (props) => {
  const { notifications } = props;

  // TODO
  // User Notifications are mocked. (Missing BE for functionalities)
  return (
    <StyledProfileNotifications className="esg-profile-notifications">
      <h2>Notifications</h2>

      {notifications?.map(({ notifications, date }, index) => (
        <div className="esg-profile-notifications__on-date" key={index}>
          <div className="esg-profile-notifications__on-date__date">
            {dayjs(date).isToday() ? 'Today' : dayjs(date).format(DATE_FORMAT)}
          </div>

          <Table
            tableData={mapNotificationsToTableData(notifications)}
            tableId="esg-notifications"
            disableSort
            excludeColumns={['id']}
            translationKeys={{
              id: 'ID',
              user: 'User',
              action: 'Action',
              element: 'Element',
              task: 'Task',
            }}
          />
        </div>
      ))}
    </StyledProfileNotifications>
  );
};

export default ProfileNotifications;
