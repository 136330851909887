import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementActivity = styled.div`
  ${flex('column')};

  flex: 1;
  gap: ${theme.sizes.SIZE_24};

  .esg-module-element-activity {
    &__contributors {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
      margin-top: ${theme.sizes.SIZE_20};

      gap: ${theme.sizes.SIZE_24};
    }

    &__date-logs {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_24};

      > span {
        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
      }

      &__log {
        display: grid;
        align-items: center;
        grid-template-columns: ${theme.sizes.SIZE_224} 1fr auto;
        grid-column-gap: ${theme.sizes.SIZE_64};
        border-bottom: 1px solid var(--GRAY-C1);
        padding: ${theme.sizes.SIZE_24} 0;

        > div {
          width: fit-content;

          span {
            ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
          }
        }

        > p {
          ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
        }

        > span {
          ${fontSize(theme.fontSizes.FONT_12, '17px')};

          color: var(--GRAY-70);
        }
      }
    }
  }
`;

export { StyledModuleElementActivity };
