import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledAssignedContributor = styled.div`
  ${flex('row', 'space-between', 'center')};
  ${elevate_l};

  gap: ${theme.sizes.SIZE_8};
  padding: ${theme.sizes.SIZE_8};
  border-radius: ${theme.sizes.SIZE_8};

  & > div {
    ${flex('row', 'flex-start', 'center')};
    gap: ${theme.sizes.SIZE_8};
  }

  p {
    ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};
    font-weight: 400;
  }
  .esg-basic-tag {
    ${fontSize(theme.fontSizes.FONT_12)};
    padding: ${theme.sizes.SIZE_6} ${theme.sizes.SIZE_8};
    margin-left: auto;
  }

  img {
    ${size(theme.sizes.SIZE_24)};
    border-radius: 50%;
  }

  .esg-assigned-contributor {
    &__menu > .esg-three-dot-menu__dots {
      ${size(theme.sizes.SIZE_20)};
    }
  }

  .user-image-container {
    ${size(theme.sizes.SIZE_20)};
  }
`;

export { StyledAssignedContributor };
