import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import Icon from 'components/Icon';
import { Organisation } from 'models';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  NavigateFunction,
  Outlet,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { findNode } from 'utils';

import { API_ROUTES } from '../../api/routes';
import useOrganisationTree from '../hooks/useOrganisationTree';
import OrganisationContext, {
  OrganisationContextProps,
} from './Organisation.context';

type OrganisationProviderProps = PropsWithChildren;

const redirectAfterDeletion = (
  navigate: NavigateFunction,
  deleteId: string,
  activeOrganisation?: Organisation
) => {
  const parentIds = activeOrganisation?.path.ids ?? [];

  const isActiveOrganisationDeleted = deleteId === activeOrganisation?.id;

  if (isActiveOrganisationDeleted) {
    // the last element in `parentIds` is the parent of the active organisation
    const newOrganisationId = parentIds.length ? `/${parentIds.at(-1)}` : '';

    navigate(`/organisations${newOrganisationId}`);
    return;
  }

  const isParentOrganisationDeleted = parentIds.includes(deleteId);

  if (isParentOrganisationDeleted) {
    // the first element before the deleted organisation is its parent organisation
    const indexOfNewActiveOrganisation = parentIds.indexOf(deleteId) - 1;

    const newOrganisationId = parentIds[indexOfNewActiveOrganisation]
      ? `/${parentIds[indexOfNewActiveOrganisation]}`
      : '';

    navigate(`/organisations${newOrganisationId}`);
  }
};

const OrganisationProvider: FC<OrganisationProviderProps> = () => {
  const navigate = useNavigate();
  const { prompts, showOverlay, hideOverlay } = useUtilities();

  const params = useParams<{
    organisationId?: string;
  }>();

  const [deletingOrgId, setDeletingOrgId] = useState<string>();
  const { trigger: deleteOrg } = api.useMutation(
    deletingOrgId && API_ROUTES.ORGANISATIONS.ORGANISATION(deletingOrgId)
  );

  const { organisationTree, rootOrganisation, error, mutateOrganisations } =
    useOrganisationTree('organisations');

  const currentOrganisationId = params.organisationId || rootOrganisation?.id;

  const activeOrganisation = useMemo(
    () =>
      rootOrganisation && currentOrganisationId
        ? findNode(currentOrganisationId, [rootOrganisation])
        : undefined,
    [currentOrganisationId, rootOrganisation]
  );

  const deleteOrganisation = useCallback(
    async (
      org: Organisation,
      trigger?: HTMLButtonElement,
      callback?: () => void
    ) => {
      setDeletingOrgId(org.id);

      const proceed = await prompts.delete({
        type: 'delete',
        title: `Remove ${org?.name}`,
        content: `Are you sure you want to delete organisation ${org?.name}?`,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Do not delete',
        buttonIcon: 'trash-can',
        iconPosition: 'left',
        confirmButtonVariant: 'delete-ghost',
        triggerRef: trigger as HTMLButtonElement,
        titleIcon: <Icon name="triangle-exclamation" />,
      });

      if (!proceed) return;
      showOverlay('body');

      try {
        await deleteOrg({ method: 'DELETE' });
        await mutateOrganisations();
        callback?.();
        redirectAfterDeletion(navigate, org.id, activeOrganisation);

        setDeletingOrgId(undefined);
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [
      prompts,
      showOverlay,
      deleteOrg,
      mutateOrganisations,
      navigate,
      activeOrganisation,
      hideOverlay,
    ]
  );

  return (
    <OrganisationContext.Provider
      value={{
        loading: false,
        error: !!error,
        activeOrganisation,
        organisationTree,
        deleteOrganisation,
        mutateOrganisations,
      }}
    >
      <Outlet />
    </OrganisationContext.Provider>
  );
};

const useOrganisationProvider = (): OrganisationContextProps =>
  useContext(OrganisationContext);

export { OrganisationProvider, useOrganisationProvider };
