import { useCallback, useEffect, useState } from 'react';

interface UseInfiniteScrollProps {
  containerRef: React.RefObject<HTMLElement>;
  hasNextPage: boolean;
  isLoading: boolean;
  loadMore: () => void;
}

const useInfiniteScroll = ({
  containerRef,
  hasNextPage,
  isLoading,
  loadMore,
}: UseInfiniteScrollProps) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(() => {
    if (!containerRef.current || isLoading || !hasNextPage || isFetching)
      return;

    const { scrollHeight, scrollTop, offsetHeight } = containerRef.current;
    const isBottomReached =
      Math.abs(scrollHeight - offsetHeight - scrollTop) < 1;

    if (isBottomReached) {
      setIsFetching(true);
      loadMore();
    }
  }, [containerRef, hasNextPage, isLoading, isFetching, loadMore]);

  useEffect(() => {
    const currentRef = containerRef.current;

    if (!currentRef) return;

    currentRef.addEventListener('scroll', handleScroll);

    return () => currentRef.removeEventListener('scroll', handleScroll);
  }, [containerRef, handleScroll]);

  useEffect(() => {
    if (!isLoading) {
      setIsFetching(false);
    }
  }, [isLoading]);

  return { isFetching };
};

export default useInfiniteScroll;
