import classNames from 'classnames';
import { FC, memo, PropsWithChildren } from 'react';

import { AddXBRLTagModal, XBRLTagChip } from './components';
import { StyledXBRLTag } from './XBRLTag.styled';

export type XBRLTagProps = PropsWithChildren<{
  className?: string;
  value?: string;
  onChange: (value?: string) => void;
  parent: string;
}>;

const XBRLTag: FC<XBRLTagProps> = ({ className, value, onChange, parent }) => (
  <StyledXBRLTag className={classNames(className, 'esg-xbrl-tag')}>
    <strong className="esg-xbrl-tag__label">XBRL Tag</strong>

    {value ? (
      <XBRLTagChip parent={parent} value={value} onRemove={onChange} />
    ) : (
      <AddXBRLTagModal onChange={onChange} />
    )}
  </StyledXBRLTag>
);

export default memo(XBRLTag);
