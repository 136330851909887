import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../api/routes';
import { DocumentResponseType } from '../models';
import { useFileUpload } from './useFileUpload';

export function useFormFileUpload(
  sessionId: string,
  companyId: string,
  organisationSessionId: string,
  campaignItemId: string
) {
  const {
    uploadDocuments,
    presignedUpload,
    handlePresignedUrlsFileUpload,
    deleteFile,
  } = useFileUpload(sessionId!, companyId!);

  // Get uploaded files
  const { data: uploadedFiles, isLoading: isLoadingFiles } = useSWR<{
    data: Record<string, DocumentResponseType[]>;
  }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS(
      sessionId!,
      companyId!,
      campaignItemId!
    )
  );

  // Cast the data to Record<string, File[]> because the fileUploadModal requires a File[] type.
  const initialFiles: Record<string, File[]> = useMemo(() => {
    if (!uploadedFiles?.data) return {};
    return Object.keys(uploadedFiles.data).reduce(
      (acc, id) => {
        acc[id] = uploadedFiles.data[id].map(
          (fileData) =>
            new File(
              [new Blob([], { type: fileData.contentType })],
              fileData.name,
              {
                type: fileData.contentType,
              }
            )
        );
        return acc;
      },
      {} as Record<string, File[]>
    );
  }, [uploadedFiles?.data]);

  const checkFileChanges = useCallback(
    (currentFiles: Record<string, File[]>) => {
      const deletedFiles: string[] = [];
      const addedFiles: { file: File; formElementId: string }[] = [];

      if (!uploadedFiles) return { deletedFiles, addedFiles };

      Object.entries(currentFiles).forEach(([key, fileList]) => {
        const localFileNames = new Set(
          initialFiles[key]?.map((file) => file.name) || []
        );
        fileList.forEach((file) => {
          if (!localFileNames.has(file.name))
            addedFiles.push({ file, formElementId: key });
        });
      });

      Object.entries(uploadedFiles?.data).forEach(([key, uploadedFileList]) => {
        const fileRecordNames = new Set(
          currentFiles[key]?.map((file) => file.name) || []
        );

        uploadedFileList.forEach(({ id, name }) => {
          if (!fileRecordNames.has(name)) deletedFiles.push(id);
        });
      });

      return { deletedFiles, addedFiles };
    },
    [initialFiles, uploadedFiles]
  );

  const uploadFormFiles = useCallback(
    async (
      files: {
        fileType?: string;
        type: string;
        name: string;
        formElementId?: string;
      }[],
      fileList: File[]
    ) => {
      if (!files.length) return;

      const response = await uploadDocuments({
        method: 'POST',
        data: {
          files: files.map((file) => ({
            fileType: 'form_attachment',
            contentType: file.type,
            fileName: file.name,
            formElementId: file.formElementId,
          })),
          organisationSessionId,
          sessionId,
          organisationId: companyId,
          campaignItemId,
        },
      });

      handlePresignedUrlsFileUpload(
        fileList,
        response.data.presignedUrls,
        campaignItemId,
        'form_attachment'
      );
    },
    [
      campaignItemId,
      companyId,
      handlePresignedUrlsFileUpload,
      organisationSessionId,
      sessionId,
      uploadDocuments,
    ]
  );

  const removeFiles = useCallback(
    async (deletedFiles: string[]) => {
      await Promise.all(
        deletedFiles.map((id) =>
          deleteFile({
            method: 'DELETE',
            url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS_DELETE(
              id
            ),
          })
        )
      );
    },
    [deleteFile]
  );

  return {
    uploadDocuments,
    presignedUpload,
    uploadFormFiles,
    removeFiles,
    uploadedFiles,
    isLoadingFiles,
    initialFiles,
    checkFileChanges,
  };
}
