import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionCampaignForm = styled.div`
  height: 100%;

  .esg-session-campaign-form {
    &__form {
      ${flex('column')};
      ${size('100%')};
      gap: ${theme.sizes.SIZE_20};
      margin-top: ${theme.sizes.SIZE_48};

      &__container {
        ${flex('row', 'flex-start', 'baseline')};
        gap: ${theme.sizes.SIZE_32};

        &__buttons {
          ${flex('row', 'space-between')};
          width: ${theme.sizes.SIZE_64};
        }

        &__comments-button {
          font-size: ${theme.fontSizes.FONT_18};
        }
      }

      &__actions {
        ${flex('row', 'flex-end', 'center')};
        padding: ${theme.sizes.SIZE_32} 0;
      }
    }
  }
`;

export { StyledSessionCampaignForm };
