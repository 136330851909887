import { theme } from '@faxi/web-component-library';
import { flex, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserProfileImage = styled.div`
  ${flex('column', 'center', 'center')};
  ${size(theme.sizes.SIZE_136)};
  background-color: var(--GRAY-EF);
  border-radius: ${theme.sizes.SIZE_16};
  margin-bottom: ${theme.sizes.SIZE_48};
  align-self: flex-start;
  color: var(--GRAY-98);
  position: relative;

  > i {
    svg {
      ${size(theme.sizes.SIZE_104)};
    }
  }

  .esg-user-profile-image {
    &__plus {
      ${size(theme.sizes.SIZE_32)};
      ${flex('column', 'center', 'center')};
      ${position(
        'absolute',
        `right -${theme.sizes.SIZE_8} bottom -${theme.sizes.SIZE_8}`
      )};

      ${size(theme.sizes.SIZE_32)};
      min-width: unset;
      min-height: unset;
      padding: unset;
    }
  }
`;

export { StyledUserProfileImage };
