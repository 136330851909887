import { Spinner } from '@faxi/web-component-library';
import classNames from 'classnames';
import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import { StyledInfiniteScrollContainer } from './InfiniteScrollContainer.styled';

export type InfiniteScrollContainerProps = PropsWithChildren<{
  className?: string;
  isFetching: boolean;
}>;

const InfiniteScrollContainer: ForwardRefRenderFunction<
  HTMLDivElement,
  InfiniteScrollContainerProps
> = ({ className, children, isFetching }, ref) => {
  return (
    <StyledInfiniteScrollContainer
      ref={ref}
      className={classNames('esg-infinite-scroll-container', className)}
    >
      {children}
      {isFetching && <Spinner size={40} color="#000" />}
    </StyledInfiniteScrollContainer>
  );
};

export default forwardRef(InfiniteScrollContainer);
