import { Dropdown, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDeadlineCalendar = styled(Dropdown)`
  .calendar-trigger-with-value {
    ${flex('row', 'flex-start', 'center')};
    gap: ${theme.sizes.SIZE_12};

    > div:first-of-type {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_12};
    }
  }
`;

export { StyledDeadlineCalendar };
