import { Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import { FC, Fragment, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import { BasicTag } from '../../../../../../../components';
import Icon from '../../../../../../../components/Icon';
import { BlockUI } from '../../../../../../../helpers';
import { UserActivityLog } from '../../../../../../../models';
import { StyledUserActivityLogs } from './UserActivityLogs.styled';

export type UserActivityLogsProps = PropsWithChildren<{}>;

export type UserActivityTableData = {
  id: number;
  date: string;
  event: JSX.Element;
  performedBy: JSX.Element;
  message: JSX.Element;
};

const EXCLUDE_TABLE_COLUMNS: (keyof UserActivityTableData)[] = ['id'];

const TABLE_COLUMNS = {
  id: 'id',
  date: 'Date',
  event: 'Event',
  performedBy: 'Performed By',
  message: 'Message',
};

const EVENT_COLORS = {
  'MODIFY': 'var(--BLUE-00)',
  'INSERT': 'var(--GREEN-12)',
  'REMOVE': 'var(--RED-EB)',
};

const UserActivityLogs: FC<UserActivityLogsProps> = () => {
  const { userId } = useParams();

  const {
    data: { data = [] } = {},
    isLoading,
    error,
  } = useSWR<{
    data: UserActivityLog[];
  }>(API_ROUTES.ACTIVITY_LOGS_ROUTES.USER(userId!));

  const tableData = useMemo(
    () =>
      data.map(({ event, jobTitle, performedBy, timestamp }, index) => ({
        id: index, // TODO: for some reason, the `table` expects the `id` to be a number (we cannot pass a string)
        date: dayjs(timestamp).format('MMMM D, YYYY h:mm A'),
        event: <BasicTag text={event} color={EVENT_COLORS[event]} />,
        performedBy: (
          <div className="esg-user-activity-logs__user">
            <Icon name="user" />
            {performedBy.email}
          </div>
        ),
        message: (
          <Fragment>
            Updated job title from <strong>{jobTitle?.old || ''}</strong> to{' '}
            <strong>{jobTitle?.new || ''}</strong>
          </Fragment>
        ),
      })),
    [data]
  );

  return (
    <StyledUserActivityLogs className="esg-user-activity-logs">
      <h2>Activity Logs</h2>

      <BlockUI loading={isLoading} fallbackCondition={error || !data?.length}>
        <Table<UserActivityTableData>
          tableId="user-activity-logs-table"
          tableData={tableData}
          excludeColumns={EXCLUDE_TABLE_COLUMNS}
          disableSort
          translationKeys={TABLE_COLUMNS}
        />
      </BlockUI>
    </StyledUserActivityLogs>
  );
};

export default UserActivityLogs;
