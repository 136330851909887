import { Heading, Spinner } from '@faxi/web-component-library';
import classNames from 'classnames';
import { ApprovalProgressBar } from 'components';
import Icon, { INameExtended } from 'components/Icon';
import { FC } from 'react';
import { NavLinkProps } from 'react-router-dom';

import { Progress } from '../../../../../../models';
import { StyledSessionDashboardCard } from './SessionDashboardCard.styled';

export type SessionDashboardCardProps = {
  className?: string;
  icon: INameExtended;
  name: string;
  id: string;
  description?: string;
  items: {
    name: string;
    id: string;
    progress: Progress;
  }[];
  loading?: boolean;
  loadingText?: string;
  disableLinking?: boolean;
} & Partial<NavLinkProps>;

const SessionDashboardCard: FC<SessionDashboardCardProps> = ({
  className,
  name,
  icon,
  id,
  description,
  items,
  loading,
  loadingText,
  disableLinking,
}) => (
  <StyledSessionDashboardCard
    to={id}
    // TODO: this is for demo purposes...
    {...(disableLinking && {
      onClick: (e) => {
        e.preventDefault();
      },
    })}
    className={classNames(
      'esg-session-dashboard-card',
      'hover-effect',
      { 'esg-session-dashboard-card--loading': loading },
      className
    )}
  >
    <div>
      <div className="esg-session-dashboard-card__header">
        <Icon name={icon} />

        <Heading level="2">{name}</Heading>
      </div>

      <small>{description || 'No description'}</small>

      <div className="esg-session-dashboard-card__content">
        {items.map(({ id, name, progress }) => (
          <div className="esg-session-dashboard-card__content__item" key={id}>
            <div className="esg-session-dashboard-card__content__item__name">
              <Icon name="folder" />
              {name}
            </div>
            <ApprovalProgressBar
              progress={progress}
              status={progress?.progress === 100 ? 'Completed' : 'In Progress'}
            />
          </div>
        ))}
      </div>
    </div>

    {loading && (
      <div className="esg-session-dashboard-card__loading">
        <Spinner size={40} color={'#000'} />
        {loadingText && <div>{loadingText}</div>}
      </div>
    )}
  </StyledSessionDashboardCard>
);

export default SessionDashboardCard;
