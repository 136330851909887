import { FormField } from '@faxi/web-form';
import { SwitchDataModule } from 'models';
import { FC } from 'react';

import SwitchField from '../../../../../components/_fields/SwitchField';
import { StyledBaseSwitchFieldConfiguration } from './BaseSwitchFieldConfiguration.styled';

export type BaseSwitchFieldConfigurationProps = SwitchDataModule & {
  hasConditions?: boolean;
  initialData?: Record<string, any>;
};

const BaseSwitchFieldConfiguration: FC<BaseSwitchFieldConfigurationProps> = ({
  config,
  hasConditions = true,
  id,
  renderModuleField,
}) => {
  return (
    <StyledBaseSwitchFieldConfiguration className="esg-switch-field-configuration">
      <FormField
        name="defaultValue"
        id={id}
        component={SwitchField}
        label="Default checked?"
      />

      {hasConditions && (
        <>
          <div className="esg-switch-field-configuration__divider" />

          <p>Conditions</p>

          {renderModuleField?.('off')}
          {renderModuleField?.('on')}
        </>
      )}
    </StyledBaseSwitchFieldConfiguration>
  );
};

export default BaseSwitchFieldConfiguration;
