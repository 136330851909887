import { Button, Input } from '@faxi/web-component-library';
import classNames from 'classnames';
import { InlineEditableType } from 'models';
import { FC, LegacyRef, useCallback, useRef, useState } from 'react';

import useClickOutsideComponent from '../../../hooks/useClickOutside';
import Icon from '../../Icon';
import { StyledInlineEditable } from './InlineEditable.styled';

const InlineEditable: FC<InlineEditableType> = ({
  value = '',
  placeholderText,
  onBlur,
  onSave,
  onChange,
  onDiscard,
  conditionalElements,
  hasConditions,
  id,
  moduleElement,
  disabled,
  ...rest
}) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const lastValue = useRef<string>(value);
  const initialValue = useRef<string>(value);

  const handleDiscard = useCallback(() => {
    onChange?.(initialValue.current);
    onDiscard?.();
    setIsEditing(false);
  }, [onChange, onDiscard]);

  const handleSave = useCallback(() => {
    initialValue.current = lastValue.current;

    onSave?.(lastValue.current);
    onChange?.(lastValue.current);
    setIsEditing(false);
  }, [onSave, onChange]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') return;

      e.stopPropagation();
      e.preventDefault();

      handleSave();
    },
    [handleSave]
  );

  const handleInputChange = useCallback(
    (v: string) => {
      onChange?.(v);
      lastValue.current = v;
    },
    [onChange]
  );

  const { componentRef } = useClickOutsideComponent(handleDiscard);

  const handleEditClick = useCallback(() => {
    if (!disabled) {
      setIsEditing(true);

      setTimeout(() => {
        inputContainerRef?.current?.getElementsByTagName('input')?.[0]?.focus();
      }, 0);
    }
  }, [disabled]);

  return (
    <StyledInlineEditable
      className="esg-inline-editable"
      ref={componentRef}
      isEditing={isEditing}
      {...rest}
    >
      <div
        className={classNames('esg-inline-editable__input', {
          'esg-inline-editable__input--is-editing': isEditing,
        })}
      >
        <Input
          tabIndex={0}
          ref={inputContainerRef as LegacyRef<HTMLInputElement>}
          type="text"
          value={lastValue.current}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <div className="esg-inline-editable__input__actions">
          <Button
            onClick={handleSave}
            icon={<Icon name="check" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
            disabled={lastValue.current === initialValue.current}
          />
          <Button
            onClick={() => {
              lastValue.current = initialValue.current;
              handleDiscard();
            }}
            icon={<Icon name="xmark" />}
            className="esg-inline-editable__input__actions__action"
            tabIndex={0}
          />
        </div>
      </div>

      <p onClick={handleEditClick}>{value || placeholderText}</p>
    </StyledInlineEditable>
  );
};

export default InlineEditable;
