import { Divider } from '@faxi/web-component-library';
import { UploadDataModule } from 'models';
import { FC } from 'react';

import { StyledBaseUploadFieldConfiguration } from './BaseUploadFieldConfiguration.styled';

export type BaseUploadFieldConfigurationProps = UploadDataModule;

const BaseUploadFieldConfiguration: FC<BaseUploadFieldConfigurationProps> = ({
  hasConditions = true,
  config,
  renderModuleField,
}) => {
  return (
    <StyledBaseUploadFieldConfiguration className="esg-integration-field-configuration">
      <p>Please set up File upload options</p>

      {hasConditions && (
        <>
          {renderModuleField?.('file-is-uploaded')}

          <Divider />
        </>
      )}
    </StyledBaseUploadFieldConfiguration>
  );
};

export default BaseUploadFieldConfiguration;
