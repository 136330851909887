import {
  ModalProps,
  ModalRef,
  useUtilities,
} from '@faxi/web-component-library';
import { FC, useCallback, useRef } from 'react';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import { EntityFormModal } from '../../../../components';
import { Session } from '../../../../models';
import { useCampaignOptions } from '../../hooks';

const SessionModal: FC<Pick<ModalProps, 'onClose'>> = ({ onClose }) => {
  const { showSnackBar } = useUtilities();

  const { campaignsOptions, loadingCampaigns } = useCampaignOptions();

  const modalRef = useRef<ModalRef>(null);

  const { trigger: createSession, isMutating: isCreateSessionMutating } =
    useMutation<{
      data: Session;
    }>(
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE(),
      {
        onSuccess: ({ data }) => {
          showSnackBar({
            text: `${data.name} has been created.`,
            variant: 'success',
            actionButtonText: 'Dismiss',
          });

          modalRef?.current?.close();
        },
      },
      true
    );

  const onSubmit = useCallback(
    async (data: Record<string, string>) => {
      const { campaign, name, description } = data;

      createSession({
        method: 'POST',
        data: { name, description, campaignId: campaign },
      });
    },
    [createSession]
  );

  return (
    <EntityFormModal
      ref={modalRef}
      renderAsPortal
      title="New session"
      conditionallyControlled
      onClose={onClose}
      onSubmit={onSubmit}
      loading={isCreateSessionMutating}
      fieldProps={{
        campaign: {
          options: campaignsOptions,
          loading: loadingCampaigns,
        },
      }}
      fieldsConfiguration={{
        NAME: true,
        DESCRIPTION: true,
        EMAIL: false,
        ROLE: false,
        TYPE: false,
        CAMPAIGN: true,
        TAXONOMY_ID: false,
      }}
    />
  );
};

export default SessionModal;
