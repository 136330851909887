import { Button } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { FC, PropsWithChildren } from 'react';

import { StyledUserProfileImage } from './UserProfileImage.styled';

export type UserProfileImageProps = PropsWithChildren<{
  onClick?: () => void;
}>;

const UserProfileImage: FC<UserProfileImageProps> = (props) => {
  const { onClick } = props;

  return (
    <StyledUserProfileImage className="esg-user-profile-image">
      <Icon name="user-circle" />
      <Button
        variant="outline"
        className="esg-user-profile-image__plus"
        onClick={onClick}
        icon={<Icon name="plus" />}
        // TODO: implement BE for user profile image
        disabled
      />
    </StyledUserProfileImage>
  );
};

export default UserProfileImage;
