import {
  applyRef,
  Button,
  Checkbox,
  ModalProps,
  ModalRef,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import {
  forwardRef,
  ForwardRefRenderFunction,
  Fragment,
  PropsWithChildren,
  useRef,
} from 'react';

import { IDataModule } from '../../../../../../models';
import { StyledToggleLockFieldModal } from './ToggleLockFieldModal.styled';
import { getLockFieldProps } from './utils';

export type ToggleLockFieldModalProps = PropsWithChildren<
  ModalProps & {
    onConfirm: (id: string, isLocked: boolean) => void;
    module: IDataModule;
  }
>;

const ToggleLockFieldModal: ForwardRefRenderFunction<
  ModalRef,
  ToggleLockFieldModalProps
> = (props, ref) => {
  const { onConfirm, module, ...rest } = props;

  const modalRef = useRef<ModalRef>();

  const { isLocked, title, lockIcon, modalInfoText } =
    getLockFieldProps(module);

  return (
    <StyledToggleLockFieldModal
      className="esg-toggle-lock-field-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      title={title}
      icon={lockIcon}
      footer={
        <Fragment>
          <Checkbox label="Don't show this again" controlledByParent={false} />
          <div className="esg-toggle-lock-field-modal__buttons">
            <Button variant="ghost" onClick={() => modalRef.current?.close()}>
              Cancel
            </Button>
            <Button
              className={classNames(
                'esg-toggle-lock-field-modal__buttons-button',
                {
                  'esg-toggle-lock-field-modal__buttons-button--lock': isLocked,
                }
              )}
              icon={lockIcon}
              onClick={() => {
                onConfirm(module.id, isLocked!);
                modalRef.current?.close();
              }}
            >
              {title}
            </Button>
          </div>
        </Fragment>
      }
      {...rest}
    >
      {modalInfoText.map((sentence, index) => (
        <div key={index}>{sentence}</div>
      ))}
    </StyledToggleLockFieldModal>
  );
};

export default forwardRef(ToggleLockFieldModal);
