import { SelectOption } from '@faxi/web-component-library';

export const XBRL_TAXONOMIES = [
  'XBRL Taxonomy package 1',
  'XBRL Taxonomy package 2',
  'XBRL Taxonomy package 3',
  'XBRL Taxonomy package 4',
  'XBRL Taxonomy package 5',
  'XBRL Taxonomy package 6',
];

export const XBRL_TAXONOMIES_OPTIONS = XBRL_TAXONOMIES.map((taxonomy) => ({
  value: taxonomy.replaceAll(' ', '_').toLowerCase(),
  label: taxonomy,
}));

export const XBRL_PACKAGES_OPTIONS = [
  ['environment', 'pollution'],
  ['finance', 'sustainability', 'investment'],
  ['health'],
  ['technology', 'innovation', 'AI', 'software'],
  ['education', 'environment'],
  ['pollution', 'sustainability'],
].reduce(
  (acc: Record<string, SelectOption[]>, curr, index) => ({
    ...acc,
    [XBRL_TAXONOMIES_OPTIONS[index].value]: curr.map((tag) => ({
      value: tag,
      label: tag,
    })),
  }),
  {}
);
