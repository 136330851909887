import {
  GlowScroll,
  LinkParserWrapper,
  useCallbackRef,
} from '@faxi/web-component-library';
import { useGetComments } from 'api';
import { API_ROUTES } from 'api/routes';
import { NoData } from 'components';
import {
  ApiData,
  Comment as TypeComment,
  CommentType,
  DataModuleElementDrawerComponentProps,
} from 'models';
import { FC, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import InfiniteScrollContainer from '../../../../../components/_molecules/InfiniteScrollContainer';
import { BlockUI } from '../../../../../helpers';
import useInfiniteScroll from '../../../../../hooks/useInfiniteScroll';
import { useRootProvider } from '../../../../../providers/Root';
import {
  generateOptimisticComment,
  optimisticUpdateCommentsCache,
  updateWithErrorComment,
} from '../../utils';
import Comment from '../Comment';
import PostComment from '../PostComment';
import { StyledModuleElementComments } from './ModuleElementComments.styled';

const ModuleElementComments: FC<DataModuleElementDrawerComponentProps> = ({
  elementId,
  organisationSessionId,
  updateNumberOfUnreadComments,
}) => {
  //TODO: change when we migrate drawer to sessions
  const { campaignItemId = '' } = useParams();

  const {
    items,
    nextPage,
    isLoading,
    mutate: mutateComments,
    isValidating,
    setSize,
  } = useGetComments(organisationSessionId, campaignItemId, elementId, {
    revalidateFirstPage: false,
    revalidateAll: false,
  });

  const { user: currentUser } = useRootProvider();

  const [commentsRef, setCommentsRef] = useCallbackRef<HTMLDivElement>();

  const { trigger } = useMutation<ApiData<TypeComment>>(
    API_ROUTES.COMMENTS.SESSION_FORM_ELEMENT_COMMENTS(
      organisationSessionId,
      campaignItemId,
      elementId
    ),
    { revalidate: false }
  );

  const { isFetching } = useInfiniteScroll({
    containerRef: { current: commentsRef },
    hasNextPage: Boolean(nextPage),
    isLoading: isValidating,
    loadMore: () => setSize((prev) => prev + 1),
  });

  const { trigger: readComments, isMutating } = useMutation<
    ApiData<{ numberOfUnreadComments: number }>
  >(
    API_ROUTES.COMMENTS.SESSION_FORM_ELEMENT_COMMENTS_READ(
      organisationSessionId,
      campaignItemId,
      elementId
    ),
    { revalidate: false }
  );

  const onDeleteFailedComment = useCallback(
    (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => comment.id !== item.id),
            },
          })),
        {
          revalidate: false,
        }
      );
    },
    [mutateComments]
  );

  const postComment = useCallback(
    async (message: CommentType[]) => {
      try {
        commentsRef?.scroll({ top: 0, behavior: 'smooth' });

        await trigger({
          data: { content: message },
          method: 'POST',
        });
      } catch (e) {
        const comment = generateOptimisticComment(message, currentUser!);

        mutateComments((prev) => updateWithErrorComment(comment, prev), {
          revalidate: false,
        });
      }
    },
    [commentsRef, currentUser, mutateComments, trigger]
  );

  const onPostComment = useCallback(
    async (message: CommentType[]) => {
      const comment = generateOptimisticComment(message, currentUser!);

      mutateComments((prev) => optimisticUpdateCommentsCache(comment, prev), {
        revalidate: false,
      });

      await postComment(message);
    },
    [currentUser, mutateComments, postComment]
  );

  const onRetryComment = useCallback(
    async (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => item.id !== comment.id),
            },
          })),
        {
          revalidate: false,
        }
      );

      await onPostComment(comment.content);
    },
    [mutateComments, onPostComment]
  );

  const markAsRead = useCallback(
    async (unreadIds: string[]) => {
      const { data } = await readComments({
        method: 'POST',
        data: {
          readComments: unreadIds,
        },
      });
      await mutateComments();
      updateNumberOfUnreadComments?.(data.numberOfUnreadComments);
    },
    [mutateComments, readComments, updateNumberOfUnreadComments]
  );

  useEffect(() => {
    if (isValidating || isMutating) return;

    const unreadIds = items
      ?.filter(({ isRead }) => !isRead)
      .map(({ id }) => id.toString());

    if (unreadIds?.length) {
      markAsRead(unreadIds);
    }
  }, [isMutating, isValidating, items, markAsRead]);

  return (
    <StyledModuleElementComments className="esg-module-element-comments">
      <BlockUI loading={isLoading}>
        <GlowScroll variant="gray">
          <InfiniteScrollContainer
            ref={setCommentsRef}
            className="esg-module-element-comments__comments"
            isFetching={isFetching}
          >
            {items.length > 0 ? (
              items?.map((comment) => (
                <LinkParserWrapper key={comment.id}>
                  <Comment
                    comment={comment}
                    isError={!!comment?.isError && comment.elementId === 'temp'}
                    onDelete={onDeleteFailedComment}
                    onRetry={onRetryComment}
                  />
                </LinkParserWrapper>
              ))
            ) : (
              <NoData title="No comments" iconName="message" />
            )}
          </InfiniteScrollContainer>
        </GlowScroll>
        <PostComment onSubmit={onPostComment} />
      </BlockUI>
    </StyledModuleElementComments>
  );
};

export default ModuleElementComments;
