import { Drawer, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementDrawer = styled(Drawer)`
  .esg-module-element-drawer {
    &__title {
      ${flex('row', 'initial', 'center')}
      width: 100%;
      gap: ${theme.sizes.SIZE_12};

      &__status {
        margin-left: auto;
      }
    }

    &__tabs {
      .wcl-tabs {
        height: ${theme.sizes.SIZE_32};

        label {
          align-items: flex-start;
        }

        &::before {
          content: unset;
        }

        &__option--active {
          background-color: var(--GRAY-EF);
        }
      }
      > div {
        gap: ${theme.sizes.SIZE_32};

        > label {
          border: unset;
          border-radius: ${theme.sizes.SIZE_8};
          padding: 6px ${theme.sizes.SIZE_10};
          width: ${theme.sizes.SIZE_120};

          &:first-of-type {
            border-left: unset;
            border-radius: ${theme.sizes.SIZE_8};
          }

          &:last-of-type {
            border-right: unset;
            border-radius: ${theme.sizes.SIZE_8};
          }
        }
      }
    }
  }

  .wcl-drawer {
    &__main {
      ${flex('column')};

      .esg-module-element-comments {
        flex: 1;
      }
    }
  }

  &.wcl-drawer {
    &--right {
      width: 60%;
    }
  }

  .wcl-divider {
    margin: ${theme.sizes.SIZE_32} 0 ${theme.sizes.SIZE_16};
  }
`;

export { StyledModuleElementDrawer };
