import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledBaseInputFieldConfiguration = styled.div`
  .esg-input-field-configuration {
    &__group {
      ${flexGap(theme.sizes.SIZE_48, 'column')};

      max-width: 70%;
      display: none;

      &.visible {
        display: unset;
      }

      .wcl-radio-button__label,
      .wcl-radio-group__radio-container__description {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};

        font-weight: 600;
        color: var(--BLACK-00);
      }

      .wcl-radio-group__radio-container__description {
        font-weight: 400;
        margin-top: unset;
      }
    }

    &__input-type {
      ${flex('column')};

      .esg-xbrl-selection-fields {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: ${theme.sizes.SIZE_20};

        margin-top: ${theme.sizes.SIZE_32};
      }
    }

    &__config {
      width: 100%;
    }
  }
`;

export { StyledBaseInputFieldConfiguration };
