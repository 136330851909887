import { Image } from '@faxi/web-component-library';
import { FC } from 'react';

import Icon from '../../Icon';
import { StyledUserImage } from './UserImage.styled';

type UserImageProps = {
  src?: string;
  name: string;
};

const UserImage: FC<UserImageProps> = ({ src, name }) => (
  <StyledUserImage className="esg-user-image">
    {src ? <Image src={src} alt={name} /> : <Icon name="user-solid" />}
  </StyledUserImage>
);

export default UserImage;
