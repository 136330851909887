import { RadioGroupOption } from '@faxi/web-component-library';
import {
  FormContext,
  FormField,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import classNames from 'classnames';
import { ChosenValue, RadioGroupField, XBRLSelectionFields } from 'components';
import { InputDataModule, InputFieldTypes, InputModuleConfig } from 'models';
import { FC, useContext, useMemo } from 'react';

import { StyledBaseInputFieldConfiguration } from './BaseInputFieldConfiguration.styled';
import DateInput from './components/DateInput';
import EmailInput from './components/EmailInput';
import NumericInput from './components/NumericInput';
import SimpleInput from './components/SimpleInput';

const INPUT_FIELD_TYPES = {
  [InputFieldTypes.SIMPLE_FIELD]: 'Free Text',
  [InputFieldTypes.NUMERIC_FIELD]: 'Numeric',
  [InputFieldTypes.EMAIL_FIELD]: 'Email',
  [InputFieldTypes.DATE_FIELD]: 'Date',
} as Record<InputFieldTypes, string>;

type InputTypeModules = Partial<{
  [K in InputFieldTypes]: FC<InputDataModule>;
}>;

export const inputTypeModules: InputTypeModules = {
  [InputFieldTypes.SIMPLE_FIELD]: SimpleInput,
  [InputFieldTypes.NUMERIC_FIELD]: NumericInput,
  [InputFieldTypes.EMAIL_FIELD]: EmailInput,
  [InputFieldTypes.DATE_FIELD]: DateInput,
};

const INPUT_FIELD_MODULE_TYPE = [
  {
    label: 'Free Text',
    description: 'Accepts any kind of input, and has no validation.',
    value: 'simple-field',
  },
  {
    label: 'Numeric',
    description:
      'Only accepts numeric value. Non numeric input will return an error.',
    value: 'numeric-field',
  },
  {
    label: 'Email',
    description:
      'Only accepts valid a email address. Otherwise will return an error.',
    value: 'email-field',
  },
  {
    label: 'Date',
    description:
      'Only accepts input in date format mm/dd/yyyy. Date can be selected using the calendar icon.',
    value: 'date-field',
  },
] as RadioGroupOption[];

export type BaseInputFieldConfigurationProps = InputDataModule;

const BaseInputFieldConfiguration: FC<BaseInputFieldConfigurationProps> = (
  props
) => {
  const { updateValueField } = useContext(FormContext);
  const values = useFormContextValues('type') as InputModuleConfig;

  const InputTypeComponent = useMemo(
    () =>
      inputTypeModules[values?.type as InputFieldTypes] as FC<InputDataModule>,
    [values]
  );

  return (
    <StyledBaseInputFieldConfiguration className="esg-input-field-configuration">
      <FormField
        name="type"
        className={classNames('esg-input-field-configuration__group', {
          visible: !values?.type,
        })}
        component={RadioGroupField}
        options={INPUT_FIELD_MODULE_TYPE}
        validate={validators.general.required('Input type is required')}
      />

      {values?.type && (
        <div className="esg-input-field-configuration__input-type">
          <ChosenValue
            label="Input type:"
            value={INPUT_FIELD_TYPES[values?.type]}
            onDiscard={() => updateValueField('type', '', true)}
          />

          <InputTypeComponent {...props} />

          <XBRLSelectionFields
            type="fields"
            selectedPackage={props.config?.xbrl_package}
          />
        </div>
      )}
    </StyledBaseInputFieldConfiguration>
  );
};

export default BaseInputFieldConfiguration;
