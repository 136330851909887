import { elevate_l, theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledXbrlTaxonomyCard = styled.div`
  ${elevate_l};

  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  padding: ${theme.sizes.SIZE_32};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_8};
  max-width: ${theme.sizes.SIZE_400};
  ${flex('row')};
  gap: ${theme.sizes.SIZE_20};

  .esg-xbrl-taxonomy-card {
    &__tag {
      font-size: ${theme.fontSizes.FONT_24};
    }
  }
`;

export { StyledXbrlTaxonomyCard };
