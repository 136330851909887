import classNames from 'classnames';
import { ApprovalProgressBar, ContributorsList } from 'components';
import { Contributor, Progress, TreeNodeElement } from 'models';
import { FC, memo } from 'react';

import { StyledCompanyTreeNode } from './CompanyTreeNode.styled';

export type OrganisationSessionCampaignItem = Omit<
  TreeNodeElement,
  'children' | 'parent' | 'created' | 'modified'
> & {
  contributors?: Contributor[];
  progress?: Progress;
  children?: OrganisationSessionCampaignItem[];
  parent?: OrganisationSessionCampaignItem | null;
};

const CompanyTreeNode: FC<OrganisationSessionCampaignItem> = ({
  children,
  element,
  contributors,
  progress,
  name,
}) => (
  <StyledCompanyTreeNode className="esg-company-tree-node">
    <div
      className={classNames('esg-company-tree-node__section', {
        'esg-company-tree-node__section--folder': children,
      })}
    >
      {element || name}
    </div>

    {contributors && (
      <ContributorsList
        className="esg-company-tree-node__contributors"
        contributors={contributors}
      />
    )}

    {progress && (
      <ApprovalProgressBar
        className="esg-company-tree-node__status"
        status={progress.progress === 100 ? 'Completed' : 'In Progress'}
        progress={progress}
      />
    )}
  </StyledCompanyTreeNode>
);

export default memo(CompanyTreeNode);
