import {
  Button,
  FormButtonsType,
  ModalRef,
  useFormButtons,
} from '@faxi/web-component-library';
import { FormContext } from '@faxi/web-form';
import { FC, MouseEvent, RefObject, useContext } from 'react';
import { FormActions } from 'styles';

import Icon, { INameExtended } from '../../Icon';

type FormFooterProps = {
  condition?: boolean;
  className?: string;
  modal?: RefObject<ModalRef>;
  deleteLabel?: string;
  submitIcon?: INameExtended;
  onDelete?: (event: MouseEvent<HTMLButtonElement>) => void;
  isModuleChanged?: boolean;
} & Partial<Pick<FormButtonsType, 'submitLabel' | 'cancelLabel'>>;

const FormFooter: FC<FormFooterProps> = (props) => {
  const {
    className,
    modal,
    condition = true,
    submitLabel = 'Save',
    cancelLabel = 'Cancel',
    deleteLabel = 'Delete',
    submitIcon,
    onDelete,
    isModuleChanged = true,
  } = props;

  const { disableSubmitButton } = useContext(FormContext);

  const [FormButtons] = useFormButtons({
    cancelLabel,
    submitLabel,
  });

  if (!condition) return null;

  return (
    <FormActions className={className}>
      {modal && (
        <FormButtons.Cancel
          variant="outline"
          onClick={() => {
            modal.current?.close();
          }}
        />
      )}

      <FormButtons.Submit
        iconPosition="left"
        disabled={disableSubmitButton && isModuleChanged}
        {...(submitIcon && { icon: <Icon name={submitIcon} /> })}
      />

      {onDelete && (
        <Button
          className="delete-btn"
          variant="delete-outline"
          onClick={onDelete}
        >
          {deleteLabel}
        </Button>
      )}
    </FormActions>
  );
};

export default FormFooter;
