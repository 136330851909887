import { Image, Table } from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { FC } from 'react';

import UserImage from '../../../../components/_atoms/UserImage';
import { DATE_FORMAT } from '../../../../constants';
import { Activity } from '../../../../models';
import StatusFlag from '../../../../routes/components/ProfileNotifications/components/StatusFlag';
import SessionHeader from '../SessionHeader';
import { sessionActivities } from './data';
import { StyledSessionActivity } from './SessionActivity.styled';

dayjs.extend(isToday);

const mapActivitiesToTableData = (activities: Activity[]) =>
  activities.map(
    (
      {
        isRead,
        user: { firstName, lastName, img },
        campaignName,
        organisation: { name: organisationName, img: organisationImg },
        action,
        topic,
      },
      index
    ) => ({
      id: index,
      user: (
        <div className="esg-session-activity__user">
          <StatusFlag initialIsRead={isRead} />

          <div className="esg-session-activity__user__image-name">
            <UserImage
              src={img}
              name={[firstName, lastName].join(' ').trim()}
            />
            <span>{[firstName, lastName].join(' ').trim()}</span>
          </div>
        </div>
      ),
      campaignName,
      organisation: (
        <div className="esg-session-activity__organisation">
          <Image
            src={organisationImg || '/assets/images/kinto-join.png'}
            alt=""
          />
          <span>{organisationName}</span>
        </div>
      ),
      topic,
      action,
    })
  );

const SessionActivity: FC = () => (
  <StyledSessionActivity className="esg-session-activity">
    <SessionHeader heading="Activity" />
    <div className="esg-session-activity__content">
      {sessionActivities?.map(({ activities, date }, index) => (
        <div className="esg-session-activity__content__on-date" key={index}>
          <div className="esg-session-activity__content__on-date__date">
            {dayjs(date).isToday() ? 'Today' : dayjs(date).format(DATE_FORMAT)}
          </div>

          <Table
            tableData={mapActivitiesToTableData(activities)}
            tableId="esg-notifications"
            disableSort
            excludeColumns={['id']}
            translationKeys={{
              id: 'ID',
              user: 'User',
              action: 'Action',
              campaignName: 'Campaign name',
              organisation: 'Organisation',
              topic: 'Topic',
            }}
          />
        </div>
      ))}
    </div>
  </StyledSessionActivity>
);

export default SessionActivity;
