import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledToggleLockFieldModal = styled(Modal)`
  .wcl-modal {
    width: ${theme.sizes.SIZE_664};

    &__header {
      &__titles {
        justify-content: center;
      }

      &__title {
        ${fontSize(theme.fontSizes.FONT_24, theme.fontSizes.FONT_34)};
      }
    }

    &__footer {
      gap: ${theme.sizes.SIZE_20};
      justify-content: space-between;
    }
  }

  .esg-toggle-lock-field-modal__buttons {
    ${flex('row')};
    gap: ${theme.sizes.SIZE_20};

    &-button {
      background: var(--RED-EB);

      &--lock {
        background: var(--GREEN-12);
      }

      &::after {
        border: unset;
      }
    }
  }
`;

export { StyledToggleLockFieldModal };
