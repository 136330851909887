import { SelectOption } from '@faxi/web-component-library';

export enum UnitCategory {
  Area = 'Area',
  Energy = 'Energy',
  Length = 'Length',
  Mass = 'Mass',
  Power = 'Power',
  Volume = 'Volume',
  Temperature = 'Temperature',
  Memory = 'Memory',
  ElectricCurrent = 'Electric Current',
  Force = 'Force',
  Voltage = 'Voltage',
  Frequency = 'Frequency',
  Duration = 'Duration',
  Pressure = 'Pressure',
  ElectricCharge = 'Electric Charge',
  PlaneAngle = 'Plane Angle',
  GHGEmissions = 'GHG Emissions',
}

export const UNIT_CATEGORY_OPTIONS: SelectOption[] = Object.values(
  UnitCategory
).map((value) => ({ value, label: value }));

export const UNIT_OPTIONS: Record<UnitCategory, SelectOption[]> = {
  [UnitCategory.Area]: [
    { value: 'a', label: 'a ( Acre )' },
    { value: 'ft2', label: 'ft² ( Square foot )' },
    { value: 'mi2', label: 'mi² ( Square miles )' },
    { value: 'yd2', label: 'yd² ( Square yard )' },
    { value: 'ha', label: 'ha ( Hectare )' },
    { value: 'km2', label: 'km² ( Square kilometer )' },
    { value: 'm2', label: 'm² ( Square metre )' },
  ],
  [UnitCategory.Energy]: [
    { value: 'Boe', label: 'Boe ( Barrel of oil equivalent )' },
    { value: 'BTU', label: 'BTU ( British thermal unit )' },
    { value: 'ft-lb', label: 'ft-lb ( Foot-pound Force )' },
    { value: 'MBoe', label: 'MBoe ( Thousand Barrels Oil Equivalent )' },
    { value: 'Mcfe', label: 'Mcfe ( Thousand Cubic Foot Equivalent )' },
    { value: 'MMBoe', label: 'MMBoe ( Millions of Barrels Oil Equivalent )' },
    { value: 'MMBTU', label: 'MMBTU ( Millions of BTU )' },
    { value: 'Cal', label: 'Cal ( Calorie )' },
    { value: 'Wh', label: 'Wh ( Watt-hours )' },
    { value: 'VAh', label: 'VAh ( Volt-ampere hours )' },
    { value: 'dth', label: 'dth ( Dekatherm )' },
    { value: 'MWd', label: 'MWd ( Megawatt-day )' },
    { value: 'MWh', label: 'MWh ( Megawatt-Hour )' },
    { value: 'GWh', label: 'GWh ( Gigawatt-Hour )' },
    { value: 'TWh', label: 'TWh ( Terawatt-Hour )' },
    { value: 'J', label: 'J ( Joule )' },
    { value: 'kJ', label: 'kJ ( Thousand Joules )' },
    { value: 'kWh', label: 'kWh ( Kilowatt-Hours of Energy )' },
    { value: 'mJ', label: 'mJ ( Millijoules )' },
    { value: 'MJ', label: 'MJ ( Megajoules )' },
    { value: 'GJ', label: 'GJ ( Gigajoules )' },
    { value: 'TJ', label: 'TJ ( Terajoules )' },
    { value: 'Bcfe', label: 'Bcfe ( Thousand Cubic Feet Gas Equivalent )' },
    { value: 'MMcfe', label: 'MMcfe ( Million Cubic Feet Gas Equivalent )' },
    { value: 'Tcfe', label: 'Tcfe ( Trillion Cubic Feet Gas Equivalent )' },
  ],
  [UnitCategory.Length]: [
    { value: 'ft', label: 'ft ( Feet )' },
    { value: 'in', label: 'in ( Inches )' },
    { value: 'mi', label: 'mi ( Miles )' },
    { value: 'nmi', label: 'nmi ( Nautical miles )' },
    { value: 'yd', label: 'yd ( Yards )' },
    { value: 'mm', label: 'mm ( Millimetres )' },
    { value: 'cm', label: 'cm ( Centimeters )' },
    { value: 'dm', label: 'dm ( Decimeters )' },
    { value: 'm', label: 'm ( Meters )' },
    { value: 'km', label: 'km ( Kilometers )' },
  ],
  [UnitCategory.Mass]: [
    { value: 'lb', label: 'lb ( Pounds )' },
    { value: 'oz', label: 'oz ( Ounces )' },
    { value: 'ozt', label: 'ozt ( Troy Ounces )' },
    { value: 'T', label: 'T ( US Tons )' },
    { value: 't', label: 't ( Metric Tonne, Metric Ton )' },
    { value: 'g', label: 'g ( Grams )' },
    { value: 'kg', label: 'kg ( Kilograms )' },
    { value: 'kT', label: 'kT ( Thousand of US Tons )' },
    { value: 'MT', label: 'MT ( Million of US Tons )' },
    { value: 'GT', label: 'GT ( Thousand million of US Tons )' },
    { value: 'kt', label: 'kt ( Thousand of metric Tons )' },
    { value: 'Mt', label: 'Mt ( Million of metric Tons )' },
    { value: 'Gt', label: 'Gt ( Thousand million of metric Tons )' },
  ],
  [UnitCategory.Power]: [
    { value: 'hp', label: 'hp ( Horsepower )' },
    { value: 'W', label: 'W ( Watt )' },
    { value: 'kW', label: 'kW ( Kilowatt )' },
    { value: 'GW', label: 'GW ( Gigawatt )' },
    { value: 'MW', label: 'MW ( Megawatt )' },
    { value: 'TW', label: 'TW ( Terawatt )' },
    { value: 'VA', label: 'VA ( Volt-ampere )' },
    { value: 'MVA', label: 'MVA ( Megavolt-ampere )' },
    { value: 'var', label: 'var ( Volt-ampere reactive )' },
    { value: 'Mvar', label: 'Mvar ( Million volt-ampere reactive )' },
  ],
  [UnitCategory.Volume]: [
    { value: 'bbl', label: 'bbl ( Barrel of oil )' },
    { value: 'ft3', label: 'ft3 ( Cubic foot )' },
    { value: 'gal', label: 'gal ( US Gallon )' },
    { value: 'MBbls', label: 'MBbls ( Thousands of Barrels of oil )' },
    { value: 'Mcf', label: 'Mcf ( Thousand of Cubic feet )' },
    { value: 'MMBbls', label: 'MMBbls ( Millions of Barrels of oil )' },
    { value: 'MMcf', label: 'MMcf ( Millions of Cubic feet )' },
    { value: 'Bcf', label: 'Bcf ( Billion of Cubic feet )' },
    { value: 'Tcf', label: 'Tcf ( Trillion of Cubic feet )' },
    { value: 'l', label: 'l ( Litre )' },
    { value: 'm3', label: 'm³ ( Cubic metre )' },
    { value: 'bu', label: 'bu ( US Bushel )' },
    { value: 'acre ft', label: 'acre ft ( Acre-foot )' },
  ],
  [UnitCategory.Temperature]: [
    { value: '°C', label: '°C ( Celsius )' },
    { value: 'K', label: 'K ( Kelvin )' },
    { value: '°F', label: '°F ( Fahrenheit )' },
  ],
  [UnitCategory.Memory]: [
    { value: 'B', label: 'B ( Bytes )' },
    { value: 'GB', label: 'GB ( Gigabytes )' },
    { value: 'kB', label: 'kB ( Kilobytes )' },
    { value: 'MB', label: 'MB ( Megabytes )' },
    { value: 'TB', label: 'TB ( Terabytes )' },
    { value: 'PB', label: 'PB ( Petabytes )' },
  ],
  [UnitCategory.ElectricCurrent]: [{ value: 'A', label: 'A ( Ampere )' }],
  [UnitCategory.Force]: [{ value: 'N', label: 'N ( Newton )' }],
  [UnitCategory.Voltage]: [
    { value: 'V', label: 'V ( Volt )' },
    { value: 'kV', label: 'kV ( Kilovolt )' },
    { value: 'MV', label: 'MV ( Megavolt )' },
    { value: 'GV', label: 'GV ( Gigavolt )' },
  ],
  [UnitCategory.Frequency]: [{ value: 'Hz', label: 'Hz ( Hertz )' }],
  [UnitCategory.Duration]: [
    { value: 'yr', label: 'yr ( Year )' },
    { value: 'qtr', label: 'qtr ( Quarter of year )' },
    { value: 'mo', label: 'mo ( Month )' },
    { value: 'wk', label: 'wk ( Week )' },
    { value: 'd', label: 'd ( Day )' },
    { value: 'h', label: 'h ( Hour )' },
    { value: 'm', label: 'm ( Minute )' },
    { value: 's', label: 's ( Second )' },
  ],
  [UnitCategory.Pressure]: [
    { value: 'Pa', label: 'Pa ( Pascal )' },
    { value: 'bar', label: 'bar ( Bar )' },
    { value: 'psi', label: 'psi ( Pounds per square inch )' },
    { value: 'atm', label: 'atm ( Atmosphere )' },
  ],
  [UnitCategory.ElectricCharge]: [
    { value: 'Ah', label: 'Ah ( Ampere hour )' },
    { value: 'C', label: 'C ( Coulomb )' },
  ],
  [UnitCategory.PlaneAngle]: [
    { value: '°', label: '° ( Degree )' },
    { value: 'rad', label: 'rad ( Radian )' },
  ],
  [UnitCategory.GHGEmissions]: [
    { value: 'tCO2e', label: 'tCO2e ( Tonnes of CO2 equivalent )' },
    { value: 'ktCO2e', label: 'ktCO2e ( Kilotonnes of CO2 equivalent )' },
    { value: 'MtCO2e', label: 'MtCO2e ( Megatonnes of CO2 equivalent )' },
    { value: 'GtCO2e', label: 'GtCO2e ( Gigatonnes of CO2 equivalent )' },
  ],
};

export const getUnitCategory = (unitValue: string): UnitCategory | undefined =>
  Object.entries(UNIT_OPTIONS).reduce<UnitCategory | undefined>(
    (foundCategory, [category, options]) =>
      options.some((option) => option.value === unitValue)
        ? (category as UnitCategory)
        : foundCategory,
    undefined
  );
