import {
  elevate_l,
  elevate_xxl,
  focusStyles,
  theme,
} from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledCanvasModule = styled.div<{ width?: number }>`
  ${elevate_l};
  ${flex('column', 'center', 'flex-start')};
  padding: ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_16};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_8};
  background-color: var(--BACKGROUND_1_1);
  cursor: grab;
  touch-action: none;
  ${focusStyles};
  min-height: ${theme.sizes.SIZE_72};
  ${(props) => (props.width ? `width: ${props.width}px` : '')};

  .wcl-dropdown__content {
    width: ${theme.sizes.SIZE_248};
    padding-left: ${theme.sizes.SIZE_12};
  }

  .esg-canvas-module {
    &__content {
      ${flex('row', 'flex-start', 'center')};
      flex-wrap: wrap;
      width: 100%;
      gap: ${theme.sizes.SIZE_20};

      &__grip {
        cursor: grab;
        padding: ${theme.sizes.SIZE_8} !important;
        width: ${theme.sizes.SIZE_32};

        svg {
          color: var(--GRAY-C1);
        }
      }

      &__type {
        width: ${theme.sizes.SIZE_46};
        background-color: var(--GRAY-EF);
        padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_12};
        border-radius: ${theme.sizes.SIZE_8};
      }

      &__text {
        color: var(--GRAY-98);
      }

      &__menu {
        margin-left: auto;
        .button {
          justify-content: center;
        }
      }
    }

    &__conditions {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_8};

      ${fontSize(theme.fontSizes.FONT_14)};

      border-radius: ${theme.sizes.SIZE_16};
      padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_16};
      background-color: var(--GRAY-EF);
      word-break: break-word;

      margin-left: 120px;
    }
  }

  &.esg-canvas-module {
    &--is-dragging {
      opacity: 0.2;
    }

    &--is-grabbing {
      cursor: grabbing;
    }

    &--section {
      border-left: ${theme.sizes.SIZE_4} solid var(--BLUE-00) !important;

      .esg-canvas-module__type {
        margin: ${theme.sizes.SIZE_10} 0 auto;
        display: none;
      }

      .esg-canvas-module__content__type,
      .esg-canvas-module__content__grip {
        margin: ${theme.sizes.SIZE_10} 0 auto;
      }
    }

    &:hover {
      ${elevate_xxl};
    }
  }
`;

export { StyledCanvasModule };
