import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledInfiniteScrollContainer = styled.div`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_32};
  margin-bottom: auto;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 0px;
  scroll-padding-bottom: 30px;
  padding: 3px;

  .esg-loading {
    align-self: center;
    margin-bottom: 5px;

    .spinner-rotator {
      ${size(theme.sizes.SIZE_16)};
    }

    .spinner-part {
      ${size(theme.sizes.SIZE_16, theme.sizes.SIZE_8)};
    }

    .spinner {
      ${size(theme.sizes.SIZE_16)};
    }
  }
  .esg-infinite-scroll-container {
  }
`;

export { StyledInfiniteScrollContainer };
