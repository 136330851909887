import env from 'env';

const apiVersion = env.VITE_API_VERSION;

export const SESSIONS = {
  FORM_APPROVAL: (id: string) => `/${apiVersion}/session-forms/${id}/approval`,
  FORM_STATUS: (id: string) => `/${apiVersion}/session-forms/${id}/status`,
  CONTRIBUTORS_LIST: (sessionId: string) =>
    `/sessions/v1/${sessionId}/assignments`,
};
