import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserActivityLogs = styled.div`
  ${flex('column')};
  height: 100%;

  .esg-user-activity-logs {
    &__user {
      ${flex('row')};
      gap: ${theme.sizes.SIZE_8};
    }
  }

  .esg-data-grid-preview__container {
    grid-template-columns: 224px 1fr 3fr 5fr;
    gap: ${theme.sizes.SIZE_32};
    width: 100%;
    overflow: auto;

    .esg-data-grid-preview__container__column {
      white-space: nowrap;
    }

    strong {
      font-weight: 600;
    }
  }
`;

export { StyledUserActivityLogs };
