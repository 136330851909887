import { SelectOption } from '@faxi/web-component-library';
import { useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../api/routes';
import { BasicTag } from '../components';
import { ApiData, Role } from '../models';
import { StyledCustomOption } from '../pages/Users/components/ManageUserModal/ManageUserModal.styled';
import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../pages/Users/constants';

// TODO: this hook is poorly made, imports cause circular dependencies when exported from the 'hooks/index' file...
const useRoleOptions = () => {
  const {
    data: { data: roles } = {},
    isLoading,
    isValidating,
  } = useSWR<ApiData<Role[]>, Error>(API_ROUTES.USERS.ROLES);

  const roleOptions = useMemo(
    () =>
      !roles
        ? []
        : (roles.map(({ id, name, color }) => ({
            value: id,
            label: (
              <StyledCustomOption>
                <span>{ROLE_VALUE_TO_DESCRIPTION_MAPPER[name]}</span>
                <BasicTag
                  text={ROLE_VALUE_TO_DESCRIPTION_MAPPER[name]}
                  color={color}
                />
              </StyledCustomOption>
            ),
          })) as SelectOption[]),
    [roles]
  );

  return { roleOptions, isLoading, isValidating, roles };
};

export default useRoleOptions;
