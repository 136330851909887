import { SelectOption } from '@faxi/web-component-library';
import { Dayjs } from 'dayjs';
import { Contributor, SectionDataModule } from 'models';
import { FC, useMemo, useState } from 'react';

import { useContributors } from '../../../../../../hooks';
import AssignContributors from '../../../../../../routes/subrouters/Sessions/components/AssignContributors';
import DeadlineCalendar from '../../../../../../routes/subrouters/Sessions/components/DeadlineCalendar';
import { ContributorsList } from '../../../../../_molecules';
import { StyledSectionPreview } from './SectionPreview.styled';

export type SectionPreviewProps = SectionDataModule;

// TODO: this needs clean-up, rush for demo
const SectionPreview: FC<SectionPreviewProps> = ({
  moduleElement: ModuleElement,
  elements,
  disabled,
}) => {
  //TODO add request or recieve through props when api is ready
  const [deadline, setDeadline] = useState<Dayjs>();

  const { contributors } = useContributors();

  const [contributorsLocal, setContributorsLocal] = useState<Contributor[]>([]);

  const initialData = useMemo<{ contributors: SelectOption[] }>(
    () => ({
      contributors: contributorsLocal.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    }),
    [contributorsLocal]
  );

  if (!elements?.length || !ModuleElement) return <></>;

  return (
    <StyledSectionPreview className="esg-section-preview">
      <div className="esg-section-preview__header">
        <div className="esg-section-preview__header__contributors">
          <AssignContributors
            // TODO
            initialData={initialData}
            contributors={contributorsLocal}
            onSubmit={async (data) => {
              setContributorsLocal(
                contributors.filter(({ id }) =>
                  data.contributors.some(
                    (selectedName) => selectedName.value === id
                  )
                )
              );
            }}
            btnText="Assign to section"
            render={(contributors) => (
              <ContributorsList contributors={contributors} />
            )}
          />
        </div>
        <DeadlineCalendar
          onChange={(date) => {
            setDeadline(date ?? undefined);
          }}
          triggerText="Set deadline"
          value={deadline}
        />
      </div>

      {elements.map((module, index) => (
        <ModuleElement
          modulesType="preview-module"
          type={module.type}
          module={module}
          className="esg-section-preview__module"
          key={index}
          isInlineEditableDisabled={disabled}
        />
      ))}
    </StyledSectionPreview>
  );
};

export default SectionPreview;
