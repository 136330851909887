import { Button, Tabs, useModalUtilities } from '@faxi/web-component-library';
import classNames from 'classnames';
import FoldersPreview from 'components/_molecules/FoldersPreview';
import Icon from 'components/Icon';
import {
  LayoutPreview,
  Organisation as OrganisationType,
  PermissionSections,
  TreeNodeElement,
} from 'models';
import { FC, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_URI } from '../../../config';
import { useLayoutPreviewMode, useUserPermissions } from '../../../hooks';
import { previewTabs } from '../../../hooks/useLayoutPreviewMode';
import { useOrganisationProvider } from '../../../providers/Organisation';
import CompanyModal from '../../../routes/subrouters/Organisations/modals/CompanyModal';
import { ViewGuard } from '../../_atoms';
import { StyledOrganisationManagement } from './OrganisationManagement.styled';

export type OrganisationManagementProps = PropsWithChildren<{
  className?: string;
}>;

const OrganisationManagement: FC<OrganisationManagementProps> = ({
  className,
}) => {
  const navigate = useNavigate();

  const hasPermission = useUserPermissions(PermissionSections.ORGANISATION);

  const { activeOrganisation, deleteOrganisation } = useOrganisationProvider();

  const [activeCompany, setActiveCompany] = useState<TreeNodeElement>();

  const { open, triggerRef, openModal, closeModal } = useModalUtilities();

  const buttonTrigger = triggerRef.current as HTMLButtonElement;

  const { view, setView } = useLayoutPreviewMode('grid');

  return (
    <StyledOrganisationManagement
      className={classNames(className, 'esg-organisation-management')}
    >
      <div className="esg-organisation-management__buttons">
        <ViewGuard
          section={PermissionSections.ORGANISATION}
          permissions={['create']}
        >
          <Button
            className="esg-organisation-management__buttons__create-btn"
            variant="outline"
            iconPosition="left"
            icon={<Icon name="plus" />}
            onClick={openModal}
          >
            Add organisation
          </Button>
        </ViewGuard>
        {activeOrganisation?.children.length! > 1 && (
          <Tabs<LayoutPreview>
            value={view}
            className="esg-organisation-management__buttons__tabs"
            tabs={previewTabs}
            onChange={setView}
            changeOrientationOnResponsive={false}
          />
        )}
      </div>
      <FoldersPreview
        folder={activeOrganisation}
        onSelect={(f) => navigate(`${APP_URI.ORGANISATIONS}/${f.id}`)}
        onEdit={(c) => {
          setActiveCompany(c);
          openModal();
        }}
        onDelete={(org, e) =>
          deleteOrganisation(
            org as OrganisationType,
            e.target as HTMLButtonElement
          )
        }
        view={view}
        withMenu={hasPermission(['update', 'delete'])}
        hasActions={hasPermission(['update'])}
      />
      {open && (
        <CompanyModal<OrganisationType>
          renderAsPortal
          initialData={activeCompany}
          conditionallyControlled
          triggerRef={buttonTrigger}
          onClose={() => {
            closeModal();
            setActiveCompany(undefined);
          }}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
            CAMPAIGN: false,
            TAXONOMY_ID: false,
          }}
        />
      )}
    </StyledOrganisationManagement>
  );
};

export default OrganisationManagement;
