import { SelectOption } from '@faxi/web-component-library';
import { useMemo } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { Campaign } from '../../../models';

const useCampaignOptions = () => {
  const {
    data: { data: campaigns } = { data: [] },
    isLoading: loadingCampaigns,
  } = useSWR<{ data: Campaign[] }>(API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE(true));

  const campaignsOptions: SelectOption<string>[] = useMemo(
    () =>
      campaigns?.map(({ id, name }) => ({
        value: id,
        label: name,
      })) ?? [],
    [campaigns]
  );

  return { campaignsOptions, loadingCampaigns };
};

export default useCampaignOptions;
