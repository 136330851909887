import { theme } from '@faxi/web-component-library';
import { css } from 'styled-components';

const buttonStyles = css`
  button[class*='disabled'] {
    opacity: 0.7;

    &:hover {
      background-color: unset;
    }
  }

  .button--ghost {
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
    min-height: unset;
  }

  .button--delete-ghost {
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
    min-height: unset;

    &:hover {
      background-color: var(--RED-FC);
    }
  }

  .button--outline-invert[class] {
    color: var(--BLACK-00);
  }
`;

export default buttonStyles;
