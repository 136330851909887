import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import Icon from '../../../../components/Icon';
import { StyledXbrlTaxonomyCard } from './XBRLTaxonomyCard.styled';

export type XbrlTaxonomyCardProps = PropsWithChildren<{ className?: string }>;

const XBRLTaxonomyCard: FC<XbrlTaxonomyCardProps> = ({
  children,
  className,
}) => {
  return (
    <StyledXbrlTaxonomyCard
      className={classNames(
        'esg-xbrl-taxonomy-card',
        'hover-effect',
        className
      )}
    >
      <Icon name="tag" className="esg-xbrl-taxonomy-card__tag" />
      {children}
    </StyledXbrlTaxonomyCard>
  );
};

export default XBRLTaxonomyCard;
