import { Button, useUtilities } from '@faxi/web-component-library';
import { FC, MouseEventHandler, PropsWithChildren, useCallback } from 'react';

import Icon from '../../../../Icon';
import { StyledXBRLTagChip } from './XBRLTagChip.styled';

export type XBRLTagChipProps = PropsWithChildren<{
  value: string;
  parent: string;
  onRemove: () => void;
}>;

const XBRLTagChip: FC<XBRLTagChipProps> = ({ value, onRemove, parent }) => {
  const { prompts } = useUtilities();

  const handleOnRemove: MouseEventHandler<HTMLButtonElement> = useCallback(
    async (e) => {
      await prompts.delete({
        type: 'delete',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Do not delete',
        title: `Delete ${value}`,
        content: `Are you sure you want to delete ${value} from ${parent}?`,
        buttonIcon: 'trash-can',
        iconPosition: 'left',
        confirmButtonVariant: 'delete-ghost',
        titleIcon: <Icon name="triangle-exclamation" />,
        triggerRef: e.target as HTMLButtonElement,
        onConfirm: async () => onRemove(),
      });
    },
    [onRemove, parent, prompts, value]
  );

  return (
    <StyledXBRLTagChip className="esg-xbrl-tag-chip">
      <Icon name="tag-solid" />
      <div className="esg-xbrl-tag-chip__value">{value}</div>
      <Button
        className="esg-xbrl-tag-chip__button"
        variant="ghost"
        onClick={handleOnRemove}
      >
        <Icon name="xmark" />
      </Button>
    </StyledXBRLTagChip>
  );
};

export default XBRLTagChip;
