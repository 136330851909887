import { theme } from '@faxi/web-component-library';
import { flex, flexGap, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledDateInput = styled.div`
  ${flex('column')};
  ${flexGap(theme.sizes.SIZE_24, 'column')};

  margin-top: ${theme.sizes.SIZE_32};

  .esg-date-input {
    &__dates {
      display: grid;
      gap: ${theme.sizes.SIZE_20};
      grid-template-columns: 1fr 3fr;
      align-items: center;

      &__title {
        ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_20)};
        font-weight: 400;
        color: var(--GRAY-54);
      }

      &__date-range {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_8};

        .wcl-dropdown {
          width: 100%;
        }
      }
    }
  }
`;

export { StyledDateInput };
