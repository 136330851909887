import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledWarningFallback = styled.div`
  ${flex('column', 'center', 'center')};
  ${size('100%')};

  h1 {
    ${flex('row')};
    gap: ${theme.sizes.SIZE_10};
  }

  p {
    margin-bottom: ${theme.sizes.SIZE_32};
  }
`;

export { StyledWarningFallback };
