import { CampaignSessionTreeNode, Progress } from 'models';

export const findCurrentSessionEntry = (
  root: CampaignSessionTreeNode,
  currentId: string
): CampaignSessionTreeNode | undefined => {
  if (currentId === root?.id) {
    return root;
  }
  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findCurrentSessionEntry(child as any, currentId);
    if (result) {
      return result;
    }
  }
};

export const findAllAncestorNames = (
  root: CampaignSessionTreeNode,
  currentId: string
): string[] | undefined => {
  if (currentId === root?.id) {
    return [root.name];
  }

  if (!root?.children || root?.children?.length === 0) {
    return undefined;
  }

  for (const child of root.children) {
    const result = findAllAncestorNames(child as any, currentId);
    if (result !== undefined) {
      return [root.name, ...result];
    }
  }
};

// TODO: remove when API done
export const mockProgress = (): Progress => ({
  progress: Math.random() * 100,
  total: 100,
  completed: Math.random() * 100,
});

export const mockedApprovals = Array.from(Array.of(100)).map(() =>
  mockProgress()
);
