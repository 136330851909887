import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

// TODO: clean this up
const StyledSessionContributors = styled.div`
  ${flex('column', 'center', 'initial')};
  padding: ${theme.sizes.SIZE_24};

  .esg-session-contributors {
    &__header {
      ${flex('column', 'center', 'flex-start')};
      gap: ${theme.sizes.SIZE_32};

      padding: ${theme.sizes.SIZE_24};
      width: 100%;
      span {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_10};
        ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_14)};
      }
    }

    &__content {
      width: 100%;
      padding: ${theme.sizes.SIZE_24};

      .esg-users-preview__container {
        grid-template-columns: repeat(
          auto-fill,
          minmax(${theme.sizes.SIZE_200}, 1fr)
        );
      }
      .esg-user-card {
        padding: ${theme.sizes.SIZE_20};
        gap: ${theme.sizes.SIZE_10};
        &--grid {
          width: ${theme.sizes.SIZE_200};
          .user-image {
            ${size(theme.sizes.SIZE_80)};
            .user-image-container {
              ${size(theme.sizes.SIZE_80)};
              .wcl-icon {
                ${fontSize(theme.sizes.SIZE_64)};
              }
              img {
                width: 100%;
                height: 100%;
              }
            }
            .esg-basic-tag {
              ${fontSize(theme.fontSizes.FONT_12)};
              padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_4};
            }
          }
        }

        &__menu {
          top: ${theme.sizes.SIZE_8};
          right: ${theme.sizes.SIZE_8};
        }

        &__name {
          ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_20)};
          text-align: center;
          max-width: ${theme.sizes.SIZE_152};
        }
        &__description {
          span {
            max-width: ${theme.sizes.SIZE_104};
          }
          ${fontSize(theme.fontSizes.FONT_12)}
        }
      }
    }
  }
`;

export { StyledSessionContributors };
