import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionDocuments = styled.div`
  height: 100%;
  padding: ${theme.sizes.SIZE_24};

  .esg-session-document-name {
    text-decoration: underline;
    cursor: pointer;
  }

  .wcl-table {
    &-container {
      margin-top: ${theme.sizes.SIZE_24};
    }

    &__data {
      &__title {
        .esg-session-document-name {
          text-decoration: underline;
          cursor: pointer;
        }

        .esg-session-document-user,
        .esg-session-document-name {
          ${flex('row', 'center', 'center')};
          gap: ${theme.sizes.SIZE_10};
          font-size: ${theme.fontSizes.FONT_14};
        }
      }
    }
  }
`;
export { StyledSessionDocuments };
