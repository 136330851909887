import env from '../../env';

const apiVersion = env.VITE_API_VERSION;

export const COMMENTS = {
  SESSION_FORM_ELEMENT_COMMENTS: (
    sessionId: string,
    campaignItemId: string,
    elementId: string,
    limit?: number
  ) =>
    `/campaign-sessions/${apiVersion}/organisation-sessions/${sessionId}/forms/${campaignItemId}/elements/${elementId}/comments${limit ? '?limit=' + limit : ''}`,
  SESSION_FORM_ELEMENT_COMMENTS_READ: (
    sessionId: string,
    campaignItemId: string,
    elementId: string
  ) =>
    `/campaign-sessions/${apiVersion}/organisation-sessions/${sessionId}/forms/${campaignItemId}/elements/${elementId}/comments/read`,
};
