import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionActivity = styled.div`
  ${flex('column', 'center', 'initial')};
  padding: ${theme.sizes.SIZE_24};

  .esg-session-activity {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_10};
      padding: ${theme.sizes.SIZE_24};
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_14)};

      .wcl-icon {
        ${fontSize(theme.fontSizes.FONT_30)};
      }
    }

    &__content {
      padding: ${theme.sizes.SIZE_24};

      &__on-date {
        &__date {
          ${fontSize(theme.fontSizes.FONT_16, theme.fontSizes.FONT_20)};
          color: var(--GRAY-70);
          margin-bottom: ${theme.sizes.SIZE_24};
          margin-top: ${theme.sizes.SIZE_40};
        }
      }
    }

    &__user {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_32};

      &__image-name {
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_12};
      }
    }

    &__organisation {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_12};

      img {
        ${size(theme.sizes.SIZE_32)};
      }
    }
  }
`;

export { StyledSessionActivity };
