import { theme } from '@faxi/web-component-library';
import { size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserImage = styled.div`
  ${size(theme.sizes.SIZE_32)};
  min-width: ${theme.sizes.SIZE_32};
  font-size: ${theme.fontSizes.FONT_24};
  border-radius: 50%;
  background: linear-gradient(var(--GRAY-EF), var(--GRAY-D9));
  color: var(--GRAY-8F-B3);
  position: relative;
  overflow: hidden;

  .wcl-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  img {
    ${size('100%')};
  }
`;
export { StyledUserImage };
