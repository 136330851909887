import {
  applyRef,
  ModalProps,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef } from '@faxi/web-form';
import { FileUploadField, FormFooter } from 'components';
import { useValidations } from 'hooks';
import {
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  RefObject,
  useCallback,
  useRef,
} from 'react';
import useSWRMutation from 'swr/mutation';

import { presignedUploadFetcher } from '../../../../api';
import useMutation from '../../../../api/hooks/useMutation';
import { CAMPAIGN_ITEMS_ROUTES } from '../../../../api/routes/CampaignItemsRoutes';
import { handleUploadFieldErrors } from '../../../../components/_fields/FileUploadField/FileUploadField.component';
import { XBRL_ACCEPTED_FILE_TYPES } from '../../../../constants';
import { PresignedUrl, XBRLFile } from '../../../../models';
import { StyleXBRLUploadModal } from './XBRLUploadModal.styled';

export type XBRLUploadModalProps = PropsWithChildren<
  ModalProps & {
    onSubmit: () => void;
  }
>;

const MAX_FILES = 3;

const XBRLUploadModal: ForwardRefRenderFunction<
  ModalRef,
  XBRLUploadModalProps
> = (props, ref) => {
  const { onSubmit, ...rest } = props;

  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const modalRef = useRef<ModalRef>();

  const [formRef, setFormRef] = useCallbackRef<FormRef>();

  const { validations } = useValidations();

  const { trigger: uploadDocuments } = useMutation<{
    data: PresignedUrl & { taxonomyId: string };
  }>(CAMPAIGN_ITEMS_ROUTES.CREATE_UPLOAD_URL);

  const { trigger: presignedUpload } = useSWRMutation(
    'presignedUpload',
    presignedUploadFetcher
  );

  const { trigger: confirmUpload } = useMutation<{
    data: XBRLFile;
  }>(`/campaign-items/v1/taxonomies//confirm-upload`, {
    onSuccess: (data) => {
      showSnackBar({
        text: `${data.data.name} successfully uploaded.`,
        variant: 'success',
      });
      modalRef.current?.close();
    },
  });

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        ref={setFormRef}
        children={children}
        className={className}
        initialData={undefined}
        onSubmit={async (data: { xbrlTaxonomies: File[] }) => {
          showOverlay('body');

          try {
            const file = data.xbrlTaxonomies[0];

            const { name: fileName, type: fileType } = file;

            const {
              data: { presignedUrl, taxonomyId },
            } = await uploadDocuments({
              method: 'POST',
              data: {
                file: {
                  fileName,
                  fileType,
                },
              },
            });

            await presignedUpload({
              presignedUrl,
              file,
              fileType,
            });

            await confirmUpload({
              url: `/campaign-items/v1/taxonomies/${taxonomyId}/confirm-upload`,
              method: 'POST',
            });

            onSubmit?.();
          } catch (e) {
            console.error(e);
          } finally {
            hideOverlay('body');
          }
        }}
      />
    ),
    [
      confirmUpload,
      hideOverlay,
      onSubmit,
      presignedUpload,
      setFormRef,
      showOverlay,
      uploadDocuments,
    ]
  );

  return (
    <StyleXBRLUploadModal
      className="esg-manage-user-modal"
      ref={(el: ModalRef) => {
        applyRef(ref, el);
        modalRef.current = el;
      }}
      childrenWrapper={formWrapper}
      title="Upload a new XBRL taxonomy"
      footer={
        <FormFooter
          modal={modalRef as RefObject<ModalRef>}
          submitLabel="Submit"
        />
      }
      {...rest}
    >
      <FormField
        component={FileUploadField}
        name="xbrlTaxonomies"
        acceptedFileTypesText="Accepted file types: "
        maxFileSize={100}
        multiple={true}
        maxFiles={MAX_FILES}
        dropZoneText="Drag & drop taxonomy files here or "
        clickToUploadText="click to upload"
        uploadingText="Uploading"
        filesCountError={`Maximum number of files exceeded. Max allowed is ${MAX_FILES}.`}
        fileTypeError="File type not allowed"
        fileSizeError="File size too large"
        alreadyExistError="File with this name already exists"
        acceptedFileTypes={XBRL_ACCEPTED_FILE_TYPES}
        validate={validations.xbrlTaxonomies}
        handleErrors={handleUploadFieldErrors(formRef, 'xbrlTaxonomies')}
      />
    </StyleXBRLUploadModal>
  );
};

export default forwardRef(XBRLUploadModal);
