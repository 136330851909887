import {
  Button,
  Modal,
  ModalRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, RefObject, useCallback, useRef } from 'react';

import { XBRLSelectionFields } from '../../../../_fields';
import { FormFooter } from '../../../../_forms';
import Icon from '../../../../Icon';
import { StyledAddXBRLTagModal } from './AddXBRLTagModal.styled';

export type AddXBRLTagModalProps = PropsWithChildren<{
  className?: string;
  onChange: (value?: string) => void;
}>;

const AddXBRLTagModal: FC<AddXBRLTagModalProps> = ({ className, onChange }) => {
  const modalRef = useRef<ModalRef>(null);

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const formWrapper = useCallback(
    ({ children, className }: PropsWithChildren<{ className: string }>) => (
      <Form
        children={children}
        className={className}
        onSubmit={async ({ xbrl_package_tag }) => {
          showOverlay('body');
          // TODO: remove when BE is ready
          setTimeout(() => {
            onChange(xbrl_package_tag);
            showSnackBar({
              text: `You have successfully added ${xbrl_package_tag} tag.`,
              variant: 'success',
              actionButtonText: 'Dismiss',
            });
            hideOverlay('body');
          }, 1500);
        }}
      />
    ),
    [onChange, showSnackBar, showOverlay, hideOverlay]
  );

  return (
    <StyledAddXBRLTagModal
      className={classNames(className, 'esg-add-xbrl-tag-modal')}
    >
      <Button
        variant="outline"
        onClick={() => modalRef.current?.open()}
        icon={<Icon name="plus" />}
        iconPosition="left"
      >
        Add XBRL Tag
      </Button>

      <Modal
        ref={modalRef}
        conditionallyControlled={false}
        onClose={() => modalRef.current?.close()}
        childrenWrapper={formWrapper}
        title="Add XBRL Tag"
        footer={<FormFooter modal={modalRef as RefObject<ModalRef>} />}
      >
        <XBRLSelectionFields
          type="fieldGroup"
          showLabel={false}
          direction="column"
        />
      </Modal>
    </StyledAddXBRLTagModal>
  );
};

export default AddXBRLTagModal;
