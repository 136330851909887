import {
  Button,
  Calendar as WclCalendar,
  CalendarProps as WclCalendarProps,
  CalendarValue,
  config,
  DropdownProps,
  DropdownRef,
  useCallbackRef,
} from '@faxi/web-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useCallback, useMemo } from 'react';

import { PortalContainer } from '../../../../../components/_fields/CalendarField/CalendarField.styled';
import { CALENDAR_LABELS } from '../../../../../components/_fields/CalendarField/utils';
import Icon from '../../../../../components/Icon';
import { DATE_FORMAT } from '../../../../../constants';
import DueReminderFlag from '../../../../components/ProfileReminders/components';
import { StyledDeadlineCalendar } from './DeadlineCalendar.styled';

type DeadlineCalendarProps = {
  leftLimitDate?: Dayjs;
  rightLimitDate?: Dayjs;
  renderAsPortal?: boolean;
  dateFormat?: string;
  onSetDate?: (date: Dayjs | null) => void;
  onChange?: (date: Dayjs | null) => void;
  openAtToday?: boolean;
  disabled?: boolean;
  triggerText: string;
} & Pick<
  WclCalendarProps,
  'disableFuture' | 'disablePast' | 'mode' | 'value' | 'disabledDates'
> &
  Pick<DropdownProps, 'openPosition'>;

const DeadlineCalendar: FC<DeadlineCalendarProps> = (props) => {
  const {
    dateFormat = DATE_FORMAT,
    renderAsPortal = true,
    disablePast = true,
    openAtToday = true,
    leftLimitDate,
    rightLimitDate,
    value: pValue,
    disableFuture,
    disabledDates,
    openPosition,
    onSetDate,
    onChange,
    disabled,
    triggerText,
  } = props;

  const [dropdown, dropdownRef] = useCallbackRef<DropdownRef>();

  const value = useMemo(
    () =>
      pValue
        ? dayjs(pValue as Dayjs, config.dateFormat).format(dateFormat)
        : undefined,
    [dateFormat, pValue]
  );

  const closeDropdown = useCallback(() => {
    dropdown?.trigger.focus();
    dropdown?.setOpen(false);
  }, [dropdown]);

  const handleOnChange = useCallback(
    (date?: CalendarValue) => {
      if (!date) return;

      onSetDate?.(date as Dayjs);
      onChange?.(date as Dayjs);
      dropdown?.setOpen(false);
    },
    [onSetDate, onChange, dropdown]
  );

  return (
    <StyledDeadlineCalendar
      ref={dropdownRef}
      className="esg-deadline-calendar"
      hasCloudArrow={false}
      openPosition={openPosition}
      renderAsPortal={renderAsPortal}
      portalStyle={PortalContainer}
      disabled={disabled}
      trigger={
        !value ? (
          <Button icon={<Icon name="calendar-days" />} variant="outline">
            {triggerText}
          </Button>
        ) : (
          <div className="calendar-trigger-with-value">
            <div>
              <Icon name="calendar-days" />
              <div>{value}</div>
            </div>
            <DueReminderFlag dueDate={dayjs(pValue as Dayjs).toDate()} />
          </div>
        )
      }
      body={
        <WclCalendar
          keepFocus={dropdown?.open}
          labels={CALENDAR_LABELS}
          dateFormat={dateFormat}
          disablePast={disablePast}
          disableFuture={disableFuture}
          leftLimitDate={leftLimitDate}
          disabledDates={disabledDates}
          rightLimitDate={rightLimitDate}
          {...(value && { value: dayjs(value, dateFormat) })}
          onClose={closeDropdown}
          onChange={handleOnChange}
          openAtToday={openAtToday}
        />
      }
    />
  );
};

export default DeadlineCalendar;
