import { ButtonProps } from '@faxi/web-component-library';
import { EmptyFolder } from 'components';
import UserCard from 'components/_molecules/UserCard';
import Icon from 'components/Icon';
import { LayoutPreview, User } from 'models';
import { FC, MouseEvent, PropsWithChildren } from 'react';

import { ROLE_VALUE_TO_DESCRIPTION_MAPPER } from '../constants';
import { StyledUsersPreview } from './UsersPreview.styled';

export type UsersPreviewProps = PropsWithChildren<{
  users?: User[];
  view?: LayoutPreview;
  onClick?: (user: User) => void;
  onEdit?: (user: User, event: MouseEvent) => void;
  onDelete?: (user: User, event: MouseEvent) => void;
  loadingUserId?: string;
  menuItems?: ButtonProps[];
  includeDefaultMenuItems?: boolean;
}>;

const UsersPreview: FC<UsersPreviewProps> = (props) => {
  const {
    users,
    onClick,
    onEdit,
    onDelete,
    view = 'list',
    includeDefaultMenuItems = true,
    menuItems,
    loadingUserId,
  } = props;

  if (!users?.length) return <EmptyFolder />;

  return (
    <StyledUsersPreview className="esg-users-preview">
      <div className="esg-users-preview__container" data-view={view}>
        {users.map((user) => (
          <UserCard
            id={user.id}
            key={user.id}
            title={`${user.firstName ?? ''} ${user.lastName ?? ''}`.trim()}
            titleTag={{
              text: ROLE_VALUE_TO_DESCRIPTION_MAPPER[user.roles[0]?.name],
              color: user.roles[0]?.color,
            }}
            image={user.img}
            user={user.firstName}
            description={user.email}
            prefixElement={<Icon name="user" />}
            hasActions={false}
            withMenu
            menuItems={menuItems}
            includeDefaultMenuItems={includeDefaultMenuItems}
            view={view}
            onClick={() => onClick?.(user)}
            onEdit={(e) => onEdit?.(user, e)}
            onDelete={(e) => onDelete?.(user, e)}
            onAddDescriptionCallback={(e) => onEdit?.(user, e)}
            loading={loadingUserId === user.id}
          />
        ))}
      </div>
    </StyledUsersPreview>
  );
};

export default UsersPreview;
