import { FC, PropsWithChildren } from 'react';

import { StyledNotificationBadgeWrapper } from './NotificationBadgeWrapper.styled';

export type NotificationBadgeWrapperProps = PropsWithChildren<{
  count: number;
}>;

/*
The NotificationBadgeWrapper component is designed to wrap an icon or other child elements to visually indicate unread notifications.
If the hasNotification prop is set to true, it displays a notification badge, optionally showing a count of unread notifications when the count prop is provided.
If hasNotification is false, the child elements are rendered without modification.
*/

const NotificationBadgeWrapper: FC<NotificationBadgeWrapperProps> = ({
  children,
  count,
}) =>
  count > 0 ? (
    <StyledNotificationBadgeWrapper
      $hasCount={Boolean(count)}
      data-count={count}
      className="esg-notification-badge-wrapper"
    >
      {children}
    </StyledNotificationBadgeWrapper>
  ) : (
    children
  );

export default NotificationBadgeWrapper;
