import { Heading } from '@faxi/web-component-library';
import { FC } from 'react';

import Icon, { INameExtended } from '../../../../components/Icon';
import * as Styled from './SessionHeader.styled';

export type SessionActivityProps = { heading: string; icon?: INameExtended };

const SessionHeader: FC<SessionActivityProps> = (props) => {
  const { heading, icon = 'wave-pulse' } = props;

  return (
    <Styled.StyledSessionHeader className="esg-session-header">
      <Icon name={icon} />
      <Heading level="1">{heading}</Heading>
    </Styled.StyledSessionHeader>
  );
};

export default SessionHeader;
