import { theme } from '@faxi/web-component-library';
import { laptop } from '@faxi/web-css-utilities';
import { BaseLayout } from 'layouts';
import styled, { css } from 'styled-components';

const StyledSessionDashboard = styled(BaseLayout)`
  .esg-base-layout {
    &__content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.sizes.SIZE_20};

      ${laptop(css`
        grid-template-columns: 1fr;
      `)}
    }
  }
`;

export { StyledSessionDashboard };
