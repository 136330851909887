import { FC, PropsWithChildren, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { XBRL_TAXONOMIES } from '../../mock/xbrl';
import XBRLContext, { XBRLContextProps } from './XBRL.context';

type XBRLProviderProps = PropsWithChildren;

const XBRLProvider: FC<XBRLProviderProps> = () => {
  return (
    <XBRLContext.Provider value={{ taxonomies: XBRL_TAXONOMIES }}>
      <Outlet />
    </XBRLContext.Provider>
  );
};

const useXBRLProvider = (): XBRLContextProps => useContext(XBRLContext);

export { useXBRLProvider, XBRLProvider };
