import { useModalUtilities } from '@faxi/web-component-library';
import { Organisation, PermissionSections } from 'models';
import { FC, useMemo } from 'react';

import { BlockUI } from '../../../../../helpers';
import {
  useNavigateUpOnDelete,
  useUserPermissions,
} from '../../../../../hooks';
import { useOrganisationProvider } from '../../../../../providers/Organisation';
import CompanyModal from '../../modals/CompanyModal';
import { generateOrganisationItemCrumbs } from '../../utils';
import { StyledOrganisationsWrapper } from './OrganisationsWrapper.styled';

const OrganisationsWrapper: FC = () => {
  const {
    organisationTree,
    activeOrganisation,
    loading,
    error,
    deleteOrganisation,
  } = useOrganisationProvider();

  const { open, openModal, closeModal } = useModalUtilities();

  const hasPermission = useUserPermissions(PermissionSections.ORGANISATION);

  const { id, name, path } = activeOrganisation ?? ({} as Organisation);

  const crumbs = useMemo(
    () => generateOrganisationItemCrumbs(path?.names, path?.ids, id, name),
    [id, name, path]
  );

  const { handleNavigate } = useNavigateUpOnDelete();

  return (
    <BlockUI error={error}>
      <StyledOrganisationsWrapper
        data={organisationTree}
        crumbs={crumbs}
        loadingTree={loading}
        pageTitle="Organisations"
        treeViewTitle="Organisation browser"
        activeNodeId={activeOrganisation?.id}
        treeNodeTitle={activeOrganisation?.name}
        description={activeOrganisation?.description}
        onChange={() => openModal()}
        onDelete={(org) =>
          deleteOrganisation(org as Organisation, undefined, () =>
            handleNavigate(org.id)
          )
        }
        withMenu={hasPermission(['delete'])}
        hasActions={hasPermission(['update'])}
      />

      {open && (
        <CompanyModal
          conditionallyControlled
          initialData={activeOrganisation}
          onClose={closeModal}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
            CAMPAIGN: false,
            TAXONOMY_ID: false,
          }}
        />
      )}
    </BlockUI>
  );
};

export default OrganisationsWrapper;
