import { Progress } from '../../../../../../../../models';

export type Reminder = {
  id: string;
  isRead: boolean;
  title: string;
  from: string;
  progress: Progress;
  dueDate: Date;
};

const USER_REMINDERS_MOCK: Reminder[] = [
  {
    id: '1',
    isRead: false,
    title: 'Assignment',
    from: 'Name 1',
    progress: {
      progress: 85,
      total: 100,
      completed: 85,
    },
    dueDate: new Date('2025-02-02'),
  },
  {
    id: '2',
    isRead: true,
    title: 'Assignment',
    from: 'Name 2',
    progress: {
      progress: 35,
      total: 100,
      completed: 35,
    },
    dueDate: new Date('2025-02-07'),
  },
  {
    id: '3',
    isRead: false,
    title: 'Report',
    from: 'Name 3',
    progress: {
      progress: 50,
      total: 100,
      completed: 50,
    },
    dueDate: new Date('2025-02-10'),
  },
  {
    id: '4',
    isRead: true,
    title: 'Assignment',
    from: 'Name 4',
    progress: {
      progress: 20,
      total: 100,
      completed: 20,
    },
    dueDate: new Date('2025-03-10'),
  },
];

export default USER_REMINDERS_MOCK;
