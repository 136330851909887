import { FC } from 'react';

import Icon from '../../../components/Icon';
import { StyledWarningFallback } from './WarningFallback.styled';

export type WarningFallbackProps = {
  warningTitle: string;
  warningText: string;
};

const WarningFallback: FC<WarningFallbackProps> = ({
  warningTitle = 'Warning',
  warningText,
}) => (
  <StyledWarningFallback className="esg-warning-fallback">
    <h1>
      <Icon name="triangle-exclamation" />
      <span>{warningTitle}</span>
    </h1>
    <p>{warningText}</p>
  </StyledWarningFallback>
);

export default WarningFallback;
