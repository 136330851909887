import {
  ButtonProps,
  Divider,
  Expander,
  MenuRef,
  SelectOption,
} from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { Dayjs } from 'dayjs';
import { FC, Fragment, useCallback, useMemo, useRef, useState } from 'react';

import AssignedContributor from '../../../../../components/_molecules/AssignedContributor';
import { DATE_FORMAT } from '../../../../../constants';
import { useContributors } from '../../../../../hooks';
import { Contributor } from '../../../../../models';
import AssignContributors from '../AssignContributors';
import DeadlineCalendar from '../DeadlineCalendar';
import { StyledCampaignBrowserSidePanel } from './CampaignBrowserSidePanel.styled';

type CampaignBrowserSidePanelProps = {
  organisationSessionId: string;
};

// TODO: this needs clean-up, rush for demo
const CampaignBrowserSidePanel: FC<CampaignBrowserSidePanelProps> = ({
  organisationSessionId,
}) => {
  // const { sessionId = '' } = useParams();

  // const { items } = useInfiniteFetch(
  //   API_ROUTES.SESSIONS.CONTRIBUTORS_LIST(sessionId) +
  //     `?organisationSessionId=${organisationSessionId}`
  // );

  const [deadline, setDeadline] = useState<Dayjs>();

  const { contributors } = useContributors();

  const [contributorsLocal, setContributorsLocal] = useState<Contributor[]>([]);

  const menuRef = useRef<MenuRef>(null);

  const nodeMenuItems = useCallback(
    (contributorId: string) =>
      [
        {
          children: 'Remove assignment',
          icon: <Icon name="xmark" />,
          variant: 'delete-ghost',
          onClick: () => {
            setContributorsLocal((old) =>
              old.filter(({ id }) => id !== contributorId)
            );
          },
        },
        {
          children: 'Open user profile',
          icon: <Icon name="arrow-up-right-from-square" />,
          variant: 'ghost',
          disabled: true,
          onClick: () => {},
        },
      ] as ButtonProps[],
    []
  );

  const initialData = useMemo<{ contributors: SelectOption[] }>(
    () => ({
      contributors: contributorsLocal.map(({ name, id }) => ({
        label: name,
        value: id,
      })),
    }),
    [contributorsLocal]
  );

  return (
    <StyledCampaignBrowserSidePanel className="campaign-browser-side-panel">
      <Expander
        icon={<Icon name="chevron-down" />}
        title="Contributors"
        bodyAs="div"
        open
        body={
          <Fragment>
            <AssignContributors
              initialData={initialData}
              btnText="Assign to form"
              contributors={contributorsLocal}
              onSubmit={async (data) => {
                setContributorsLocal(
                  contributors.filter(({ id }) =>
                    data.contributors.some(
                      (selectedName) => selectedName.value === id
                    )
                  )
                );
              }}
              className="campaign-browser-side-panel__assign-button"
              render={(contributors) => (
                <div className="campaign-browser-side-panel__assignees">
                  <small className="campaign-browser-side-panel__assignees__subtitle">
                    {contributors.length > 0
                      ? 'Contributors'
                      : 'Please add contributors'}
                  </small>

                  {contributorsLocal.map(({ name, role, src, id }) => (
                    <AssignedContributor
                      id={id}
                      key={name}
                      src={src}
                      name={name}
                      role={role}
                      menuRef={menuRef}
                      menuItems={nodeMenuItems(id)}
                    />
                  ))}
                </div>
              )}
            />
          </Fragment>
        }
      />

      <Divider />

      <Expander
        icon={<Icon name="chevron-down" />}
        title="Deadline"
        bodyAs="div"
        open
        body={
          <DeadlineCalendar
            onChange={(date) => {
              setDeadline(date ?? undefined);
            }}
            triggerText="Set deadline"
            value={deadline}
            openAtToday
            disablePast
            dateFormat={DATE_FORMAT}
          />
        }
      />
      <Divider />

      {/* TODO */}

      {/* <ReportStatus
            asAdmin
            progress={progress}
            loading={loadingStatus}
            formStatus={formStatus}
            formApproval={formApproval}
            onStatusChange={updateFormStatus}
          /> */}
    </StyledCampaignBrowserSidePanel>
  );
};

export default CampaignBrowserSidePanel;
