import { Button, Icon, ModalRef } from '@faxi/web-component-library';
import { FC, Fragment, memo, useRef } from 'react';

import useGetCampaignItem from '../../../../../../api/hooks/useGetCampaignItem';
import { useURLDataCollectionElementId } from '../../../../../../hooks';
import { useFormBuilder } from '../../../../context/FormBuilder';
import FormBuilderPreviewModal from './components/FormBuilderPreviewModal';
import { StyledPreviewTool } from './PreviewTool.styled';

const PreviewTool: FC = () => {
  const modalRef = useRef<ModalRef>(null);

  const { dataCollectionElementId } = useURLDataCollectionElementId();

  const { isLoading: isLoadingData } = useGetCampaignItem(
    dataCollectionElementId
  );

  const { loadingNewModule } = useFormBuilder();

  return (
    <StyledPreviewTool
      open
      body={
        <Fragment>
          <Button
            disabled={isLoadingData || loadingNewModule}
            icon={<Icon name="eye" />}
            variant="outline"
            onClick={() => modalRef?.current?.open()}
          >
            Preview form
          </Button>
          <FormBuilderPreviewModal
            ref={modalRef}
            conditionallyControlled={false}
          />
        </Fragment>
      }
      icon={<Icon name="chevron-down" />}
      title="Preview"
      bodyAs="div"
      bodyClassName="esg-preview-tool"
    />
  );
};

export default memo(PreviewTool);
