const findNode = <T extends { id: string; children?: T[] }>(
  id: string,
  nodes: T[]
): T | undefined => {
  for (const node of nodes) {
    if (node?.id === id) return node;

    if (node.children) {
      const desiredNode = findNode(id, node.children);

      if (desiredNode) return desiredNode;
    }
  }

  return undefined;
};

const findFirstNonDisabledNode = <
  T extends { id: string; action?: { disabledStyle: boolean }; children?: T[] },
>(
  nodes: T[]
): T | undefined => {
  for (const node of nodes) {
    if (!node?.action?.disabledStyle) return node;

    if (node.children) {
      const desiredNode = findFirstNonDisabledNode(node.children);

      if (desiredNode) return desiredNode;
    }
  }

  return undefined;
};

export { findFirstNonDisabledNode, findNode };
