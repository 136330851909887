import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledProfileNotifications = styled.div`
  .esg-profile-notifications {
    &__on-date {
      &__date {
        ${fontSize(theme.fontSizes.FONT_16, theme.fontSizes.FONT_20)};
        color: var(--GRAY-70);
        margin-bottom: ${theme.sizes.SIZE_24};
        margin-top: ${theme.sizes.SIZE_40};
      }
    }

    &__user {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_32};

      &__image-name {
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_12};
      }
    }

    &__element,
    &__task {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_12};
    }
  }
`;

export { StyledProfileNotifications };
