import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledProfileReminders = styled.div`
  .esg-profile-reminders {
    &__title {
      ${flex('row')};
    }
  }
`;

export { StyledProfileReminders };
