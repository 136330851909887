import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import { StyledDueReminderFlag } from './DueReminderFlag.styled';
import { generateRemainingDaysObj } from './utils';

export type DueReminderFlagProps = {
  className?: string;
  dueDate: Date;
};

const DueReminderFlag: FC<DueReminderFlagProps> = ({ dueDate, className }) => {
  const { text, style } = generateRemainingDaysObj(
    dayjs(dueDate).diff(dayjs(), 'days')
  );

  return (
    <StyledDueReminderFlag
      className={classNames('esg-due-reminder-flag', className)}
      style={style}
    >
      {text}
    </StyledDueReminderFlag>
  );
};

export default DueReminderFlag;
