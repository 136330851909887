import { SessionsPageParams } from 'models';
import { FC } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { APP_URI } from '../../../../config';
import { BlockUI } from '../../../../helpers';
import { SelectedSessionProvider } from '../../context/SelectedSession';
import useSessionBrowser from '../../hooks/useSessionBrowser';
import SessionSidebar from '../SessionSidebar';
import { StyledSessionDetails } from './SessionDetails.styled';

const SessionDetails: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();
  const { pathname } = useLocation();

  const { isLoading } = useSessionBrowser(false);

  const isBrowserView = pathname.includes(
    APP_URI.SESSIONS_SESSION_CAMPAIGN_BROWSER
  );

  return (
    <BlockUI error={!sessionId} loading={isLoading}>
      <StyledSessionDetails className="esg-session-details">
        <SelectedSessionProvider sessionId={sessionId!}>
          <SessionSidebar isBrowserView={isBrowserView} />
          <div className="esg-session-details__content">
            <Outlet />
          </div>
        </SelectedSessionProvider>
      </StyledSessionDetails>
    </BlockUI>
  );
};

export default SessionDetails;
