import { FileUpload, FileUploadProps } from '@faxi/web-component-library';
import { FieldProps, FormRef } from '@faxi/web-form';
import isEmpty from 'lodash.isempty';
import { FC } from 'react';

import { StyledFileUploadField } from './FileUploadField.styled';

export type FileUploadFieldProps = FieldProps<File[], (files: File[]) => void> &
  FileUploadProps;

// TODO: It should be passed through the validate property (but requires refactoring in wcl)
export const handleUploadFieldErrors =
  (formRef: FormRef, name: string, errorMessage = 'Error.') =>
  (errors: Record<string, string>) =>
    formRef?.setFieldError(name, !isEmpty(errors) ? errorMessage : '');

const FileUploadField: FC<FileUploadFieldProps> = (
  props: FileUploadFieldProps
) => {
  return (
    <StyledFileUploadField>
      <FileUpload {...props} />
    </StyledFileUploadField>
  );
};

export default FileUploadField;
