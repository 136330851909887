import {
  Button,
  OverlayRef,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import { Loading, NotificationBadgeWrapper } from '../../../../components';
import ModuleElement from '../../../../components/_organisms/BuilderCanvas/components/ModuleElement';
import Icon from '../../../../components/Icon';
import { BlockUI } from '../../../../helpers';
import { useFormFileUpload } from '../../../../hooks/useFormFileUpload';
import { DataModuleEnum, IDataModule, SessionForm } from '../../../../models';
import ModuleElementDrawer from '../../../../routes/subrouters/Sessions/components/ModuleElementDrawer';
import { generateInitialFormModulesData } from '../../../../utils';
import LockFieldModalComponents from './components/ToggleLockFieldModal';
import { StyledSessionCampaignForm } from './SessionCampaignForm.styled';

export type SessionCampaignFormProps = PropsWithChildren<{
  organisationSessionId: string;
}>;

/**
 * Recursively filters out "SECTION" modules that are empty or contain no non-empty nested modules.
 *
 * @param elements - An array of IDataModule objects to filter. Defaults to an empty array.
 * @returns A filtered array of IDataModule objects where:
 *  - Non-"SECTION" modules are preserved.
 *  - "SECTION" modules are included only if they contain non-empty nested elements.
 */
const filterNonEmptyModules = (
  elements: IDataModule<DataModuleEnum>[] = []
): IDataModule<DataModuleEnum>[] =>
  elements.filter(({ type, elements }) => {
    if (type === DataModuleEnum.SECTION) {
      const filteredNestedElements = elements
        ? filterNonEmptyModules(elements)
        : [];

      return filteredNestedElements.length > 0;
    }

    return true;
  });

const SessionCampaignForm: FC<SessionCampaignFormProps> = ({
  organisationSessionId,
}) => {
  const [activeModule, setActiveModule] = useState<IDataModule>();

  const { campaignItemId, companyId, sessionId } = useParams();

  const {
    uploadFormFiles,
    removeFiles,
    isLoadingFiles,
    initialFiles,
    checkFileChanges,
  } = useFormFileUpload(
    sessionId!,
    companyId!,
    organisationSessionId,
    campaignItemId!
  );

  const moduleDrawerRef = useRef<OverlayRef>(null);

  const {
    open: isOpen,
    openModal: openLockFieldModal,
    closeModal: closeLockFieldModal,
  } = useModalUtilities();

  const apiEndpoint =
    organisationSessionId &&
    campaignItemId &&
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
      organisationSessionId,
      campaignItemId
    );

  const {
    data: { data: form } = {},
    isLoading,
    isValidating,
    error,
  } = useSWR<{ data: SessionForm & { elements: IDataModule[] } }>(apiEndpoint);

  //TODO: This need to be changed once the API is done

  // const modules = useMemo(
  //   () => filterNonEmptyModules(form?.elements) || [],
  //   [form?.elements]
  // );

  const [modules, setModules] = useState<IDataModule[]>([]);

  useEffect(() => {
    if (form?.elements) {
      setModules(
        filterNonEmptyModules(form.elements).map((module, index) => ({
          ...module,
          isLocked: index % 2 === 0,
        }))
      );
    }
  }, [form?.elements]);

  const handleLockToggle = (moduleId: string, isLocked: boolean) => {
    setModules((prevModules) =>
      prevModules.map((mod) =>
        mod.id === moduleId ? { ...mod, isLocked: !isLocked } : mod
      )
    );
  };

  const initialFormData = useMemo(
    () => generateInitialFormModulesData(modules, initialFiles),
    [initialFiles, modules]
  );

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const { trigger: submitSessionForm } = useMutation(
    apiEndpoint,
    {
      onSuccess: () =>
        showSnackBar({
          text: 'Form successfully submitted!',
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  return (
    <StyledSessionCampaignForm className="esg-session-campaign-form">
      <BlockUI
        loading={isLoading || isValidating || isLoadingFiles}
        error={error}
      >
        <Form
          className="esg-session-campaign-form__form"
          initialData={initialFormData}
          onSubmit={async (data) => {
            if (!form) return;

            const finalData = {
              elements: Object.entries(data).map(([expandedId, value]) => ({
                id: expandedId.split('+')[0],
                type: expandedId.split('+')[1],
                value,
              })),
            };

            // Get all uploaded files
            const currentFiles: Record<string, File[]> = finalData.elements
              .filter(
                ({ type, value }) =>
                  type === 'upload' && Array.isArray(value) && value.length
              )
              .reduce(
                (acc, curr) => {
                  acc[curr.id] = curr.value as File[];
                  return acc;
                },
                {} as Record<string, File[]>
              );

            // Check if there are any deleted files or new ones added
            const { deletedFiles, addedFiles } = checkFileChanges(currentFiles);

            // Convert the newly added files to the type we need for API calls.
            const uploadFilesList: File[] = Object.values(addedFiles)
              .flat()
              .map(({ file }) => file);

            const uploadingFiles = addedFiles.map(
              ({ file, formElementId }) => ({
                fileType: 'form_attachment',
                type: file.type,
                name: file.name,
                formElementId,
              })
            );

            await submitSessionForm({
              method: 'POST',
              data: finalData,
            });
            mutate(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE());

            showOverlay('#root', 'fixed', <Loading />);

            uploadingFiles &&
              (await uploadFormFiles(uploadingFiles, uploadFilesList));

            deletedFiles && (await removeFiles(deletedFiles));

            mutate(
              API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_FORM_DOCUMENTS(
                sessionId!,
                companyId!,
                campaignItemId!
              )
            );

            hideOverlay('#root');
          }}
        >
          {modules.map((module) => (
            <div
              key={module.id}
              className="esg-session-campaign-form__form__container"
            >
              <ModuleElement
                modulesType="preview-module"
                type={module.type}
                module={module}
                key={module.id}
                isInlineEditableDisabled={module.isLocked}
              />
              <div className="esg-session-campaign-form__form__container__buttons">
                <Button
                  icon={
                    <Icon name={`lock-${module.isLocked ? 'solid' : 'open'}`} />
                  }
                  onClick={() => {
                    setActiveModule(module);
                    openLockFieldModal();
                  }}
                  variant="ghost"
                />
                <Button
                  className="esg-session-campaign-form__form__container__comments-button"
                  icon={
                    <NotificationBadgeWrapper
                      count={module.numberOfUnreadComments ?? 0}
                    >
                      <Icon name="sidebar-flip" />
                    </NotificationBadgeWrapper>
                  }
                  variant="ghost"
                  onClick={() => {
                    setActiveModule(module);
                    moduleDrawerRef?.current?.open();
                  }}
                />
              </div>
            </div>
          ))}
          <ModuleElementDrawer
            ref={moduleDrawerRef}
            module={activeModule}
            onUpdateNumberOfUnreadComments={(numberOfUnreadComments, id) => {
              setModules((old) =>
                old.map((el) =>
                  el.id === id ? { ...el, numberOfUnreadComments } : el
                )
              );
            }}
          />
          {isOpen && (
            <LockFieldModalComponents
              onClose={closeLockFieldModal}
              onConfirm={handleLockToggle}
              module={activeModule!}
            />
          )}
          <div className="esg-session-campaign-form__form__actions">
            <Button type="submit" disabled={!modules.length}>
              Submit
            </Button>
          </div>
        </Form>
      </BlockUI>
    </StyledSessionCampaignForm>
  );
};

export default SessionCampaignForm;
