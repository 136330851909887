import { FormField } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, useMemo, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import {
  XBRLTaxonomy,
  XBRLTaxonomyField,
  XBRLTaxonomyFieldGroup,
} from '../../../models';
import SelectField from '../SelectField';
import { StyledXBRLSelectionFields } from './XBRLSelectionFields.styled';

export type XBRLSelectionFieldsProps = PropsWithChildren<{
  className?: string;
  showLabel?: boolean;
  direction?: 'row' | 'column';
  selectedPackage?: string;
  type: 'fields' | 'fieldGroup';
}>;

const XBRLSelectionFields: FC<XBRLSelectionFieldsProps> = ({
  className,
  showLabel = true,
  direction = 'row',
  selectedPackage: pSelectedPackage,
  type,
  ...rest
}) => {
  const { data: { data: packages = [] } = {}, isLoading: isLoadingPackages } =
    useSWR<{
      data: XBRLTaxonomy[];
    }>(API_ROUTES.CAMPAIGN_ITEMS_ROUTES.XBRL_TAXONOMIES);

  const [selectedPackage, setSelectedPackage] = useState<string | undefined>(
    pSelectedPackage
  );
  const [selectedTag, setSelectedTag] = useState<string>();

  const { data: xbrlFieldsGroup, isLoading: isLoadingFieldsGroup } = useSWR<{
    data: XBRLTaxonomyFieldGroup[];
  }>(
    type === 'fieldGroup' &&
      selectedPackage &&
      API_ROUTES.CAMPAIGN_ITEMS_ROUTES.XBRL_TAXONOMIES_FIELD_GROUPS(
        selectedPackage
      )
  );

  const { data: xbrlFields, isLoading: isLoadingFields } = useSWR<{
    data: XBRLTaxonomyField[];
  }>(
    type === 'fields' &&
      selectedPackage &&
      API_ROUTES.CAMPAIGN_ITEMS_ROUTES.XBRL_TAXONOMIES_FIELDS(selectedPackage)
  );

  const tagOptions = useMemo(() => {
    const xbrlTags = type === 'fieldGroup' ? xbrlFieldsGroup : xbrlFields;

    return (xbrlTags?.data || []).map(({ id, label }) => ({
      value: id,
      label,
    }));
  }, [type, xbrlFields, xbrlFieldsGroup]);

  return (
    <StyledXBRLSelectionFields
      className={classNames(className, 'esg-xbrl-selection-fields')}
      {...rest}
    >
      {showLabel && (
        <label className="esg-xbrl-selection-fields__label">XBRL Tag</label>
      )}

      <div
        className="esg-xbrl-selection-fields__fields"
        style={{ flexDirection: direction }}
      >
        <FormField
          name="xbrl_package"
          component={SelectField}
          autoComplete="off"
          placeholder="Select XBLR Package"
          options={packages.map(({ id, meta: { name } = {} }) => ({
            value: id,
            label: name,
          }))}
          hasClearAction
          clearTitle="Unselect"
          aria-describedby="xbrl-taxonomy-package"
          searchable
          onChange={(v) => {
            setSelectedPackage(v as string);
            setSelectedTag(undefined);
          }}
          loading={isLoadingPackages}
          disabled={!packages.length}
        />

        <FormField
          name="xbrl_package_tag"
          component={SelectField}
          placeholder="Tag"
          autoComplete="off"
          disabled={!selectedPackage || isLoadingFields || isLoadingFieldsGroup}
          options={tagOptions}
          value={selectedTag}
          onChange={(v) => setSelectedTag(v as string)}
          hasClearAction
          clearTitle="Unselect"
          aria-describedby="xbrl-tag"
          searchable
          loading={isLoadingFields || isLoadingFieldsGroup}
        />
      </div>
    </StyledXBRLSelectionFields>
  );
};

export default XBRLSelectionFields;
