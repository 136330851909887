import { TreeConversionParams, TreeNodeElement } from '../models';

export const insertNodeInTree = <T extends TreeNodeElement>({
  currentNode,
  targetNode,
  depth,
}: TreeConversionParams<T>): void => {
  const { path, children, parent, ...nodeData } = targetNode;

  if (!path.ids.length) return;

  // Validate current tree level matches path expectation
  if (path.ids[depth] && currentNode.id !== path.ids[depth]) return;

  // Check for existing node to prevent duplicates
  const existingNode = currentNode.children?.find(
    (child) => child.id === targetNode.id
  );

  if (existingNode) {
    insertNodeInTree({
      currentNode: existingNode,
      targetNode,
      depth: depth + 1,
    });
    return;
  }

  if (currentNode.id === targetNode.id) {
    Object.assign(currentNode, { ...nodeData, parent: currentNode });

    return;
  }

  // Create path complete node when reaching target depth
  if (depth === path.ids.length - 1) {
    const newChild: TreeNodeElement = {
      ...nodeData,
      parent: currentNode,
    };

    currentNode.children = [...(currentNode.children || []), newChild];
    return;
  }

  // Create missing path nodes
  const nextNodeId = path.ids[depth + 1];
  const nextNodeName = path.names[depth + 1];

  if (!currentNode.children?.some((child) => child.id === nextNodeId)) {
    const pathNode: TreeNodeElement = {
      id: nextNodeId,
      name: nextNodeName,
      parent: currentNode,
      children: [],
    };

    currentNode.children = [...(currentNode.children || []), pathNode];
  }

  // Continue building the path
  const nextNode = currentNode.children.find(
    (child) => child.id === nextNodeId
  );

  if (nextNode) {
    insertNodeInTree({
      currentNode: nextNode,
      targetNode,
      depth: depth + 1,
    });
  }
};
