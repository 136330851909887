import { theme } from '@faxi/web-component-library';
import { flex, fontSize, laptop } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

import { BaseLayout } from '../../../../layouts';

const StyledSessionCompanyBrowser = styled(BaseLayout)`
  ${flex('column')};
  height: 100%;

  .esg-base-layout__content {
    padding: ${theme.sizes.SIZE_32};
  }

  h1 {
    ${fontSize('36px', '54px')};
    font-weight: 400;
  }

  .esg-session-company-browser {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.sizes.SIZE_20};

      ${laptop(css`
        grid-template-columns: 1fr;
      `)}
    }
  }
`;

export { StyledSessionCompanyBrowser };
