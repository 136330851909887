import { Sidebar as WCLSidebar } from '@faxi/web-component-library';
import classNames from 'classnames';
import { APP_URI } from 'config';
import { FC, useMemo } from 'react';

import { useRootProvider } from '../../providers/Root';
import { sidebarItemsPermissionBased } from '../../utils/sidebarItemsPermissionBased';
import {
  DEFAULT_SIDEBAR_PROPS,
  SIDEBAR_ARIA_LABELS,
  SIDEBAR_BOTTOM_ITEMS,
  SIDEBAR_MAIN_ITEMS,
} from './constants';
import { EsgSidebarItem } from './types';

export type SidebarProps = { className?: string };

const Sidebar: FC<SidebarProps> = ({ className }) => {
  const {
    logoutUser,
    userTokenInformation: {
      firstName = '',
      lastName = '',
      userRouterPermissions,
    } = {},
  } = useRootProvider();

  const finalSidebarRoutes = useMemo<{ items: EsgSidebarItem[] }[]>(
    () => [
      {
        items: sidebarItemsPermissionBased(
          SIDEBAR_MAIN_ITEMS,
          userRouterPermissions
        ),
      },
    ],
    [userRouterPermissions]
  );

  return (
    <WCLSidebar
      className={classNames('esg-sidebar', className)}
      logoUrl="/assets/svg/logo.svg"
      logoAlt="ESG Navigator logo"
      ariaLabels={SIDEBAR_ARIA_LABELS}
      mainItems={finalSidebarRoutes}
      bottomItems={SIDEBAR_BOTTOM_ITEMS}
      logoTo={APP_URI.BASE}
      user={{
        name: `${firstName} ${lastName}`,
        image_url: '/assets/images/avatar.png',
      }}
      onClickLogout={logoutUser}
      {...DEFAULT_SIDEBAR_PROPS}
    />
  );
};

export default Sidebar;
