import { FC } from 'react';

import ProfileReminders from '../../../../../../components/ProfileReminders';
import USER_REMINDERS_MOCK from './mock';

const UserReminders: FC = () => {
  return <ProfileReminders reminders={USER_REMINDERS_MOCK} />;
};

export default UserReminders;
