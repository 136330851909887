import { Modal, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledAssignContributorsModal = styled(Modal)`
  .wcl-modal {
    width: ${theme.sizes.SIZE_680};
    &__main {
      ${flex('column', 'flex-start', 'stretch')};

      gap: ${theme.sizes.SIZE_32};
      padding-top: ${theme.sizes.SIZE_32};
    }

    &__header {
      .wcl-icon {
        ${fontSize(theme.fontSizes.FONT_24)};
        min-height: unset;
      }
    }
  }
  .esg-assign-contributors-modal {
    &__contributors {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: ${theme.sizes.SIZE_16};
    }

    &__calendar {
      ${flex('column')};
      gap: ${theme.sizes.SIZE_10};
      color: var(--GRAY-70);
    }
  }
`;

export { StyledAssignContributorsModal };
