import axios from 'axios';

// avoids axiosInstance default Bearer
export const presignedUploadFetcher = async (
  _: string,
  { arg }: { arg: { file: File; presignedUrl: string; fileType: string } }
) => {
  try {
    await axios.put(arg.presignedUrl, arg.file, {
      headers: {
        'Content-Type': arg.fileType,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
