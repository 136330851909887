import Icon from '../../../../../../components/Icon';
import { IDataModule } from '../../../../../../models';
import { splitStringWithPartsLimit } from '../../../../../../utils';

export const getLockFieldProps = (module: IDataModule) => {
  const isLocked = module.isLocked;
  const title = isLocked ? 'Unlock Field' : 'Lock Field';
  const lockIcon = <Icon name={`lock-${isLocked ? 'open' : 'solid'}`} />;
  const infoText = isLocked
    ? 'You are unlocking this field. Other users will now be able to modify it.'
    : 'You are locking this field. Other users will not be able to modify it. Only you and admins can unlock it again.';
  const modalInfoText = splitStringWithPartsLimit(infoText, '. ', 3, true);

  return { isLocked, title, lockIcon, modalInfoText };
};
