import { GlowScroll } from '@faxi/web-component-library';
import { FC, useMemo } from 'react';

import { ContributorsList } from '../../../../../components';
import InfiniteScrollContainer from '../../../../../components/_molecules/InfiniteScrollContainer';
import { useContributors } from '../../../../../hooks';
import mockActivityLog from '../../../../../mock/activityLog';
import {
  DataModuleElementDrawerComponentProps,
  User,
} from '../../../../../models';
import {
  formatRelativeDate,
  formatRelativeOrFullDate,
  isSameDateWithoutTime,
} from '../../../../../utils';
import UserMentionOption from '../UserMentionOption';
import { StyledModuleElementActivity } from './ModuleElementActivity.styled';

type ParsedActivityLogByDate = {
  date: Date | string;

  logs: {
    id: string;
    user: User;
    summary: string;
    exactDate: Date | string;
  }[];
};

const ModuleElementActivity: FC<DataModuleElementDrawerComponentProps> = () => {
  //TODO: REPLACE WITH ACTUAL REQUEST
  const logs = mockActivityLog;

  const { contributors } = useContributors();

  const parsedLogs = useMemo(() => {
    if (!logs) return [];

    return logs.reduce<ParsedActivityLogByDate[]>((prev, curr) => {
      const sameDateArrayElement = prev.find((el) =>
        isSameDateWithoutTime(el.date, curr.targetedAt)
      );

      if (!sameDateArrayElement) {
        return [
          ...prev,
          {
            date: curr.targetedAt,
            logs: [
              {
                id: curr.id,
                user: curr.user,
                summary: curr.summary,
                exactDate: curr.targetedAt,
              },
            ],
          },
        ];
      } else {
        return prev.map((el) =>
          isSameDateWithoutTime(el.date, curr.targetedAt)
            ? {
                ...el,
                logs: [
                  ...el.logs,
                  {
                    id: curr.id,
                    user: curr.user,
                    summary: curr.summary,
                    exactDate: curr.targetedAt,
                  },
                ],
              }
            : el
        );
      }
    }, []);
  }, [logs]);

  return (
    <StyledModuleElementActivity className="esg-module-element-activity">
      <div className="esg-module-element-activity__contributors">
        <p>Contributors</p>
        <ContributorsList contributors={contributors} />
      </div>
      <GlowScroll variant="gray">
        <InfiniteScrollContainer isFetching={false}>
          {parsedLogs?.map(({ date, logs }) => (
            <div
              className="esg-module-element-activity__date-logs"
              key={date.toString()}
            >
              <span>{formatRelativeDate(date)}</span>
              {logs.map((log) => (
                <div
                  className="esg-module-element-activity__date-logs__log"
                  key={log.id}
                >
                  <UserMentionOption
                    name={`${log.user.firstName} ${log.user.lastName}`}
                  />
                  <p>{log.summary}</p>
                  <span>{formatRelativeOrFullDate(log.exactDate)}</span>
                </div>
              ))}
            </div>
          ))}
        </InfiniteScrollContainer>
      </GlowScroll>
    </StyledModuleElementActivity>
  );
};

export default ModuleElementActivity;
